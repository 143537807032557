import React, { useEffect, useState, useRef } from 'react';
import { addGymFactor, deleteGymFactorImage, getGymFactorByHotelIdAdmin, getGymFactorDefinitions, updateAllowScoreOverwrite, updateGymFactor, updateHotelReviewRequest, updateIsGalleryImage, updateIsProfileImage } from '../redux.js/api';
import ToastNotification from './ToastNotification';
import { blobStorageUrl, getImageUrl } from './HelperComponent';
import LoadingDots from './LoadingDots';
import { Dropdown } from 'primereact/dropdown';
import { formatShortDate } from './formatting';
import { InputTextarea } from "primereact/inputtextarea";
import AppImage from './AppImage';
import ImageProviderModal from './ImageProviderModal';
import { gymFactorSourceTypes } from './constants';
import Checkbox from './Checkbox';
import { GymImageGalleryModal } from './GymImageGalleryModal';

const EditorPageGymFactorScore = ({ selectedHotel, allProviders, changeTriggerGymFactorScoreSectionFunctions, triggerGymFactorScoreSectionFunctions, gymFactorDefinitions }) => {
    const emptyReview = {
        id: 0,
        score: 0,
        description: "",
        providerIds: [],
        youtubeLinks: "",
        twitterLinks: "",
        images: [],
        rotateImages: [],
        imageProviders: [],
        gymProfileLinks: ""
    }
    const [addGymFactorData, setGymFactorData] = useState({ ...emptyReview })
    const [hotelGymFactor, setHotelGymFactor] = useState({});

    const [providers, setProviders] = useState({})
    const [youtubeVideosFields, setYoutubeVideosFields] = useState([{ id: 1, value: "" }]);
    const [twitterFields, setTwitterFields] = useState([{ id: 1, value: "" }]);
    const [showImageGallery, setShowImageGallery] = useState(false);
    const [galleryImage, setGalleryImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [showImageProviderModal, setShowImageProviderModal] = useState(false)
    const [imageProvider, setImageProvider] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [gymImagesFolder, setGymImagesFolder] = useState('optimized');

    const [gymFactorObject, setGymFactorObject] = useState();
    const [tillNextScore, setTillNextScore] = useState(0);
    const [gymFactorOptions, setGymFactorOptions] = useState();
    const [allGymFactorDefinitions, setAllGymFactorDefinitions] = useState(gymFactorDefinitions || []);

    const imgRef = useRef();

    const [groupedGymFactorSourceProviders, setGroupedGymFactorSourceProviders] = useState({});
    useEffect(() => {
        const gymFactorSources = allProviders.filter(provider => provider.isGymFactorSource === true);

        const groupedByType = gymFactorSources.reduce((acc, provider) => {
            if (!acc[provider.type]) {
                acc[provider.type] = [];
            }
            acc[provider.type].push(provider);
            return acc;
        }, {});

        setGroupedGymFactorSourceProviders(groupedByType);
    }, [allProviders]);

    useEffect(() => {
        changeTriggerGymFactorScoreSectionFunctions()
    }, [])

    useEffect(() => {
        const isFloat = (n) => {
            return Number(n) === n && n % 1 !== 0;
        }
        const fetchGFDefinitions = async () => {
            let items = allGymFactorDefinitions;
            items.forEach(x => {
                if (x.gymFactorScore > 0) {
                    if (!isFloat(x.gymFactorScore)) x.gymFactorScoreString = `${x.gymFactorScore}.0`;
                    else x.gymFactorScoreString = x.gymFactorScore.toString();
                }
            })
            setAllGymFactorDefinitions(items);
            const filtered = items.filter(z => z.minPoints > -1)
            const all = filtered.map(y => {
                return ({ value: y.gymFactorScore, label: `${y.gymFactorScoreString} - ${y.ratingClassName}` })
            });
            setGymFactorOptions(all)
        }
        fetchGFDefinitions()
    }, [])

    useEffect(() => {
        const getNextHighestIndex = (arr, value) => {
            let i = arr.length;
            while (arr[--i] > value);
            return ++i;
        }
        if (hotelGymFactor.points) {
            let arr = allGymFactorDefinitions.filter(x => x.minPoints > -1)
            arr = arr.map(y => y.minPoints)
            arr.sort(function (a, b) { return a - b })
            let idx = getNextHighestIndex(arr, hotelGymFactor.points)
            if (idx > 4) idx = 4;
            const difference = arr[idx] - hotelGymFactor.points;
            if (difference < 0) setTillNextScore(0)
            else setTillNextScore(difference)
        }
        else setTillNextScore(0)
    }, [hotelGymFactor.points])

    useEffect(() => {
        if (allGymFactorDefinitions.length > 0 && addGymFactorData.score) {
            setGymFactorObject(allGymFactorDefinitions.find(data => data.gymFactorScore === addGymFactorData.score))
        }
        else setGymFactorObject({})
    }, [allGymFactorDefinitions, addGymFactorData.score, selectedHotel.bookingHotelId])

    const selectUpdateGymFactor = (e) => {
        e.preventDefault();
        setGymFactorData({ ...addGymFactorData, score: e.value })
    }

    const handleUpdateAllowScoreOverwrite = async (value) => {
        const processedData = {
            id: hotelGymFactor.id,
            allowScoreOverwrite: value
        }
        try {
            const { data: { result } } = await updateAllowScoreOverwrite(processedData)
            setHotelGymFactor({ ...hotelGymFactor, allowScoreOverwrite: result.allowScoreOverwrite, score: result.score })
            setGymFactorData({ ...addGymFactorData, score: result.score })
            ToastNotification('success', `${value === true ? 'Allow score overwrite set to True' : 'Allow score overwrite set to False'}`)
        } catch (error) {
            ToastNotification('error', 'Error updating allow score overwrite')
            return;
        }
    }

    async function fetchData() {
        setLoading(true)
        const [hotelGymFactor] = await Promise.all([
            getGymFactorByHotelIdAdmin(selectedHotel.hotelId),
        ]);
        if (allProviders) {
            const providersData = allProviders.filter(provider => provider.isGymFactorSource === true).map(p => ({ value: parseInt(p.id), label: p.name }));
            providersData.sort((a, b) => a.label.localeCompare(b.label))
            setProviders(providersData)
        }
        if (hotelGymFactor.data && hotelGymFactor.data.result) {
            const result = hotelGymFactor.data.result
            setHotelGymFactor(result)
            setGymFactorData({
                ...addGymFactorData,
                score: result.score,
                description: result.description,
                providerIds: result.providerIds ? result.providerIds.split(',') : [],
                youtubeLinks: result.youtubeLinks,
                twitterLinks: result.twitterLinks,
                images: [],
                rotateImages: [],
                imageProviders: [],
                gymProfileLinks: result.gymProfileLinks === null ? '' : result.gymProfileLinks
            })
            if (result.youtubeLinks) {
                const youtubeLinksArray = result.youtubeLinks.split(',');
                let youtubeFields = [];
                youtubeLinksArray.map(x => youtubeFields.push({ id: youtubeFields.length + 1, value: x }))
                setYoutubeVideosFields(youtubeFields)
            }
            if (result.twitterLinks) {
                const twitterLinksArray = result.twitterLinks.split(',');
                let twitterFields = [];
                twitterLinksArray.map(x => twitterFields.push({ id: twitterFields.length + 1, value: x }))
                setTwitterFields(twitterFields)
            }
        }
        else {
            setGymFactorData({ ...emptyReview })
            setHotelGymFactor({})
        }
        setLoading(false)
    }

    useEffect(() => {
        if (selectedHotel.bookingHotelId || triggerGymFactorScoreSectionFunctions === true) {
            setYoutubeVideosFields([{ id: 1, value: "" }])
            setTwitterFields([{ id: 1, value: "" }])
            fetchData();
        }
    }, [selectedHotel.bookingHotelId, triggerGymFactorScoreSectionFunctions]);

    const handleAddPartners = (event) => {
        let providerIds = addGymFactorData.providerIds
        if (event.target.checked) {
            providerIds.push(event.target.id)
        } else {
            providerIds = providerIds.filter(item => item !== event.target.id)
        }
        setGymFactorData({ ...addGymFactorData, providerIds })
    }

    const handleUploadImage = (e) => {
        const newImages = Array.from(e.target.files);

        // Check if any image is of type 'image/heic' or 'image/heif'
        const heicOrHeifImage = newImages.find(img => img.type === 'image/heic' || img.type === 'image/heif');
        if (heicOrHeifImage) {
            // Show a toast or any other notification to the user
            ToastNotification('error', 'HEIC/HEIF images are not supported. Please upload images of JPG format.')
            return;
        }
        // Check file extension as a fallback, in case the file has already been converted
        const heicExtensionImage = newImages.find(img => img.name.toLowerCase().endsWith('.heic') || img.name.toLowerCase().endsWith('.heif'));
        if (heicExtensionImage) {
            ToastNotification('error', 'HEIC/HEIF images are not supported. Please upload images of JPG format.')
            return;
        }

        const existingImageNames = addGymFactorData.images.map(img => img.name);
        const filteredNewImages = newImages.filter(img => !existingImageNames.includes(img.name));

        setGymFactorData(prevState => ({
            ...prevState,
            images: [...prevState.images, ...filteredNewImages]
        }));
    };

    const handleDeleteAddedReviewImage = (filename) => {
        setGymFactorData({ ...addGymFactorData, images: [...addGymFactorData.images.filter(image => image.name !== filename)] })
    }

    const handleSaveImageProvider = ({ e, provider, fileName }) => {
        e.preventDefault();
        const imageProviderExist = addGymFactorData.imageProviders.find(x => x.filename === fileName)
        if (imageProviderExist === undefined) {
            setGymFactorData(prevState => {
                const providerString = provider.toString();
                const newProviderIds = prevState.providerIds.includes(providerString)
                    ? prevState.providerIds
                    : [...prevState.providerIds, providerString];
                return {
                    ...prevState,
                    imageProviders: [...prevState.imageProviders, { filename: fileName, providerId: provider }],
                    providerIds: newProviderIds
                }
            });
        }
        else {
            setGymFactorData(prevState => {
                const idToChange = prevState.imageProviders.find(x => x.filename === fileName).providerId.toString();
                const providerString = provider.toString();
                const newProviderIds = prevState.providerIds.includes(idToChange)
                    ? prevState.providerIds.map(x => x === idToChange ? providerString : x)
                    : [...prevState.providerIds, providerString];
                return {
                    ...prevState,
                    imageProviders: [...prevState.imageProviders.filter(x => x.filename !== fileName), { filename: fileName, providerId: provider }],
                    providerIds: newProviderIds
                };
            });
        }
    }

    const handleRotateImageAddReview = (filename) => {
        const image = addGymFactorData.rotateImages.find(x => x.filename === filename);

        if (image) {
            if ((image.rotateDegree || 0) === 0) {
                image.rotateDegree = 90;
            }
            else if (image.rotateDegree === 270) {
                image.rotateDegree = 0;
            }
            else {
                image.rotateDegree += 90;
            }
            setGymFactorData({
                ...addGymFactorData,
                rotateImages: [...addGymFactorData.rotateImages.filter(x => x.filename !== filename), image]
            })
        }
        else {
            setGymFactorData({
                ...addGymFactorData,
                rotateImages: [...addGymFactorData.rotateImages, { filename, rotateDegree: 90 }]
            })
        }
    }

    const handleYoutubeLinkChange = (e) => {
        const { id, value } = e.target;
        setYoutubeVideosFields(prevFields =>
            prevFields.map(field =>
                field.id === parseInt(id) ? { ...field, value: value } : field
            )
        );
    }

    const handleTwitterLinkChange = (e) => {
        const { id, value } = e.target;
        setTwitterFields(prevFields =>
            prevFields.map(field =>
                field.id === parseInt(id) ? { ...field, value: value } : field
            )
        );
    }

    const handleCommentChange = (e) => {
        const description = e.target.value
        setGymFactorData({ ...addGymFactorData, description });
    }

    const handleGymProfileLinkChange = (e) => {
        const gymProfileLinks = e.target.value
        setGymFactorData({ ...addGymFactorData, gymProfileLinks });
    }

    const insertReview = async ({ addReview }) => {
        setDisabledButton(true);
        const fd = new FormData();
        let youtubeLinks = addReview.youtubeLinks;
        let twitterLinks = addReview.twitterLinks;
        if (youtubeLinks) {
            const youtubeValues = youtubeVideosFields.map((x) => x.value)
            youtubeLinks = youtubeValues.join(',') //youtubeValues.join(',') ? `${youtubeLinks}, ${youtubeValues.join(',')}` : youtubeLinks
        }
        else {
            const youtubeValues = youtubeVideosFields.map((x) => x.value)
            youtubeLinks = youtubeValues.join(',') ? youtubeValues.join(',') : ''
        }
        if (twitterLinks) {
            const twitterValues = twitterFields.map((x) => x.value)
            twitterLinks = twitterValues.join(',') //twitterValues.join(',') ? `${twitterLinks}, ${twitterValues.join(',')}` : twitterLinks
        }
        else {
            const twitterValues = twitterFields.map((x) => x.value)
            twitterLinks = twitterValues.join(',') ? twitterValues.join(',') : ''
        }

        fd.append('score', addReview.score);
        fd.append('description', addReview.description ?? "");
        fd.append('providerIds', addReview.providerIds && addReview.providerIds.length > 0 ? addReview.providerIds.join(',') : '');
        fd.append('youtubeLinks', youtubeLinks);
        fd.append('twitterLinks', twitterLinks);
        fd.append('gymProfileLinks', addReview.gymProfileLinks ?? null);

        if (hotelGymFactor?.id) {
            //UPDATE
            fd.append('id', hotelGymFactor.id);
            if (addReview.images.length > 0) {
                addReview.images.forEach((image, i) => {
                    fd.append('images', image, image.name)
                })
                fd.append('imageProviders', JSON.stringify(addReview.imageProviders))
                addReview.rotateImages.length > 0 && fd.append('rotateImages', JSON.stringify(addReview.rotateImages))
            }
            else {
                fd.append('imageProviders', addReview.imageProviders ? JSON.stringify(addReview.imageProviders) : "")
            }
            try {
                await updateGymFactor(fd);
                updateHotelReviewRequest(selectedHotel.bookingHotelId)
                ToastNotification('success', 'GymFactor updated successfully')
            } catch (error) {
                ToastNotification('error', 'Cannot update GymFactor. Please try again later.')
            }
            fetchData()
            setDisabledButton(false);
        }
        else {
            //ADD
            fd.append('hotelId', selectedHotel.hotelId);
            if (addReview.images.length > 0) {
                addReview.images.forEach((image, i) => {
                    fd.append('images', image, image.name)
                })
                fd.append('imageProviders', JSON.stringify(addReview.imageProviders))
                addReview.rotateImages.length > 0 && fd.append('rotateImages', JSON.stringify(addReview.rotateImages))
            }
            try {
                await addGymFactor(fd);
                updateHotelReviewRequest(selectedHotel.bookingHotelId)
                ToastNotification('success', 'GymFactor added successfully')
            } catch (error) {
                ToastNotification('error', 'Cannot add GymFactor. Please try again later.')
            }
            fetchData()
            setDisabledButton(false);
            // onReviewActionCompleted()
        }
    }

    const handleAddYoutubeField = () => {
        setYoutubeVideosFields(prevFields => [
            ...prevFields,
            { id: prevFields.length + 1, value: '' }
        ]);
    }

    const handleDeleteYoutubeField = (id) => {
        setYoutubeVideosFields(prevFields =>
            prevFields.filter(field => field.id !== id)
        );
    }

    const handleAddTwitterField = () => {
        setTwitterFields(prevFields => [
            ...prevFields,
            { id: prevFields.length + 1, value: '' }
        ]);
    }

    const handleDeleteTwitterField = (id) => {
        setTwitterFields(prevFields =>
            prevFields.filter(field => field.id !== id)
        );
    }

    const [loadingGymImages, setLoadingGymImages] = useState(false)
    const handleDeleteGymFactorImage = async (imageId) => {
        setLoadingGymImages(true)
        try {
            const { data: { result } } = await deleteGymFactorImage(imageId)
            if (result === 0) {
                return ToastNotification('error', 'Cannot Delete GymFactor Image')
            }
            hotelGymFactor.images = hotelGymFactor.images.filter(x => x.id !== imageId)
            setHotelGymFactor(hotelGymFactor)
            ToastNotification('success', 'GymFactor Image Deleted')
        } catch (error) {
            ToastNotification('error', 'Cannot Delete GymFactor Image')
        }
        setLoadingGymImages(false)
    }

    const handleUpdateMainImage = async (imageId, unAssign = false) => {
        try {
            setLoadingGymImages(true)
            const data = {
                isProfileImage: !unAssign
            }
            const { data: { result } } = await updateIsProfileImage(imageId, data)
            if (result === 0) {
                setLoadingGymImages(false)
                return ToastNotification('error', 'Cannot assign main image')
            }
            if (hotelGymFactor.images.length > 1) {
                let previousMainImageIndex = hotelGymFactor.images.findIndex(x => x.isProfileImage === true)
                if (previousMainImageIndex !== -1) {
                    hotelGymFactor.images[previousMainImageIndex].isProfileImage = false
                }
            }
            const mainImageIndex = hotelGymFactor.images.findIndex(x => x.id === imageId)

            if (unAssign) {
                hotelGymFactor.images[mainImageIndex].isProfileImage = false;
                setHotelGymFactor(hotelGymFactor)
                setLoadingGymImages(false)
                return ToastNotification('success', 'Main image unassigned')
            }
            if (hotelGymFactor.images[mainImageIndex].isProfileImage === true) {
                hotelGymFactor.images[mainImageIndex].isProfileImage = false;
            }
            else {
                hotelGymFactor.images[mainImageIndex].isProfileImage = true;
            }
            setHotelGymFactor(hotelGymFactor)
            ToastNotification('success', 'Main image added')
            setLoadingGymImages(false)
        }
        catch (err) {
            ToastNotification('error', 'Cannot assign main image')
            setLoadingGymImages(false)
        }
    }

    const handleUpdateIsGalleryImage = async (imageId, value) => {
        setLoadingGymImages(true)
        try {
            const data = {
                isGalleryImage: value
            }
            const { data: { result } } = await updateIsGalleryImage(imageId, data)
            if (result && result > 0) {
                const imageIndex = hotelGymFactor.images.findIndex(x => x.id === imageId);
                hotelGymFactor.images[imageIndex].isGalleryImage = value
                setHotelGymFactor(hotelGymFactor)
                ToastNotification('success', 'Image status changed successfully!')
            }
            else {
                ToastNotification('error', 'Oops! Cannot change image status. Please try again later.')
            }
        }
        catch (err) {
            ToastNotification('error', 'Oops! Cannot change image status. Please try again later.')
        }
        setLoadingGymImages(false)
    }

    const imageActionCompleted = ({ imageId, result, forImageProvider = false, forImageDeletion = false }) => {
        setLoadingGymImages(true)
        const index = hotelGymFactor.images.findIndex(x => x.id === imageId);
        if (result && index >= 0) {
            hotelGymFactor.images[index].provider = {
                name: result.providerName,
                id: result.providerId,
                code: result.providerName.toLowerCase()
            };
            hotelGymFactor.images[index].providerName = result.providerName;
            hotelGymFactor.images[index].providerId = result.providerId;
            if (forImageProvider) {
                setHotelGymFactor(hotelGymFactor)
            }
        }
        setLoadingGymImages(false)
    }

    const handleShowImageProviderModal = (photo) => {
        setShowImageProviderModal(!showImageProviderModal)
        setImageProvider(photo)
    }

    const handleOpenGallery = () => {
        const galleryImages = [];
        hotelGymFactor.images.map((image) => { galleryImages.push({ original: getImageUrl(gymImagesFolder, image.filename), thumbnail: getImageUrl(gymImagesFolder, image.filename) }) })
        setGalleryImage(galleryImages);
    }

    const handleShowImageGallery = () => {
        setShowImageGallery(!showImageGallery)
    }

    const handleImageError = (e) => {
        setGymImagesFolder('uploadsOriginal')
    }

    const returnMaxPoints = (score) => {
        if (allGymFactorDefinitions.length > 0) {
            return allGymFactorDefinitions.find(x => x.gymFactorScore === score).maxPoints;
        }
    };

    // Check if we should use points or score
    const usePoints = hotelGymFactor.allowScoreOverwrite !== null && hotelGymFactor.allowScoreOverwrite !== undefined && hotelGymFactor.allowScoreOverwrite && hotelGymFactor.points !== undefined && hotelGymFactor.points !== null && hotelGymFactor.points;

    // Progress Bar Ranges
    const score1 = returnMaxPoints(1);
    const score2 = returnMaxPoints(2.0) - score1;
    const score3 = returnMaxPoints(3.0) - score2 - score1;
    const score4 = returnMaxPoints(4.0) - score3 - score2 - score1;
    const score5 = returnMaxPoints(5.0) - score4 - score3 - score2 - score1;

    const scores = [score1, score2, score3, score4, score5];
    const labels = ['Poor', 'Okay', 'Good', 'Excellent', 'Perfect'];

    // Reference value for progress calculation (points if allowed, otherwise score)
    let referenceValue = usePoints ? hotelGymFactor.points : hotelGymFactor.score;
    if (!referenceValue) referenceValue = 0; // Fallback if neither score nor points are provided

    // Calculate percentages and find the active part for the progress
    let remainingPoints = usePoints ? referenceValue : returnMaxPoints(referenceValue);
    const percentages = scores.map((score) => {
        if (remainingPoints <= 0) return 0;
        const percentage = Math.min((remainingPoints / score) * 100, 100);
        remainingPoints -= score;
        return percentage;
    });

    // Determine the normalized score (1 to 5) based on which progress bar part is filled
    let normalizedValue = 1;
    remainingPoints = usePoints ? referenceValue : returnMaxPoints(referenceValue);
    for (let i = 0; i < scores.length; i++) {
        if (remainingPoints <= scores[i]) break;
        remainingPoints -= scores[i];
        normalizedValue++;
    }

    // Determine which label to bold based on the normalized value
    const boldLabelIndex = normalizedValue - 1;


    const providersData = allProviders;
    providersData.forEach((x, index) => (
        x.id === 2 ? providersData.unshift(providersData.splice(index, 1)[0])
            : x.id === 7 ? providersData.unshift(providersData.splice(index, 1)[0])
                : x.id === 8 ? providersData.unshift(providersData.splice(index, 1)[0])
                    : x.id === 20 ? providersData.unshift(providersData.splice(index, 1)[0])
                        : x.id === 9 ? providersData.unshift(providersData.splice(index, 1)[0])
                            : x.id === 3 ? providersData.unshift(providersData.splice(index, 1)[0])
                                : x.id === 25 ? providersData.unshift(providersData.splice(index, 1)[0])
                                    : undefined
    ))

    return (
        <div className='flex flex-col w-full'>
            {
                loading ?
                    <div className='flex items-center justify-center w-full h-[150px]'>
                        <LoadingDots customStyles={'mx-[10px]'} dotsNumber={3} />
                    </div>
                    :
                    <>
                        <div className='flex w-full'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>GymFactor Label</span>
                        </div>
                        <div className='flex flex-wrap mt-[20px] gap-[20px] lg:gap-[50px]'>
                            <div key={selectedHotel.bookingHotelId} className='flex items-center'>
                                {gymFactorObject && gymFactorObject?.icon &&
                                    <div className='flex items-center justify-center w-[60px] h-[60px] rounded-[10px] border border-black mr-[10px]'>
                                        <span className='text-[40px] font-bold'>
                                            {addGymFactorData.score}
                                        </span>
                                    </div>
                                }
                                {gymFactorOptions?.length > 0 &&
                                    <div className="flex items-center justify-content-center h-[44.5px]">
                                        <Dropdown
                                            options={gymFactorOptions}
                                            value={addGymFactorData.score}
                                            onChange={(e) => selectUpdateGymFactor(e)}
                                            optionLabel="label"
                                            placeholder="Select a Score"
                                            className="w-full md:w-[200px] rounded-[8px] primeDropdownStyle"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='flex items-center border-[2px] rounded-[10px] p-[15px]'>
                                <span className='text-[15px]'>Allow score overwrite</span>
                                <div className="ml-[10px] w-[114px] h-[33px] justify-start items-center gap-4 inline-flex cursor-pointer">
                                    <div className="bg-zinc-100 rounded-[6px] justify-start items-start flex">
                                        <div onClick={() => handleUpdateAllowScoreOverwrite(true)} className={`${hotelGymFactor.allowScoreOverwrite === true ? 'bg-gf_title_green' : undefined} rounded-[6px] justify-center items-center flex w-[54px] h-[33px] transition duration-200`}>
                                            <span className={`${hotelGymFactor.allowScoreOverwrite === true ? 'text-white' : 'text-black'} text-[14px] font-medium`}>YES</span>
                                        </div>
                                        <div onClick={() => handleUpdateAllowScoreOverwrite(false)} className={`${hotelGymFactor.allowScoreOverwrite === false ? 'bg-gf_title_red' : undefined} rounded-[6px] justify-center items-center flex w-[54px] h-[33px] transition duration-200`}>
                                            <span className={`${hotelGymFactor.allowScoreOverwrite === false ? 'text-white' : 'text-black'} text-[14px] font-medium`}>NO</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-wrap border-[2px] rounded-[10px] gap-[20px] md:gap-[20px] lg:gap-[50px] p-[15px] w-auto'>
                                <div className='flex gap-[50px] md:gap-[20px] lg:gap-[50px]'>
                                    <div className='flex flex-col'>
                                        <span className='text-[15px] font-bold'>Review Created</span>
                                        <span className='text-[15px]'>
                                            {hotelGymFactor?.creationTime ? formatShortDate(hotelGymFactor.creationTime) : 'No data'}
                                        </span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='text-[15px] font-bold'>Last Updated</span>
                                        <span className='text-[15px]'>
                                            {hotelGymFactor?.lastModificationTime ? formatShortDate(hotelGymFactor.lastModificationTime) : 'No data'}
                                        </span>
                                    </div>
                                </div>
                                <div className='flex gap-[50px] md:gap-[20px] lg:gap-[50px]'>
                                    <div className='flex flex-col'>
                                        <span className='text-[15px] font-bold'>Updated by</span>
                                        <span className='text-[15px]'>
                                            Staff
                                        </span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='text-[15px] font-bold'>Review Request</span>
                                        <span className='text-[15px]'>
                                            {selectedHotel.hasReviewRequests ? 'Yes' : 'No'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[10px]'>
                            <span className='w-full text-[18px] font-bold'>Notes</span>
                            <InputTextarea
                                value={addGymFactorData.description}
                                onChange={(e) => handleCommentChange(e)}
                                rows={3}
                                cols={30}
                                className='primeDropdownStyle'
                            />
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>GymFactor Progress Bar</span>
                        </div>
                        <div className='flex flex-col lg:flex-row mt-[20px] lg:mt-[30px] gap-[20px]'>
                            <div className='flex flex-col border-[2px] rounded-[10px] p-[15px] w-full lg:w-[280px] lg:min-w-[280px]'>
                                <div className='flex w-full border-b mb-[3px] pb-[3px]'>
                                    <span className='text-[15px]'>Total Points</span>
                                    <span className='text-[15px] font-bold ml-auto w-[36px] flex items-center justify-center'>{hotelGymFactor.points || "NA"}</span>
                                </div>
                                <div className='flex w-full border-b mb-[3px] pb-[3px]'>
                                    <span className='text-[15px]'>Points till next Score</span>
                                    <span className='text-[15px] font-bold ml-auto w-[36px] flex items-center justify-center'>{tillNextScore || "NA"}</span>
                                </div>
                                <div className='flex w-full border-b mb-[3px] pb-[3px]'>
                                    <span className='text-[15px]'>Completeness</span>
                                    <span className='text-[15px] font-bold ml-auto w-[36px] flex items-center justify-center'>{hotelGymFactor.completeness ? `${hotelGymFactor.completeness}%` : "NA"}</span>
                                </div>
                                <div className='flex w-full'>
                                    <span className='text-[15px]'>Recommended GymFactor</span>
                                    <span className='text-[15px] font-bold ml-auto w-[36px] flex items-center justify-center'>{hotelGymFactor.recommendedScore || "NA"}</span>
                                </div>
                            </div>
                            <div className='flex flex-col border-[2px] rounded-[10px] p-[15px] w-full'>
                                <div className='flex'>
                                    <span className='text-[15px] font-bold'>Overall gym score</span>
                                    <span className='text-[15px] ml-auto'>{`${normalizedValue} out of 5`}</span>
                                </div>
                                <div className='flex w-full gap-[5px] mt-[20px]'>
                                    {percentages.map((percentage, index) => (
                                        <div key={index} className="flex flex-1 flex-col">
                                            <div className="w-full bg-gray-200 h-3 rounded">
                                                <div
                                                    className="bg-gf_progress_gold h-3 rounded"
                                                    style={{ width: `${percentage}%` }}
                                                ></div>
                                            </div>
                                            <span className={(index === boldLabelIndex) ? 'text-[15px] mt-[6px] font-bold' : 'text-[15px] mt-[6px]'}>{labels[index]}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>GymFactor Images</span>
                        </div>
                        <div className='flex flex-col mt-[20px] lg:mt-[30px]'>
                            <div className='flex'>
                                <input accept="image/x-png,image/jpg,image/jpeg" id="icon-button-file" type="file" multiple onChange={(e) => handleUploadImage(e)} className='hidden' />
                                <label htmlFor="icon-button-file" className='flex items-center justify-center h-[44.5px] px-[15px] bg-black rounded-[8px] cursor-pointer'>
                                    <img
                                        className='w-[20px] h-[20px] mr-[10px] filter invert brightness-0'
                                        src={blobStorageUrl('app', 'add-photo-icon.svg')}
                                        alt="Add Photos icon | HotelGyms.com"
                                        width='20'
                                        height='20'
                                    />
                                    <span className='text-[15px] text-white font-bold'>ADD PHOTOS</span>
                                </label>
                                <div className='flex flex-wrap ml-[20px]'>
                                    {addGymFactorData?.images?.length > 0 &&
                                        addGymFactorData.images.map((image, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='flex flex-col mb-[15px] mr-[15px] w-[166px] max-w-[166px] min-w-[166px] rounded-[8px] border relative'
                                                >
                                                    <div className='flex'>
                                                        <img
                                                            className='w-[164px] min-w-[164px] h-[70px] object-cover rounded-t-[7px]'
                                                            src={URL.createObjectURL(image)}
                                                            alt="Reviews Photos | HotelGyms.com"
                                                            width='164'
                                                            height='70'
                                                        />
                                                    </div>
                                                    <div className='flex justify-end items-center cursor-pointer pt-[10px] pr-[10px]'>
                                                        <div
                                                            className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px]'
                                                            onClick={() => handleDeleteAddedReviewImage(image.name)}
                                                        >
                                                            <img
                                                                src={blobStorageUrl('app', 'Delete.svg')}
                                                                alt='Delete icon | HotelGyms.com'
                                                                className='w-[14px] h-[16px] cursor-pointer'
                                                                width='14'
                                                                height='16'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex'>
                                                        <div className='w-[164px] max-w-[164px] min-w-[164px]'>
                                                            <div className='flex flex-col mx-[10px] mb-[10px]'>
                                                                <span className='text-[13px] text-gf_title_red italic'>* Provider</span>
                                                                {providersData &&
                                                                    <div className="flex items-center justify-content-center h-[44.5px]">
                                                                        <Dropdown
                                                                            filter
                                                                            options={providersData.filter(provider => provider.isImageSource === true).map(p => ({ value: parseInt(p.id), label: p.name }))}
                                                                            value={addGymFactorData.imageProviders.find(x => x.filename === image.name)?.providerId}
                                                                            onChange={(e) => handleSaveImageProvider({ e, provider: e.target.value, fileName: image.name })}
                                                                            optionLabel="label"
                                                                            placeholder="Select..."
                                                                            className="rounded-[8px] primeDropdownStyle provider-dropdown-custom"
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                            <div className='flex flex-col mt-[30px]'>
                                {hotelGymFactor.images?.length > 0 ?
                                    <div className='flex flex-col'>
                                        <span className='text-[15px] font-bold'>Uploaded Images:</span>
                                        {loadingGymImages ?
                                            <div className='flex h-[150px] items-center justify-center'>
                                                <LoadingDots customStyles={'mx-[10px]'} dotsNumber={3} />
                                            </div>
                                            :
                                            <div key={hotelGymFactor?.images} className='flex mt-[10px] flex-wrap gap-[15px]'>
                                                {hotelGymFactor.images.map((image, index) => {
                                                    const photo = image;
                                                    return (
                                                        <figure key={hotelGymFactor?.images[index]?.provider?.providerId} className='flex border rounded-[8px] gap-[10px] w-[150px] max-w-[150px] min-w-[150px]'>
                                                            <div className='flex flex-col'>
                                                                <div className='flex'>
                                                                    <img
                                                                        key={photo.filename + index}
                                                                        width="150"
                                                                        height="70"
                                                                        src={`${getImageUrl(gymImagesFolder, photo.filename)}`}
                                                                        ref={imgRef}
                                                                        className={`w-[150px] h-[70px] object-cover rounded-t-[7px] cursor-pointer
                                                                        ${photo.status === 2 ?
                                                                                "mb-[10px] edit-image bad-image-border" :
                                                                                photo.status === 3 ?
                                                                                    "mb-[10px] edit-image ignored-image-border" :
                                                                                    "mb-[10px] edit-image"}
                                                                    `}
                                                                        alt='Reviews Photos | HotelGyms.com'
                                                                        onClick={() => { handleOpenGallery(); setShowImageGallery(true) }}
                                                                        onError={() => handleImageError()}
                                                                    />
                                                                    {/* <img onClick={() => handleDeleteGymFactorImage(image.id)} src={blobStorageUrl('app', 'Delete.svg')} alt='delete' title="Delete GF Image" style={{ cursor: 'pointer' }} /> */}
                                                                </div>
                                                                <div className='flex px-[10px]'>
                                                                    <span className='hover:underline text-[12px] text-color_primary_blue'><i className='text-[12px] cursor-pointer colored' onClick={() => handleShowImageProviderModal(photo)}>{photo.providerName}</i></span>
                                                                </div>
                                                                <div className='flex items-center justify-between align-center p-[10px]'>
                                                                    {photo.isProfileImage === true ?
                                                                        <div
                                                                            className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                                            onClick={() => handleUpdateMainImage(photo.id, true)}
                                                                        >
                                                                            <AppImage
                                                                                name={'star-icon.png'}
                                                                                folder={`app`}
                                                                                alt='star-icon'
                                                                                title='Change main image'
                                                                                className='cursor-pointer w-[15px]'
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                                            onClick={() => handleUpdateMainImage(photo.id, false)}
                                                                        >
                                                                            <AppImage
                                                                                name={'empty-star-icon.png'}
                                                                                folder={`app`}
                                                                                alt='star-icon'
                                                                                title='Main Image'
                                                                                className='cursor-pointer w-[15px]'
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <div
                                                                        className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                                        onClick={() => handleUpdateIsGalleryImage(photo.id, !photo.isGalleryImage)}
                                                                    >
                                                                        {
                                                                            !photo.isGalleryImage &&
                                                                            <AppImage
                                                                                name={'dumbell.svg'}
                                                                                folder={`app`}
                                                                                className='flex w-[15px] cursor-pointer'
                                                                                alt='Dumbell-icon'
                                                                                title='Not a gallery image'
                                                                            />
                                                                        }
                                                                        {
                                                                            photo.isGalleryImage &&
                                                                            <AppImage
                                                                                name={'dumbell-filled.svg'}
                                                                                folder={`app`}
                                                                                className='flex w-[15px] cursor-pointer'
                                                                                alt='Dumbell-icon'
                                                                                title='Gallery image'
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                                        onClick={() => handleDeleteGymFactorImage(photo.id)}
                                                                    >
                                                                        <AppImage
                                                                            name={'Delete.svg'}
                                                                            folder={`app`}
                                                                            alt='delete-icon'
                                                                            title="Delete GF Image"
                                                                            className='cursor-pointer w-[13px]'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </figure>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>GymFactor Media</span>
                        </div>
                        <div className='flex mt-[20px] lg:mt-[30px]'>
                            <div className='flex flex-col gap-[10px]'>
                                <span className='text-[15px] font-bold'>YouTube Links</span>
                                <button
                                    onClick={handleAddYoutubeField}
                                    type="submit"
                                    className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black flex items-center`}>
                                    <b className='text-[20px] leading-[15px] mb-[2px] mr-[5px]'>+</b> ADD YOUTUBE LINK
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col mt-[15px]'>
                            <div className='flex w-full'>
                                <span className='text-[13px] italic text-color_text_gray_light'>
                                    NOTE: Preferred format: https://www.youtube.com/watch?v=6bEAokRm9To&t=63s (add "&t=63s" and video will start from 63rd second)
                                </span>
                            </div>
                            {youtubeVideosFields.map((x, index) => {
                                return (
                                    <div key={index} className='flex items-center w-full mt-[5px]'>
                                        <input
                                            type="text"
                                            name="youtubeLink"
                                            value={x.value}
                                            id={x.id}
                                            key={`k- ${x.id}`}
                                            onChange={(e) => handleYoutubeLinkChange(e)}
                                            className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                            placeholder='Paste YouTube Link here...'
                                        />
                                        <button
                                            onClick={() => handleDeleteYoutubeField(x.id)}
                                            type="submit"
                                            className={`ml-[15px] h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black flex items-center`}>
                                            <b className='text-[20px] leading-[15px] mb-[2px] mr-[5px]'>-</b> REMOVE
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='flex mt-[20px] lg:mt-[30px]'>
                            <div className='flex flex-col gap-[10px]'>
                                <span className='text-[15px] font-bold'>Twitter Links</span>
                                <button
                                    onClick={handleAddTwitterField}
                                    type="submit"
                                    className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black flex items-center`}>
                                    <b className='text-[20px] leading-[15px] mb-[2px] mr-[5px]'>+</b> ADD TWITTER LINK
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col mt-[15px]'>
                            {twitterFields.map((x, index) => {
                                return (
                                    <div key={index} className='flex items-center w-full mt-[5px]'>
                                        <input
                                            type="text"
                                            name="twitterLink"
                                            value={x.value}
                                            id={x.id}
                                            key={`k- ${x.id}`}
                                            onChange={(e) => handleTwitterLinkChange(e)}
                                            className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                            placeholder='Paste Twitter Link here...'
                                        />
                                        <button
                                            onClick={() => handleDeleteTwitterField(x.id)}
                                            type="submit"
                                            className={`ml-[15px] h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black flex items-center`}>
                                            <b className='text-[20px] leading-[15px] mb-[2px] mr-[5px]'>-</b> REMOVE
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='flex mt-[20px] lg:mt-[30px]'>
                            <div className='flex'>
                                <span className='text-[15px] font-bold'>Fitbod Gym Profile</span>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='flex items-center w-full mt-[5px]'>
                                <input
                                    type="text"
                                    name="gympProfileLink"
                                    value={addGymFactorData.gymProfileLinks}
                                    onChange={(e) => handleGymProfileLinkChange(e)}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='Paste Gym Profile Link here...'
                                />
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Source</span>
                        </div>
                        <div className='flex mt-[20px] lg:mt-[30px]'>
                            <div className='flex flex-wrap gap-[20px] md:gap-[30px] w-full'>
                                {groupedGymFactorSourceProviders &&
                                    Object.keys(groupedGymFactorSourceProviders).map((type, index) => {
                                        return (
                                            <div key={index} className='flex flex-col gap-[10px]'>
                                                <span className='text-[15px] font-bold'>{gymFactorSourceTypes[type]}</span>
                                                <div className='flex flex-wrap flex-col gap-[5px]'>
                                                    {groupedGymFactorSourceProviders[type].map((provider, index) => {
                                                        return (
                                                            <div key={provider.id} className='flex items-center'>
                                                                <Checkbox
                                                                    onInputChange={handleAddPartners}
                                                                    name={provider.id}
                                                                    id={provider.id}
                                                                    checkboxText={provider.name}
                                                                    checkboxChecked={addGymFactorData.providerIds.includes(provider.id.toString())}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        <div className='flex border-t mt-[30px] pt-[25px] pb-[9px] mx-[-16px] calculator-footer-sticky bg-white'>
                            <button
                                onClick={() => insertReview({ addReview: addGymFactorData })}
                                type="submit"
                                disabled={
                                    disabledButton ||
                                    addGymFactorData.score === 0 ||
                                    (
                                        addGymFactorData.images &&
                                        (addGymFactorData.images?.length !== addGymFactorData.imageProviders?.length)
                                    )
                                }
                                className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black ml-[16px]`}>
                                {hotelGymFactor?.id ? 'SAVE' : 'ADD REVIEW'} {disabledButton &&
                                    <LoadingDots customStyles={'bg-white'} dotsNumber={1} />
                                }
                            </button>
                        </div>
                    </>
            }
            {
                showImageProviderModal &&
                <ImageProviderModal
                    handleClose={handleShowImageProviderModal}
                    image={imageProvider}
                    onActionCompleted={imageActionCompleted}
                    allProviders={providersData}
                />
            }
            {showImageGallery &&
                <GymImageGalleryModal
                    gymPhotos={galleryImage}
                    handleShowImageGallery={handleShowImageGallery}
                />
            }
        </div>
    );
};

export default EditorPageGymFactorScore;