import { useEffect } from 'react';

export const HasHotelGym = (hasFitnessFacility, gymFactorScore) => {
    let hasHotelGym = undefined;
    if (hasFitnessFacility) hasHotelGym = true;
    else if (gymFactorScore && gymFactorScore > 0) hasHotelGym = true;
    else hasHotelGym = undefined;
    return hasHotelGym;
}

export const useOutsideClick = (refs, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            let isOutside = true;

            for (const ref of refs) {
                if (ref.current && ref.current.contains(event.target)) {
                    isOutside = false;
                    break;
                }
            }

            if (isOutside && callback) {
                callback();
            }
        };

        const handleGlobalClick = (event) => {
            handleClickOutside(event);
        };

        document.addEventListener('mousedown', handleGlobalClick);

        return () => {
            document.removeEventListener('mousedown', handleGlobalClick);
        };
    }, [refs, callback]);
};

export function getImageUrl(folder, filename) {
    if (
        (
            folder === 'uploadsOriginal' ||
            folder === 'original' ||
            folder === 'web' ||
            folder === 'mobile' ||
            folder === 'thumbnail' ||
            folder === 'optimized'
        ) &&
        filename &&
        !filename.includes("jpg")) {
        filename = filename + '.jpg'
    }
    const fileRoute = folder === 'uploadsOriginal' ||
        folder === 'original' ||
        folder === 'web' ||
        folder === 'mobile' ||
        folder === 'thumbnail' ||
        folder === 'optimized' ?
        `images/gym/optimized/${filename}` :
        `images/${folder}/${filename}`
    return `${process.env.REACT_APP_IMAGE_BASE_URL}/${fileRoute}`
}

export function gymImages(platform, filename) {
    if (platform != 'uploadsOriginal') {
        if (!filename?.includes(".jpg")) {
            filename = filename + ".jpg"
        }
    }
    switch (platform) {
        case 'allimages':
            return `${process.env.REACT_APP_CLOUD_IMAGE_GYM}/${filename}`;
        case 'original':
            return `${process.env.REACT_APP_CLOUD_IMAGE_GYM}/${filename}`;
        case 'web':
            return `${process.env.REACT_APP_IMAGE_BASE_URL}/images/gym/web/${filename}`.replace(".jpg", "");
        case 'mobile':
            return `${process.env.REACT_APP_IMAGE_BASE_URL}/images/gym/mobile/${filename}`.replace(".jpg", "");
        case 'thumbnail':
            return `${process.env.REACT_APP_IMAGE_BASE_URL}/images/gym/thumbnail/${filename}`.replace(".jpg", "");
        case 'uploadsOriginal':
            return `${process.env.REACT_APP_CLOUD_IMAGE_GYM_UNPROCESSED}/${filename}`;
        default:
            return `${process.env.REACT_APP_CLOUD_IMAGE_GYM}/${filename}`;
    }
}

export function blobStorageUrl(folder, filename) {
    if (folder === "original" && !filename?.includes(".jpg")) {
        filename = filename + ".jpg"
    }

    if (folder == 'uploadsOriginal') {
        return `${process.env.REACT_APP_CLOUD_IMAGE_GYM_UNPROCESSED}/${filename}`
    }
    else if (folder == 'web' || folder == 'mobile' || folder == 'thumbnail' || folder == 'original') {
        return `${process.env.REACT_APP_CLOUD_IMAGE_GYM}/${filename}`
    }
    else if (folder == 'blog') {
        return `${process.env.REACT_APP_CLOUD_IMAGE_BLOG}/${filename}`
    }
    else if (folder == 'appCloudImage') {
        return `${process.env.REACT_APP_CLOUD_IMAGE_APP}/${filename}`
    }
    else if (folder == 'location') {
        return `${process.env.REACT_APP_CLOUD_IMAGE_LOCATION}/${filename}`
    }
    else {
        return `${process.env.REACT_APP_IMAGE_BASE_URL}/images/${folder}/${filename}`
    }
}

export const youtubeRegex = "^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))";