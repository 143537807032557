import React, { useEffect, useState, useRef } from 'react';
import { deleteExperience, deleteExperienceImage, updateExperience, updateHotelReviewRequest, updateIsGalleryImage, updateIsProfileImage } from '../redux.js/api';
import ToastNotification from './ToastNotification';
import { formatShortDate } from './formatting';
import { blobStorageUrl } from './HelperComponent';
import Avatar from 'react-avatar';
import LoadingDots from './LoadingDots';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { Grid, TextField } from '@mui/material';
import { format, parseISO } from 'date-fns';
import AppImage from './AppImage';
import ImageProviderModal from './ImageProviderModal';
import { GymImageGalleryModal } from './GymImageGalleryModal';
import { InputTextarea } from 'primereact/inputtextarea';

const DEFAULT_FOLDER = 'original';

const EditorPageTravelerExperiences = ({
    hotelReviews,
    onReviewActionCompleted,
    hotel,
    onHandleSaveExperience,
    onHandleUpdateExperience,
    allProviders
}) => {
    return (
        <div className='flex flex-col w-full'>
            {hotelReviews.map((review, index) => (
                <Review
                    key={index}
                    index={index}
                    review={review}
                    comingHotelReviews={hotelReviews}
                    onReviewActionCompleted={onReviewActionCompleted}
                    hotel={hotel}
                    onHandleSaveExperience={onHandleSaveExperience}
                    onHandleUpdateExperience={onHandleUpdateExperience}
                    allProviders={allProviders}
                />
            ))}
        </div>
    );
};

export default EditorPageTravelerExperiences;

const Review = ({
    index,
    review,
    comingHotelReviews,
    onReviewActionCompleted,
    hotel,
    onHandleSaveExperience,
    onHandleUpdateExperience,
    allProviders,
    editFlaggedReview = false
}) => {
    const [editReview, setEditReview] = useState(review);
    const [hotelReviews, setHotelReviews] = useState(comingHotelReviews);
    const [display, setDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showImageProviderModal, setShowImageProviderModal] = useState(false)
    const [showImageGallery, setShowImageGallery] = useState(false)
    const [imageProvider, setImageProvider] = useState(0);
    const [deleteRatingLoading, setDeleteRatingLoading] = useState(false)
    const [loadingAutoReviews, setLoadingAutoReviews] = useState(false)
    const [loadingDeleteImageId, setLoadingDeleteImageId] = useState(false)
    const [mainImage, setMainImage] = useState(false)
    const [galleryImage, setGalleryImage] = useState("");
    const [loadingExperienceImages, setLoadingExperienceImages] = useState(false)

    const imgRef = useRef()

    useEffect(() => {
        if (review && review.images && review.images.length > 0) {
            const updatedImages = review.images.map(image => {
                const imageDate = new Date(image.creationTime);
                const today = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
                const folderName = (today - imageDate) < (2 * 60 * 1000) ? 'uploadsOriginal' : 'original';
                return {
                    ...image,
                    folderName,
                };
            });
            setEditReview({
                ...review,
                images: updatedImages,
            });
        }
        else {
            setEditReview(review);
        }
    }, [review, review.images, review.user]);

    const handleChangeReviewComment = (e) => {
        setEditReview({ ...editReview, description: e.target.value })
    }
    const handleChangeDisplayEdit = () => {
        setDisplay(!display)
    };

    const handleChangeReviewDate = (date) => {
        if (date) {
            setEditReview({ ...editReview, date: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") });
        } else {
            setEditReview({ ...editReview, date: '' });
        }
    };

    // Convert the ISO string to a Date object
    const dateValue = editReview.date ? parseISO(editReview.date) : null;

    const editExperience = async (editReview, hotelId) => {
        const fd = new FormData();

        fd.append('id', editReview.id)
        fd.append('description', editReview.description ? editReview.description : '')
        fd.append('hotelId', hotelId)
        fd.append('date', editReview.date)

        if (editReview.additionalImages?.length > 0) {
            editReview.additionalImages.forEach((image, i) => {
                fd.append('images', image, image.name)
            })
            fd.append('imageProviders', JSON.stringify(editReview.imageProviders))
        }
        const { data: { result } } = await updateExperience(fd);
        return result
    }

    const handleEditReview = async (editReview) => {
        setLoading(true);
        try {
            if (editReview?.additionalImages?.length > 0) {
                const imageProviders = editReview.additionalImages.map((x) => ({
                    filename: x.name,
                    providerId: 24,
                }));
                editReview = { ...editReview, imageProviders };
            }
            const result = await editExperience(editReview, hotel.hotelId);
            if (editReview.reviewRequested) updateHotelReviewRequest(hotel.bookingHotelId);
            const editedExperienceIndex = hotelReviews.findIndex((x) => x.id === result.id);
            onHandleUpdateExperience(result, 'editReview', editedExperienceIndex)
            setLoading(false);
            ToastNotification('success', 'Experience edited successfully!')
            if (!editFlaggedReview) {
                onReviewActionCompleted()
            }
            // else {
            //     onFlaggedReviewActionCompleted(editReview.id)
            // }
        } catch (error) {
            setLoading(false);
            ToastNotification('error', 'Cannot edit experience!')
        }
    }

    const handleDeleteRating = async (reviewId) => {
        setDeleteRatingLoading(true)
        await deleteExperience(reviewId)
        const deletedExperiencesResult = hotelReviews.filter((r) => r.id !== reviewId)
        onHandleSaveExperience(deletedExperiencesResult)
        if (!editFlaggedReview) {
            onReviewActionCompleted()
        }
        // else {
        //     onFlaggedReviewActionCompleted(reviewId)
        // }
        ToastNotification('success', 'Review Deleted!')
        setDeleteRatingLoading(false)
    }

    const handleDeleteImage = async (imageId) => {
        setLoadingExperienceImages(true)
        try {
            setLoadingDeleteImageId(imageId)
            await deleteExperienceImage(imageId)
            imageActionCompleted({ imageId, forImageDeletion: true })
            setLoadingDeleteImageId(false)
            setLoadingExperienceImages(false)
            ToastNotification('success', 'Image Deleted!')
        } catch (error) {
            setLoadingDeleteImageId(false)
            setLoadingExperienceImages(false)
            ToastNotification('error', 'Cannot Delete Image')
        }
    }

    // const handleReplaceImage = async ({ e, imageId }) => {
    //     const fd = new FormData();
    //     e.target.files.forEach((file, i) => {
    //         fd.append('image', file, file.name)
    //     })
    //     fd.append('imageId', imageId)
    //     fd.append('image', e.target.files)
    //     const { data: { result } } = await replaceReviewImage(fd)
    //     if (result && !editFlaggedReview) {
    //         onReviewActionCompleted()
    //     }
    //     if (result && editFlaggedReview) {
    //         imageActionCompleted({ imageId, result })
    //     }
    // }

    const imageActionCompleted = ({ imageId, result, forImageProvider = false, forImageDeletion = false }) => {
        setLoadingExperienceImages(true)
        if (forImageDeletion) {
            setEditReview({ ...editReview, images: editReview.images.filter(x => x.id !== imageId) })
            setDisplay(!display)
        }
        const index = editReview.images.findIndex(x => x.id === imageId)
        if (result) {
            editReview.images[index] = result;
            if (forImageProvider) {
                setEditReview(editReview)
            }
            else {
                setEditReview(editReview)
                setDisplay(!display)
            }
        }
        setDisplay(true)
        setLoadingExperienceImages(false)
    }

    const handleShowImageProviderModal = (fileName) => {
        setShowImageProviderModal(!showImageProviderModal)
        setImageProvider(fileName)
    }

    const handleOpenGallery = () => {
        const galleryImages = [];
        editReview.images.map((photo) => { galleryImages.push({ original: `https://cdn-imgs.hotelgyms.com/${blobStorageUrl(photo.folderName, photo.filename)}`, thumbnail: `https://cdn-imgs.hotelgyms.com/${blobStorageUrl(photo.folderName, photo.filename)}` }) })
        setGalleryImage(galleryImages);
    }

    const handleShowImageGallery = (mainImageSrc) => {
        setShowImageGallery(!showImageGallery)
        if (mainImageSrc) setMainImage(mainImageSrc)
    }

    const handleUploadAdditionalImage = (e) => {
        const newImages = Array.from(e.target.files);
        // Check if any image is of type 'image/heic' or 'image/heif'
        const heicOrHeifImage = newImages.find(img => img.type === 'image/heic' || img.type === 'image/heif');
        if (heicOrHeifImage) {
            ToastNotification('error', 'HEIC/HEIF images are not supported. Please upload images of JPG format.')
            return;
        }
        // Check file extension (for example, in case the MIME type is already converted)
        const heicExtensionImage = newImages.find(img => img.name.toLowerCase().endsWith('.heic') || img.name.toLowerCase().endsWith('.heif'));
        if (heicExtensionImage) {
            ToastNotification('error', 'HEIC/HEIF images are not supported. Please upload images of JPG format.')
            return;
        }

        if (!editReview.additionalImages) editReview.additionalImages = [];
        const existingImageNames = editReview.additionalImages.map(img => img.name);
        const filteredNewImages = newImages.filter(img => !existingImageNames.includes(img.name));
        setEditReview(prevState => ({
            ...prevState,
            additionalImages: [...prevState.additionalImages, ...filteredNewImages]
        }));
    }

    const handleDeleteAdditionalImage = (filename) => {
        setEditReview({ ...editReview, additionalImages: [...editReview.additionalImages.filter(image => image.name !== filename)] })
    }

    const handleUpdateMainImage = async (index, imageId, unAssign = false) => {
        try {
            setLoadingExperienceImages(true)
            const data = {
                isProfileImage: !unAssign
            }
            const { data: { result } } = await updateIsProfileImage(imageId, data)
            if (result === 0) {
                setLoadingExperienceImages(false)
                return ToastNotification('error', 'Cannot assign main image')
            }
            if (editReview.images.length > 1) {
                let previousMainImageIndex = editReview.images.findIndex(x => x.isProfileImage === true)
                if (previousMainImageIndex !== -1) {
                    editReview.images[previousMainImageIndex].isProfileImage = false
                }
            }
            const mainImageIndex = editReview.images.findIndex(x => x.id === imageId)

            if (unAssign) {
                editReview.images[mainImageIndex].isProfileImage = false;
                let allExperiencesResult = [
                    ...hotelReviews.slice(0, index),
                    editReview,
                    ...hotelReviews.slice(index + 1),
                ]
                onHandleSaveExperience(allExperiencesResult);
                setLoadingExperienceImages(false)
                return ToastNotification('success', 'Main image unassigned')
            }
            if (editReview.images[mainImageIndex].isProfileImage === true) {
                editReview.images[mainImageIndex].isProfileImage = false;
                let allExperiencesResult = [
                    ...hotelReviews.slice(0, index),
                    editReview,
                    ...hotelReviews.slice(index + 1),
                ]
                onHandleSaveExperience(allExperiencesResult);
            }
            else {
                editReview.images[mainImageIndex].isProfileImage = true;
                let allExperiencesResult = [
                    ...hotelReviews.slice(0, index),
                    editReview,
                    ...hotelReviews.slice(index + 1),
                ]
                onHandleSaveExperience(allExperiencesResult);
            }
            setEditReview(editReview)
            setDisplay(true)
            setLoadingExperienceImages(false)
            ToastNotification('success', 'Main image added')
        }
        catch (err) {
            setLoadingExperienceImages(false)
            ToastNotification('error', 'Cannot assign main image')
        }
    }

    const handleUpdateIsGalleryImage = async (imageId, value) => {
        setLoadingExperienceImages(true)
        try {
            const data = {
                isGalleryImage: value
            }
            const { data: { result } } = await updateIsGalleryImage(imageId, data)
            if (result && result > 0) {
                const imageIndex = editReview.images.findIndex(x => x.id === imageId);
                editReview.images[imageIndex].isGalleryImage = value;
                setEditReview(editReview)
                setDisplay(!display)
                setDisplay(true)
                setLoadingExperienceImages(false)
                ToastNotification('success', 'Changed image status')
            }
            else {
                setLoadingExperienceImages(false)
                ToastNotification('error', 'Cannot change image status')
            }
        }
        catch (err) {
            setLoadingExperienceImages(false)
            ToastNotification('error', 'Cannot change image status')
        }
    }

    const profilePicture = editReview.user && editReview.user.profilePicture ? editReview.user.profilePicture : "";
    return (
        <div key={index} className='flex flex-col my-[10px] border-[2px] rounded-[10px] p-[15px]'>
            <div className='flex items-center'>
                <div className='mr-[15px]'>
                    {profilePicture ?
                        <img
                            src={profilePicture}
                            className='w-[50px] h-[50px] rounded-full'
                            alt='User Profile | HotelGyms.com'
                        />
                        :
                        <Avatar
                            name={editReview.user ? editReview.user.displayName : 'User'}
                            size="50"
                            round={true}
                            color={Avatar.getRandomColor('sitebase', ['red', 'blue', 'green', 'purple', '#0098C9'])}
                        />
                    }
                </div>
                <div className='flex flex-col justify-center'>
                    <span className='text-[18px] font-bold'>{editReview.user ? editReview.user.displayName : 'User'}</span>
                    {!display ?
                        <span className='text-[15px] text-color_border_dark'>{formatShortDate(editReview.date)}</span>
                        :
                        <div className="flex">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container justifyContent="space-around">
                                    <DatePicker
                                        margin="normal"
                                        id="mui-pickers-date"
                                        value={dateValue}
                                        onChange={handleChangeReviewDate}
                                        renderInput={(params) => <TextField {...params} />}
                                        format='dd/MM/yyyy'
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </div>
                    }
                </div>
                {editReview.autoReview &&
                    <div className="flex mt-[5px] ml-[15px] bg-color_primary_blue h-[18px] px-[5px] rounded-[15px]">
                        <span className="text-[12px] text-white">Auto Review</span>
                    </div>
                }
                <div className='flex ml-auto'>
                    <button
                        className='text-[15px] text-white bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px] mr-[10px]'
                        onClick={() => handleChangeDisplayEdit({ id: editReview.id, editReview })}
                    >
                        {display ? 'Close' : 'Edit'}
                    </button>
                    <button
                        className='text-[15px] text-white bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]'
                        onClick={() => handleDeleteRating(editReview.id)}
                    >
                        {deleteRatingLoading ? <LoadingDots customStyles={'bg-white mx-[10px]'} dotsNumber={1} /> : 'Delete'}
                    </button>
                </div>
            </div>
            <div className='flex items-center w-full mt-[20px]'>
                {!display ?
                    <div className='flex border-b border-solid w-full pb-[5px]'>
                        <span className='text-[15px]'>
                            {editReview.description}
                        </span>
                    </div>
                    :
                    <div className='flex w-full'>
                        <InputTextarea
                            type="textarea"
                            name="Description"
                            value={editReview.description}
                            onChange={(e) => handleChangeReviewComment(e)}
                            rows={5}
                            className='primeDropdownStyle text-[15px] block p-[10px] rounded-[8px] border border-gray-300 w-full'
                        />
                    </div>
                }
            </div>
            {display &&
                <div className='flex mt-[20px]'>
                    <input accept="image/x-png,image/jpg,image/jpeg" id={`experience-additional-images-idx${index}`} type="file" multiple onChange={(e) => handleUploadAdditionalImage(e)} className='hidden' />
                    <label htmlFor={`experience-additional-images-idx${index}`} className='flex items-center justify-center h-[44.5px] px-[15px] bg-black rounded-[8px] cursor-pointer'>
                        <img
                            className='w-[20px] h-[20px] mr-[10px] filter invert brightness-0'
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/add-photo-icon.svg`}
                            alt="Add Photos icon | HotelGyms.com"
                            width='20'
                            height='20'
                        />
                        <span className='text-[15px] text-white font-bold'>ADD PHOTOS</span>
                    </label>
                    <div className='flex flex-wrap ml-[20px]'>
                        {editReview?.additionalImages?.length > 0 &&
                            editReview.additionalImages.map((image, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='flex flex-col mb-[15px] mr-[15px] w-[166px] max-w-[166px] min-w-[166px] rounded-[8px] border relative'
                                    >
                                        <div className='flex'>
                                            <img
                                                className='w-[164px] min-w-[164px] h-[70px] object-cover rounded-t-[7px]'
                                                src={URL.createObjectURL(image)}
                                                alt="Reviews Photos | HotelGyms.com"
                                                width='164'
                                                height='70'
                                            />
                                        </div>
                                        <div className='flex justify-end items-center cursor-pointer pt-[10px] pr-[10px] mb-[10px]'>
                                            <div
                                                className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px]'
                                                onClick={() => handleDeleteAdditionalImage(image.name)}
                                            >
                                                <img
                                                    src={blobStorageUrl('app', 'Delete.svg')}
                                                    alt='Delete icon | HotelGyms.com'
                                                    className='w-[14px] h-[16px] cursor-pointer'
                                                    width='14'
                                                    height='16'
                                                />
                                            </div>
                                        </div>
                                        {/* <div className='flex'>
                                            <div className='w-[164px] max-w-[164px] min-w-[164px]'>
                                                <div className='flex flex-col mx-[10px] mb-[10px]'>
                                                    <span className='text-[13px] text-gf_title_red italic'>* Provider</span>
                                                    {providersData &&
                                                        <div className="flex items-center justify-content-center h-[44.5px]">
                                                            <Dropdown
                                                                filter
                                                                options={providersData.filter(provider => provider.isImageSource === true).map(p => ({ value: parseInt(p.id), label: p.name }))}
                                                                value={addGymFactorData.imageProviders.find(x => x.filename === image.name)?.providerId}
                                                                onChange={(e) => handleSaveImageProvider({ e, provider: e.target.value, fileName: image.name })}
                                                                optionLabel="label"
                                                                placeholder="Select..."
                                                                className="rounded-[8px] primeDropdownStyle provider-dropdown-custom"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            }
            {display &&
                <div className='flex mt-[20px]'>
                    <button
                        onClick={() => {
                            handleEditReview(editReview)
                            handleChangeDisplayEdit()
                        }}
                        type="submit"
                        className={`h-[44.5px] w-[79px] text-[15px] font-bold rounded-[8px] border-none cursor-pointer text-white bg-black`}>
                        SAVE
                    </button>
                </div>
            }
            {loadingExperienceImages ?
                <div className='flex h-[150px] items-center justify-center'>
                    <LoadingDots customStyles={'mx-[10px]'} dotsNumber={3} />
                </div>
                :
                <div className='flex mt-[20px] flex-wrap gap-[15px]'>
                    {editReview.images.map((photo, index) => {
                        return (
                            <figure key={index} className='flex border rounded-[8px] gap-[10px] w-[150px] max-w-[150px] min-w-[150px]'>
                                <div className='flex flex-col'>
                                    <div className='flex'>
                                        <AppImage
                                            key={photo.folderName + index}
                                            onClick={() => { handleOpenGallery(); setShowImageGallery(true) }}
                                            name={photo.filename}
                                            folder={photo.folderName || DEFAULT_FOLDER}
                                            ref={imgRef}
                                            alt='Reviews Photos | HotelGyms.com'
                                            width="150"
                                            height="70"
                                            className={`${photo.providerName ? 'mb-[10px]' : 'rounded-b-[7px]'} ${display ? 'rounded-b-[0px]' : ''} w-[150px] h-[70px] object-cover rounded-t-[7px] cursor-pointer
                                                                        ${photo.status === 2 ?
                                                    "edit-image bad-image-border" :
                                                    photo.status === 3 ?
                                                        "edit-image ignored-image-border" :
                                                        "edit-image"}
                                                                    `}
                                            useCloudImage={{
                                                width: 150,
                                                height: 70,
                                            }}
                                            thumbnailPreset
                                        // uploadsOriginal
                                        />

                                        {/* <img onClick={() => handleDeleteGymFactorImage(image.id)} src={blobStorageUrl('app', 'Delete.svg')} alt='delete' title="Delete GF Image" style={{ cursor: 'pointer' }} /> */}
                                    </div>
                                    {photo.providerName &&
                                        <div className='flex px-[10px]'>
                                            <span className='hover:underline text-[12px] text-color_primary_blue'><i className='text-[12px] cursor-pointer colored' onClick={() => handleShowImageProviderModal(photo)}>{photo.providerName}</i></span>
                                        </div>
                                    }
                                    {display &&
                                        <div className='flex items-center justify-between align-center p-[10px]'>
                                            {photo.isProfileImage === true ?
                                                <div
                                                    className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                    onClick={() => handleUpdateMainImage(index, photo.id, true)}
                                                >
                                                    <AppImage
                                                        name={'star-icon.png'}
                                                        folder={`app`}
                                                        alt='star-icon'
                                                        title='Change main image'
                                                        className='cursor-pointer w-[15px]'
                                                    />
                                                </div>
                                                :
                                                <div
                                                    className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                    onClick={() => handleUpdateMainImage(index, photo.id, false)}
                                                >
                                                    <AppImage
                                                        name={'empty-star-icon.png'}
                                                        folder={`app`}
                                                        alt='star-icon'
                                                        title='Main Image'
                                                        className='cursor-pointer w-[15px]'
                                                    />
                                                </div>
                                            }
                                            <div
                                                className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                onClick={() => handleUpdateIsGalleryImage(photo.id, !photo.isGalleryImage)}
                                            >
                                                {
                                                    !photo.isGalleryImage &&
                                                    <AppImage
                                                        name={'dumbell.svg'}
                                                        folder={`app`}
                                                        className='flex w-[15px] cursor-pointer'
                                                        alt='Dumbell-icon'
                                                        title='Not a gallery image'
                                                    />
                                                }
                                                {
                                                    photo.isGalleryImage &&
                                                    <AppImage
                                                        name={'dumbell-filled.svg'}
                                                        folder={`app`}
                                                        className='flex w-[15px] cursor-pointer'
                                                        alt='Dumbell-icon'
                                                        title='Gallery image'
                                                    />
                                                }
                                            </div>
                                            <div
                                                className='flex items-center justify-center w-[30px] h-[30px] border rounded-[6px] cursor-pointer'
                                                onClick={() => handleDeleteImage(photo.id)}
                                            >
                                                <AppImage
                                                    name={'Delete.svg'}
                                                    folder={`app`}
                                                    alt='delete-icon'
                                                    title="Delete GF Image"
                                                    className='cursor-pointer w-[13px]'
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </figure>
                        )
                    })}
                </div>
            }
            {showImageProviderModal &&
                <ImageProviderModal
                    handleClose={handleShowImageProviderModal}
                    image={imageProvider}
                    onActionCompleted={imageActionCompleted}
                    allProviders={allProviders}
                />
            }
            {showImageGallery &&
                <GymImageGalleryModal
                    gymPhotos={galleryImage}
                    handleShowImageGallery={handleShowImageGallery}
                    mainImageSrc={mainImage}
                />
            }
        </div>
    )
}