import moment from "moment";
import { format, parseISO } from 'date-fns';

const shortDateFormat = 'DD-MM-YYYY'
const dateTimeFormat = 'DD-MM-YYYY HH:mm:ss'
const dateTimeFormatWithHours = 'DD-MM-YYYY HH:mm'
const dateTimeTextFormat = "MMM Do YY"
export function formatShortDate(value) {
    if (value) {
        return moment(value).format(shortDateFormat)
    }
    return '';
};
export function formatDateTime(value) {
    if (value) {
        return moment(value).format(dateTimeFormat)
    }
};
export function formatDateTimeWithHours(value) {
    if (value) {
        return moment(value).format(dateTimeFormatWithHours)
    }
    return '';
};
export function formatDateTimeText(value) {
    if (value) {
        return moment(value).format(dateTimeTextFormat)
    }
    return '';
};
export function handleDefineTrustScoreColor(value) {
    if (value <= 39) {
        return "red"
    }
    else if (value > 39 && value < 75) {
        return "yellow"
    }
    else if (value >= 75) { return "green" }
}
export function formatDate(value, dateFormat) {
    if (value) {
        if (typeof value === "string")
            return format(parseISO(value), dateFormat);
        else if (Object.prototype.toString.call(value) === "[object Date]") {
            return format(parseISO(new Date(value).toISOString()), dateFormat);
        }
        else return "";
    }
};

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function getTimeFromDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const timeToReturn = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    return (timeToReturn)
}

export function calculateTrustScore(matchingCode, autoCompleteCheck) {
    autoCompleteCheck = autoCompleteCheck === 1 ? true : false;
    if (matchingCode === 1) {
        if (autoCompleteCheck) {
            return 300
        }
        else {
            return 200
        }
    }
    if (matchingCode === 2) {
        if (autoCompleteCheck) {
            return 150
        }
        else {
            return 100
        }
    }
    if (matchingCode === 3) {
        if (autoCompleteCheck) {
            return 75
        }
        else {
            return 50
        }
    }
    if (matchingCode === 4) {
        return 150
    }
}

export const formatNumberWithComma = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }