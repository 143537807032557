import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { blobStorageUrl } from './HelperComponent';

const AppImage = forwardRef(
  ({ name, folder = 'app', alt = 'Image/Icon | HotelGyms.com', showGalleryModal, gallery = false, useCloudImage = false, mainPreset = false, thumbnailPreset = false, smallPreset = false, mainSharpPreset = false, ...rest }, ref) => {
    const [src, setSrc] = useState(
      useCloudImage
        ? `https://cdn-imgs.hotelgyms.com/${blobStorageUrl(folder, name)}?w=${useCloudImage.width}&h=${useCloudImage.height}${mainPreset
          ? '&p=main'
          : thumbnailPreset
            ? '&p=thumbnail'
            : smallPreset
              ? '&p=small'
              : mainSharpPreset
                ? '&p=main-sharp'
                : ''}`
        : blobStorageUrl(folder, name)
    );
    const [errored, setErrored] = useState(false);

    const onError = () => {
      if (!errored) {
        setSrc(`${useCloudImage ? 'https://cdn-imgs.hotelgyms.com/' : ''}${blobStorageUrl((rest.uploadsOriginal ? 'uploadsOriginal' : "original"), name)}`);
        setErrored(true);
      }
    }

    useEffect(() => {
      if (errored) setSrc(blobStorageUrl(folder, name))
    }, [name, folder, errored])

    return (
      <img
        src={src}
        alt={alt}
        onError={onError}
        onClick={gallery ? () => showGalleryModal(name) : null}
        ref={ref}
        {...rest}
      />
    )
  }
);

AppImage.propTypes = {
  src: PropTypes.string,
  folder: PropTypes.string
};

export default AppImage;
