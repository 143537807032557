import React, { useState } from "react";

const GymFactorScoreBox = ({
    notRatedGymLabel,
    gymFactorScore,
    hasHotelGym,
    gymFactorObject,
    bigSizeMap = false,
    size = 'medium',
}) => {
    const [requested, setRequested] = useState(false)

    const notRatedNotRequested = ((hasHotelGym && gymFactorScore != undefined && parseFloat(gymFactorScore.toFixed()) < 1) || (hasHotelGym && gymFactorScore == null)) && !requested;

    const hasRating = (hasHotelGym && gymFactorScore != undefined && parseFloat(gymFactorScore.toFixed()) > 0) ? true : false;

    const gfProgressColor = gymFactorObject?.gymFactorScore == 100 ?
        'bg-gf_progress_green' :
        gymFactorObject?.gymFactorScore == 0 ?
            'bg-gf_progress_gray' :
            gymFactorObject?.gymFactorScore == 1 ?
                'bg-gf_progress_red' :
                gymFactorObject?.gymFactorScore == 2 ?
                    'bg-gf_progress_gold' :
                    gymFactorObject?.gymFactorScore > 2 ?
                        'bg-gf_progress_blue' : 'bg-gf_progress_blue'

    return (
        <div
            className={`${(notRatedGymLabel || notRatedNotRequested) ? 'cursor-pointer' : undefined} ${size == 'large' ? 'w-[304px] h-[106px] px-[20px] py-[16px] border-2 rounded-[16px] gap-[20px]' : size == 'medium' ? 'w-[182px] h-[64px] px-[12px] py-[10px] border rounded-[10px] gap-[12px]' : 'w-[152px] h-[53px] px-[10px] py-[8px] border rounded-[8px] gap-[10px]'} bg-white border-color_stroke justify-start items-center inline-flex`}
        >
            <div className="relative">
                <img
                    className={`${size == 'large' ? 'min-w-[60px] min-h-[60px]' : size == 'medium' ? 'min-w-[34px] min-h-[34px]' : 'min-w-[28px] min-h-[28px]'}`}
                    src={`${process.env.REACT_APP_STORAGE_BASE_URL}/images/app/${gymFactorObject?.icon?.substring(gymFactorObject?.icon?.lastIndexOf('/') + 1)}`}
                    alt='HotelGyms Logo | HotelGyms.com'
                    width={size == 'large' ? '60' : size == 'medium' ? '34' : '28'}
                    height={size == 'large' ? '60' : size == 'medium' ? '34' : '28'}
                />
            </div>
            <div className={`flex-col justify-center items-start inline-flex ${size == 'large' ? 'gap-[12px]' : size == 'medium' ? 'gap-[7px]' : 'gap-[6px]'}`}>
                <div className="flex-col justify-start items-start flex mt-[-12px] mb-[6px]">
                    <span className={`${size == 'large' ? 'text-[23px] leading-[28px]' : size == 'medium' ? 'text-[14px] leading-[17px]' : 'text-[12px] leading-[14px]'} ${gymFactorObject?.gymFactorScore < 1 ? 'text-gray-600' : 'text-black'} font-bold`}>
                        {(hasRating || !hasHotelGym) ?
                            gymFactorObject?.ratingClassName && gymFactorObject.ratingClassName
                            :
                            (!requested ?
                                <div className="flex flex-col">
                                    <span className={`${size == 'large' ? 'text-[23px] leading-[28px]' : size == 'medium' ? 'text-[14px] leading-[17px]' : 'text-[12px] leading-[14px]'} text-gray-600 font-bold`}>No Information</span>
                                    <span className={`${size == 'large' ? 'text-[21px] leading-[26px]' : size == 'medium' ? 'text-[12px] leading-[15px]' : 'text-[10px] leading-[12px]'} text-color_blue font-semibold underline`}>Request a review</span>
                                </div>
                                :
                                <div className="flex flex-col">
                                    <span className={`${size == 'large' ? 'text-[23px] leading-[28px]' : size == 'medium' ? 'text-[14px] leading-[17px]' : 'text-[12px] leading-[14px]'} text-gray-600 font-bold`}>No Information</span>
                                    <span className={`${size == 'large' ? 'text-[21px] leading-[26px]' : size == 'medium' ? 'text-[12px] leading-[15px]' : 'text-[10px] leading-[12px]'} text-color_blue font-semibold`}>Request Sent!</span>
                                </div>
                            )
                        }
                    </span>
                    {
                        gymFactorObject && gymFactorObject.gymFactorScore != undefined && gymFactorObject.gymFactorScore != null && gymFactorObject.gymFactorScore > 0 &&
                        <span className={`${size == 'large' ? 'text-[20px] leading-[24px]' : size == 'medium' ? 'text-[12px] leading-[15px]' : 'text-[10px] leading-[12px]'} text-black font-normal tracking-[0.5px]`}>{gymFactorObject.gymFactorScore} out of 5</span>
                    }
                </div>
                {
                    gymFactorObject && gymFactorObject.gymFactorScore != undefined && gymFactorObject.gymFactorScore != null && gymFactorObject && gymFactorObject.gymFactorScore > -1 &&
                    <div className="justify-start items-start gap-1.5 inline-flex">
                        {Array.from({ length: 5 }, (_, index) => {
                            return (
                                <div key={index} className={gymFactorObject.gymFactorScore > index ? 'opacity-100' : 'opacity-20'}>
                                    <div className={`${size == 'large' ? 'w-[30px] h-[10px]' : size == 'medium' ? 'w-[18px] h-[6px]' : 'w-[15px] h-[5px]'} rounded-[6px] relative ${gfProgressColor}`} />
                                </div>
                            )
                        }
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

export default GymFactorScoreBox;