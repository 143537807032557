import React, { useEffect, useState } from 'react';
import ToastNotification from './ToastNotification';
import LoadingDots from './LoadingDots';
import { Dropdown } from 'primereact/dropdown';
import Radio from './Radio';
import { EditorPageGymFactorCalculatorWrapper } from './ComponentStyles';
import { TimePicker } from "antd";
import { addGymFactorDetail, getGymFactorDetails, updateGymFactorDetail } from '../redux.js/api';
import { getTimeFromDate } from './formatting';
import InputBlock from './InputBlock';
import Tooltip from './Tooltip';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'

const EditorPageGymFactorCalculator = ({ selectedHotel, allProviders, changeTriggerGymFactorScoreSectionFunctions }) => {
    const initialGymFactorDetailsData = {
        isGymOpen24h: null,
        gymOpeningTime: '07:00:00',
        gymClosingTime: '22:00:00',
        gymSize: null,
        areaSize: null,
        areaMeasurementUnit: null,
        offersFitbodApp: null,
        roomHasWindows: null,
        waterOption: null,
        hasTowels: null,
        hasSanitizers: null,
        primaryEquipmentBrand: null,
        hasStaffInGym: null,
        sellsDayPasses: null,
        pricePerDayPass: null,
        currency: 'USD',
        treadmills: null,
        assaultRunners: null,
        ellipticals: null,
        uprightBikes: null,
        recumbentBikes: null,
        spinningBikes: null,
        rowers: null,
        climbers: null,
        ergometers: null,
        hasPeloton: null,
        hasPelotonBikesInGym: null,
        hasPelotonBikesInHotelRooms: null,
        otherCardioDevices: null,
        flatBenches: null,
        inclineBenches: null,
        hasSmallAndHeavyDumbbells: null,
        dumbbellsMaxWeight: null,
        hasKettlebell: null,
        kettlebellsMaxWeight: null,
        hasBarbell: null,
        barbellMaxWeight: null,
        weightUnit: null,
        barbellType: null,
        rackType: null,
        hasAccessoriesAvailable: null,
        hasSpecialFloorForWeightsTraining: null,
        hasMirrorsForWorkouts: null,
        hasMultipleStationsToWorkout: null,
        otherWeights: null,
        workoutMachineType: null,
        totalAvailableMachines: null,
        hasLowerBody: null,
        hasUpperBodyPush: null,
        hasUpperBodyPull: null,
        hasCrossOverCableMachine: null,
        hasBackExtensionBench: null,
        hasTRX: null,
        hasDistanceBetweenMachines: null,
        hasMultipleMachinesForSameMuscleGroup: null,
        hasDedicatedWarmupArea: null,
        otherWorkoutMachines: null,
    }
    dayjs.extend(customParseFormat)
    const [providers, setProviders] = useState([]);
    const [provider, setProvider] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [gymFactorDetailsData, setGymFactorDetailsData] = useState(initialGymFactorDetailsData)

    useEffect(() => {
        const filtered = allProviders && allProviders.filter(x => x.id === 21 || x.id === 3 || x.id === 25)
        const all = filtered && filtered.map(p => ({ value: p.id, label: p.name }));
        setProviders(all)
    }, []);

    const handleSetProvider = (e) => {
        const value = e.target.value;
        const data = providers.find(x => x.value === value);
        setProvider(data)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setProvider(false);
            const x = await getGymFactorDetails(selectedHotel.hotelId);
            if (x.data.result && x.data.result !== null) setGymFactorDetailsData(x.data.result);
            else {
                setGymFactorDetailsData(initialGymFactorDetailsData)
            }
            setLoading(false);
        }

        fetchData();
    }, [selectedHotel.hotelId])

    const insertGymFactorDetails = async () => {
        setLoadingButton(true);
        let gfData = gymFactorDetailsData;
        let pricePerDayPass = null;
        let dumbbellsMaxWeight = null;
        let kettlebellsMaxWeight = null;
        let barbellMaxWeight = null;
        let areaSize = null;

        if (gymFactorDetailsData.pricePerDayPass) pricePerDayPass = parseFloat(gymFactorDetailsData.pricePerDayPass)
        if (gymFactorDetailsData.dumbbellsMaxWeight) dumbbellsMaxWeight = parseFloat(gymFactorDetailsData.dumbbellsMaxWeight)
        if (gymFactorDetailsData.kettlebellsMaxWeight) kettlebellsMaxWeight = parseFloat(gymFactorDetailsData.kettlebellsMaxWeight)
        if (gymFactorDetailsData.barbellMaxWeight) barbellMaxWeight = parseFloat(gymFactorDetailsData.barbellMaxWeight)
        if (gymFactorDetailsData.areaSize) areaSize = parseFloat(gymFactorDetailsData.areaSize)

        if (gymFactorDetailsData.isGymOpen24h === true || gymFactorDetailsData.isGymOpen24h === null) {
            gfData = { ...gfData, gymOpeningTime: null, gymClosingTime: null }
        }

        let providerId = null;
        if (provider) providerId = provider.value;
        else if (gymFactorDetailsData.providerId) providerId = gymFactorDetailsData.providerId;
        else providerId = 21;

        gfData = { ...gfData, providerId, pricePerDayPass, dumbbellsMaxWeight, kettlebellsMaxWeight, barbellMaxWeight, areaSize }
        setGymFactorDetailsData(gfData)
        if (gymFactorDetailsData.id && gymFactorDetailsData.id !== null) {
            //UPDATE
            try {
                const { data: { result } } = await updateGymFactorDetail(gfData);
                setLoadingButton(false);
                setGymFactorDetailsData(result);
                ToastNotification('success', 'GymFactor Details updated successfully')
            } catch (error) {
                ToastNotification('error', 'Cannot update GymFactor Details. Please try again later.')
                setLoadingButton(false);
            }
            finally {
                changeTriggerGymFactorScoreSectionFunctions();
            }
        }
        else {
            //ADD
            gfData = { ...gfData, hotelId: selectedHotel.hotelId }
            setGymFactorDetailsData(gfData)
            try {
                const { data: { result } } = await addGymFactorDetail(gfData);
                setLoadingButton(false);
                setGymFactorDetailsData(result);
                ToastNotification('success', 'GymFactor Details added successfully')
            } catch (error) {
                ToastNotification('error', 'Cannot add GymFactor Details. Please try again later.')
                setLoadingButton(false);
            }
            finally {
                changeTriggerGymFactorScoreSectionFunctions();
            }
        }
    }

    useEffect(() => {
        let elements = document.getElementsByName("currency");
        for (let i = 0; i < elements.length; i++) {
            elements[i].value = gymFactorDetailsData.currency;
        }
    }, [gymFactorDetailsData.currency])

    useEffect(() => {
        let elements = document.getElementsByName("weightUnit");
        for (let i = 0; i < elements.length; i++) {
            elements[i].value = gymFactorDetailsData.weightUnit;
        }
    }, [gymFactorDetailsData.weightUnit])

    useEffect(() => {
        const value = gymFactorDetailsData.workoutMachineType;
        if (value === 0 || value === "0") {
            setGymFactorDetailsData({
                ...gymFactorDetailsData,
                workoutMachineType: value,
                totalAvailableMachines: 0,
                hasLowerBody: false,
                hasUpperBodyPush: false,
                hasUpperBodyPull: false,
                hasCrossOverCableMachine: false,
                hasBackExtensionBench: false,
                hasMultipleMachinesForSameMuscleGroup: false
            })
        }
    }, [gymFactorDetailsData.workoutMachineType])

    const onInputChange = (e, name, type) => {
        if (name === 'dumbbellsMaxWeight' || name === 'kettlebellsMaxWeight' || name === 'barbellMaxWeight') {
            setGymFactorDetailsData({ ...gymFactorDetailsData, [name]: e.target.value, weightUnit: 1 })
        }
        else if (name === 'rackType' && e.target.value === '3') {
            setGymFactorDetailsData({
                ...gymFactorDetailsData, [name]: e.target.value, barbellType: 1, hasBarbell: true
            })
        }
        else if (name === 'rackType' && (e.target.value === '2' || e.target.value === '1')) {
            setGymFactorDetailsData({
                ...gymFactorDetailsData, [name]: e.target.value, hasBarbell: true
            })
        }
        else if (name == 'totalAvailableMachines' && e.target.value > '0') {
            setGymFactorDetailsData({
                ...gymFactorDetailsData, [name]: e.target.value, workoutMachineType: 1
            })
        }
        else {
            setGymFactorDetailsData({ ...gymFactorDetailsData, [name]: e.target.value })
        }
    }

    useEffect(() => {
        if (gymFactorDetailsData.gymSize !== '4') setGymFactorDetailsData({ ...gymFactorDetailsData, areaSize: null })
    }, [gymFactorDetailsData.gymSize])

    const timeFrom = (time, timeString) => {
        let formatted = "";
        if (time) formatted = getTimeFromDate(time.$d)
        setGymFactorDetailsData({ ...gymFactorDetailsData, gymOpeningTime: formatted })
    }
    const timeTo = (time, timeString) => {
        let formatted = "";
        if (time) formatted = getTimeFromDate(time.$d)
        setGymFactorDetailsData({ ...gymFactorDetailsData, gymClosingTime: formatted })
    }

    return (
        <EditorPageGymFactorCalculatorWrapper>
            {
                loading ?
                    <div className='flex items-center justify-center w-full h-[150px]'>
                        <LoadingDots customStyles={'mx-[10px]'} dotsNumber={3} />
                    </div>
                    :
                    <>
                        <div className='flex w-full'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Providers</span>
                        </div>
                        <div className='flex flex-wrap mt-[20px] gap-[20px]'>
                            <div className='flex flex-col w-full'>
                                <div className='flex w-full'>
                                    <span className='text-[13px] italic text-color_text_gray_light'>
                                        NOTE: A provider is needed to add/update GymFactor Elements. By default "Community" is added.
                                    </span>
                                </div>
                                {providers?.length > 0 &&
                                    <div className="flex mt-[5px] h-[44.5px]">
                                        <Dropdown
                                            options={providers}
                                            value={
                                                provider ?
                                                    provider.value :
                                                    gymFactorDetailsData?.providerId ?
                                                        gymFactorDetailsData.providerId :
                                                        21
                                            }
                                            onChange={(e) => handleSetProvider(e)}
                                            optionLabel="label"
                                            placeholder="Select a Provider"
                                            className="w-full rounded-[8px] primeDropdownStyle"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Gym Experience</span>
                        </div>
                        <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[20px]'>
                            <div className='flex'>
                                <span className='text-[15px]'>Is gym open 24h?</span>
                                <div className='flex ml-auto'>
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, isGymOpen24h: gymFactorDetailsData.isGymOpen24h === true ? null : true })}
                                        name='isGymOpen24h'
                                        label='Yes'
                                        radioSelected={gymFactorDetailsData.isGymOpen24h === true}
                                    />
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, isGymOpen24h: gymFactorDetailsData.isGymOpen24h === false ? null : false, gymOpeningTime: '07:00:00', gymClosingTime: '22:00:00' })}
                                        name='isGymOpen24h'
                                        label='No'
                                        radioSelected={gymFactorDetailsData.isGymOpen24h === false}
                                    />
                                </div>
                            </div>
                            {gymFactorDetailsData.isGymOpen24h === false &&
                                <div className='flex gap-[20px] radio-field'>
                                    <div className='flex flex-col flex-1 time-picker-item'>
                                        <span className='from-to-text'>Time From</span>
                                        <TimePicker
                                            use12Hours
                                            minuteStep={15}
                                            format="h:mm a"
                                            onChange={timeFrom}
                                            defaultValue={dayjs(gymFactorDetailsData.gymOpeningTime, 'HH:mm:ss')}
                                        />
                                    </div>
                                    <div className='flex flex-col flex-1 time-picker-item'>
                                        <span className='from-to-text'>Time To</span>
                                        <TimePicker
                                            use12Hours
                                            minuteStep={15}
                                            format="h:mm a"
                                            onChange={timeTo}
                                            defaultValue={dayjs(gymFactorDetailsData.gymClosingTime, 'HH:mm:ss')}
                                        />
                                    </div>
                                </div>
                            }
                            <div className='flex items-center'>
                                <div className='radio-text'>
                                    <span>What is the overall size of the gym?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="gymSize" name="gymSize" className='dropdown-style dd-large'
                                        onChange={(e) => onInputChange(e, 'gymSize')}
                                    >
                                        <option selected disabled hidden>Choose</option>
                                        <option value={1} selected={gymFactorDetailsData.gymSize === 1 ? true : false}>{'Small (<50m2)'}</option>
                                        <option value={2} selected={gymFactorDetailsData.gymSize === 2 ? true : false}>{'Medium (>50m2)'}</option>
                                        <option value={3} selected={gymFactorDetailsData.gymSize === 3 ? true : false}>{'Large (>300m2)'}</option>
                                        <option value={4} selected={gymFactorDetailsData.gymSize === 4 ? true : false}>{'Specific'}</option>
                                    </select>
                                </div>
                            </div>
                            {gymFactorDetailsData.gymSize === 4 &&
                                <div className='radio-field flex items-center'>
                                    <div className='radio-text'>
                                        <span>What is the specific size of the gym?</span>
                                    </div>
                                    <div className='radio-input ml-auto max-w-[200px]'>
                                        <InputBlock
                                            inputColumnStyles='price-input-style'
                                            onInputChange={onInputChange}
                                            name='areaSize'
                                            inputValue={gymFactorDetailsData}
                                            frontIcon='onboarding-area-icon.svg'
                                            type='number'
                                        />
                                        <select
                                            id="areaMeasurementUnit"
                                            name="areaMeasurementUnit"
                                            className='dropdown-style dd-small left-no-radius'
                                            onChange={(e) => onInputChange(e, 'areaMeasurementUnit')}
                                        >
                                            <option selected disabled hidden></option>
                                            <option value={1} selected={gymFactorDetailsData.areaMeasurementUnit === 1 ? true : false}>m2</option>
                                            <option value={2} selected={gymFactorDetailsData.areaMeasurementUnit === 2 ? true : false}>ft2</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Does the room have windows / daylight?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="roomHasWindows" name="roomHasWindows" className='dropdown-style dd-large'
                                        onChange={(e) => onInputChange(e, 'roomHasWindows')}
                                    >
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.roomHasWindows === 0 ? true : false}>None</option>
                                        <option value={1} selected={gymFactorDetailsData.roomHasWindows === 1 ? true : false}>Indoor/Standard Windows</option>
                                        <option value={2} selected={gymFactorDetailsData.roomHasWindows === 2 ? true : false}>Gym with a view</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>What kind of water is provided, if any?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="waterOption" name="waterOption" className='dropdown-style dd-medium'
                                        onChange={(e) => onInputChange(e, 'waterOption')}
                                    >
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.waterOption === 0 ? true : false}>None</option>
                                        <option value={1} selected={gymFactorDetailsData.waterOption === 1 ? true : false}>Water Stations</option>
                                        <option value={2} selected={gymFactorDetailsData.waterOption === 2 ? true : false}>Bottled Water</option>
                                        <option value={3} selected={gymFactorDetailsData.waterOption === 3 ? true : false}>Premium Water</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Are towels provided in the gym?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasTowels: gymFactorDetailsData.hasTowels === true ? null : true })}
                                        name='hasTowels' label='Yes'
                                        radioSelected={gymFactorDetailsData.hasTowels === true}
                                    />
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasTowels: gymFactorDetailsData.hasTowels === false ? null : false })}
                                        name='hasTowels' label='No'
                                        radioSelected={gymFactorDetailsData.hasTowels === false}
                                    />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Are there sanitizers provided in the gym?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="hasSanitizers" name="hasSanitizers" className='dropdown-style dd-medium'
                                        onChange={(e) => onInputChange(e, 'hasSanitizers')}
                                    >
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.hasSanitizers === 0 ? true : false}>No</option>
                                        <option value={1} selected={gymFactorDetailsData.hasSanitizers === 1 ? true : false}>Yes</option>
                                        <option value={2} selected={gymFactorDetailsData.hasSanitizers === 2 ? true : false}>Multiple Places</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Primary equipment brand?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="primaryEquipmentBrand" name="primaryEquipmentBrand" className='dropdown-style dd-medium' onChange={(e) => onInputChange(e, 'primaryEquipmentBrand')}
                                    >
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.primaryEquipmentBrand === 0 ? true : false}>None</option>
                                        <option value={1} selected={gymFactorDetailsData.primaryEquipmentBrand === 1 ? true : false}>Life Fitness</option>
                                        <option value={2} selected={gymFactorDetailsData.primaryEquipmentBrand === 2 ? true : false}>Matrix</option>
                                        <option value={3} selected={gymFactorDetailsData.primaryEquipmentBrand === 3 ? true : false}>TechnoGym</option>
                                        <option value={4} selected={gymFactorDetailsData.primaryEquipmentBrand === 4 ? true : false}>Toorx</option>
                                        <option value={5} selected={gymFactorDetailsData.primaryEquipmentBrand === 5 ? true : false}>Cybex</option>
                                        <option value={6} selected={gymFactorDetailsData.primaryEquipmentBrand === 6 ? true : false}>Precor</option>
                                        <option value={7} selected={gymFactorDetailsData.primaryEquipmentBrand === 7 ? true : false}>Impulse</option>
                                        <option value={8} selected={gymFactorDetailsData.primaryEquipmentBrand === 8 ? true : false}>Star Trac</option>
                                        <option value={9} selected={gymFactorDetailsData.primaryEquipmentBrand === 9 ? true : false}>Hammer Strength</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Is there reception or staff in the gym?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasStaffInGym: gymFactorDetailsData.hasStaffInGym === true ? null : true })}
                                        name='hasStaffInGym' label='Yes'
                                        radioSelected={gymFactorDetailsData.hasStaffInGym === true}
                                    />
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasStaffInGym: gymFactorDetailsData.hasStaffInGym === false ? null : false })}
                                        name='hasStaffInGym' label='No'
                                        radioSelected={gymFactorDetailsData.hasStaffInGym === false}
                                    />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Do you sell day passes for your gym?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, sellsDayPasses: gymFactorDetailsData.sellsDayPasses === true ? null : true })}
                                        name='sellsDayPasses' label='Yes'
                                        radioSelected={gymFactorDetailsData.sellsDayPasses === true}
                                    />
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, sellsDayPasses: gymFactorDetailsData.sellsDayPasses === false ? null : false })}
                                        name='sellsDayPasses' label='No'
                                        radioSelected={gymFactorDetailsData.sellsDayPasses === false}
                                    />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Price per day pass</span>
                                </div>
                                <div className='max-w-[200px] flex ml-auto'>
                                    <InputBlock
                                        inputColumnStyles='price-input-style'
                                        onInputChange={onInputChange}
                                        name='pricePerDayPass'
                                        inputValue={gymFactorDetailsData}
                                        frontIcon='onboarding-form-money-icon.svg'
                                        type='number'
                                    />
                                    <select
                                        id="currency"
                                        name="currency"
                                        className='dropdown-style dd-small left-no-radius'
                                        onChange={(e) => onInputChange(e, 'currency')}
                                    >
                                        <option value="USD" selected={gymFactorDetailsData.currency === "USD" ? true : false}>USD</option>
                                        <option value="EUR" selected={gymFactorDetailsData.currency === "EUR" ? true : false}>EUR</option>
                                        <option value="CHF" selected={gymFactorDetailsData.currency === "CHF" ? true : false}>CHF</option>
                                        <option value="GBP" selected={gymFactorDetailsData.currency === "GBP" ? true : false}>GBP</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Offers Fitbod App?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, offersFitbodApp: gymFactorDetailsData.offersFitbodApp === true ? null : true })}
                                        name='offersFitbodApp' label='Yes'
                                        radioSelected={gymFactorDetailsData.offersFitbodApp === true}
                                    />
                                    <Radio
                                        onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, offersFitbodApp: gymFactorDetailsData.offersFitbodApp === false ? null : false })}
                                        name='offersFitbodApp' label='No'
                                        radioSelected={gymFactorDetailsData.offersFitbodApp === false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Cardio</span>
                        </div>
                        <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[20px]'>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Count of Treadmills</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='treadmills' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Count of Assault Runners</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='assaultRunners' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Count of Ellipticals</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='ellipticals' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <Tooltip content={'Upright exercise bikes are like a standard road bike, where the pedals are below.'} direction='top'>
                                        <span>Count of Upright Bikes</span>
                                    </Tooltip>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='uprightBikes' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <Tooltip content={'Recumbent exercise bikes feature a more natural seated position with the pedals in front.'} direction='top'>
                                        <span>Count of Recumbent Bikes</span>
                                    </Tooltip>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='recumbentBikes' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <Tooltip content={'Designed to more closely mimic the riding position of an outdoor bike.'} direction='top'>
                                        <span>Count of Spinning Bikes</span>
                                    </Tooltip>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='spinningBikes' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Count of Rowers</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='rowers' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Count of Climbers</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='climbers' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Count of Ergometers</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='ergometers' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Has Peloton?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasPeloton: gymFactorDetailsData.hasPeloton === true ? null : true })} name='hasPeloton' label='Yes' radioSelected={gymFactorDetailsData.hasPeloton === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasPeloton: gymFactorDetailsData.hasPeloton === false ? null : false, hasPelotonBikesInGym: null, hasPelotonBikesInHotelRooms: null })} name='hasPeloton' label='No' radioSelected={gymFactorDetailsData.hasPeloton === false} />
                                </div>
                            </div>
                            {gymFactorDetailsData.hasPeloton !== false &&
                                <>
                                    <div className='radio-field flex items-center'>
                                        <div className='radio-text'>
                                            <span>Are there Peloton bikes in the gym?</span>
                                        </div>
                                        <div className='radio-input ml-auto'>
                                            <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasPelotonBikesInGym: gymFactorDetailsData.hasPelotonBikesInGym === true ? null : true })} name='hasPelotonBikesInGym' label='Yes' radioSelected={gymFactorDetailsData.hasPelotonBikesInGym === true} />
                                            <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasPelotonBikesInGym: gymFactorDetailsData.hasPelotonBikesInGym === false ? null : false })} name='hasPelotonBikesInGym' label='No' radioSelected={gymFactorDetailsData.hasPelotonBikesInGym === false} />
                                        </div>
                                    </div>
                                    <div className='radio-field flex items-center'>
                                        <div className='radio-text'>
                                            <span>Are there Peloton bikes in the hotel rooms?</span>
                                        </div>
                                        <div className='radio-input ml-auto'>
                                            <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasPelotonBikesInHotelRooms: gymFactorDetailsData.hasPelotonBikesInHotelRooms === true ? null : true })} name='hasPelotonBikesInHotelRooms' label='Yes' radioSelected={gymFactorDetailsData.hasPelotonBikesInHotelRooms === true} />
                                            <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasPelotonBikesInHotelRooms: gymFactorDetailsData.hasPelotonBikesInHotelRooms === false ? null : false })} name='hasPelotonBikesInHotelRooms' label='No' radioSelected={gymFactorDetailsData.hasPelotonBikesInHotelRooms === false} />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className='radio-field w-full'>
                                <div className='radio-text mb-[15px]'>
                                    <span>Notes</span>
                                </div>
                                <div className='flex w-full'>
                                    <InputBlock inputColumnStyles='textarea-input-style' onInputChange={onInputChange} name='otherCardioDevices' inputValue={gymFactorDetailsData} type='textarea' rows='2' />
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Weights</span>
                        </div>
                        <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[20px]'>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Number of flat benches?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='flatBenches' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Number of incline benches?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='inclineBenches' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Small and heavy weights dumbbells available?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasSmallAndHeavyDumbbells: gymFactorDetailsData.hasSmallAndHeavyDumbbells === true ? null : true })} name='hasSmallAndHeavyDumbbells' label='Yes' radioSelected={gymFactorDetailsData.hasSmallAndHeavyDumbbells === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasSmallAndHeavyDumbbells: gymFactorDetailsData.hasSmallAndHeavyDumbbells === false ? null : false })} name='hasSmallAndHeavyDumbbells' label='No' radioSelected={gymFactorDetailsData.hasSmallAndHeavyDumbbells === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Max weight - Dumbbells</span>
                                </div>
                                <div className='radio-input ml-auto max-w-[200px]'>
                                    <InputBlock inputColumnStyles='weight-input-style' onInputChange={onInputChange} name='dumbbellsMaxWeight' inputValue={gymFactorDetailsData} frontIcon='onboarding-form-max-weight-icon.svg' type='number' />
                                    <select id="weightUnit" name="weightUnit" className='dropdown-style dd-small left-no-radius' onChange={(e) => onInputChange(e, 'weightUnit')}>
                                        <option selected disabled hidden></option>
                                        <option value={1} selected={gymFactorDetailsData.weightUnit === 1 ? true : false}>Kg</option>
                                        <option value={2} selected={gymFactorDetailsData.weightUnit === 2 ? true : false}>Lbs</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Has Kettlebell?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasKettlebell: gymFactorDetailsData.hasKettlebell === true ? null : true })} name='hasKettlebell' label='Yes' radioSelected={gymFactorDetailsData.hasKettlebell === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasKettlebell: gymFactorDetailsData.hasKettlebell === false ? null : false, kettlebellsMaxWeight: null })} name='hasKettlebell' label='No' radioSelected={gymFactorDetailsData.hasKettlebell === false} />
                                </div>
                            </div>
                            {gymFactorDetailsData.hasKettlebell !== false &&
                                <div className='radio-field flex items-center'>
                                    <div className='radio-text'>
                                        <span>Max weight - Kettlebells</span>
                                    </div>
                                    <div className='radio-input max-w-[200px] ml-auto'>
                                        <InputBlock inputColumnStyles='weight-input-style' onInputChange={onInputChange} name='kettlebellsMaxWeight' inputValue={gymFactorDetailsData} frontIcon='onboarding-form-max-weight-icon.svg' type='number' />
                                        <select id="weightUnit" name="weightUnit" className='dropdown-style dd-small left-no-radius' onChange={(e) => onInputChange(e, 'weightUnit')}>
                                            <option selected disabled hidden></option>
                                            <option value={1} selected={gymFactorDetailsData.weightUnit === 1 ? true : false}>Kg</option>
                                            <option value={2} selected={gymFactorDetailsData.weightUnit === 2 ? true : false}>Lbs</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Has Barbell?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasBarbell: gymFactorDetailsData.hasBarbell === true ? null : true })} name='hasBarbell' label='Yes' radioSelected={gymFactorDetailsData.hasBarbell === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasBarbell: gymFactorDetailsData.hasBarbell === false ? null : false, barbellMaxWeight: null, barbellType: 0, rackType: 0 })} name='hasBarbell' label='No' radioSelected={gymFactorDetailsData.hasBarbell === false} />
                                </div>
                            </div>
                            {gymFactorDetailsData.hasBarbell !== false &&
                                <div className='radio-field flex items-center'>
                                    <div className='radio-text'>
                                        <span>Max weight - Barbell</span>
                                    </div>
                                    <div className='radio-input max-w-[200px] ml-auto'>
                                        <InputBlock inputColumnStyles='weight-input-style' onInputChange={onInputChange} name='barbellMaxWeight' inputValue={gymFactorDetailsData} frontIcon='onboarding-form-max-weight-icon.svg' type='number' />
                                        <select id="weightUnit" name="weightUnit" className='dropdown-style dd-small left-no-radius' onChange={(e) => onInputChange(e, 'weightUnit')}>
                                            <option selected disabled hidden></option>
                                            <option value={1} selected={gymFactorDetailsData.weightUnit === 1 ? true : false}>Kg</option>
                                            <option value={2} selected={gymFactorDetailsData.weightUnit === 2 ? true : false}>Lbs</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Type of Rack?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="rackType" name="rackType" className='dropdown-style dd-medium' onChange={(e) => onInputChange(e, 'rackType')}>
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.rackType === 0 ? true : false}>None</option>
                                        <option value={1} selected={gymFactorDetailsData.rackType === 1 ? true : false}>Squad Stand</option>
                                        <option value={3} selected={gymFactorDetailsData.rackType === 3 ? true : false}>Smith Machine</option>
                                        <option value={2} selected={gymFactorDetailsData.rackType === 2 ? true : false}>Power Rack</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Type of Barbell?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="barbellType" name="barbellType" className='dropdown-style dd-medium' onChange={(e) => onInputChange(e, 'barbellType')}>
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.barbellType === 0 ? true : false}>None</option>
                                        <option value={1} selected={gymFactorDetailsData.barbellType === 1 ? true : false}>Standard bar</option>
                                        <option value={2} selected={gymFactorDetailsData.barbellType === 2 ? true : false}>Olympic bar</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Accessories available (extra weight, bands, etc.)</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasAccessoriesAvailable: gymFactorDetailsData.hasAccessoriesAvailable === true ? null : true })} name='hasAccessoriesAvailable' label='Yes' radioSelected={gymFactorDetailsData.hasAccessoriesAvailable === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasAccessoriesAvailable: gymFactorDetailsData.hasAccessoriesAvailable === false ? null : false })} name='hasAccessoriesAvailable' label='No' radioSelected={gymFactorDetailsData.hasAccessoriesAvailable === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Does the gym have a special floor for weights training?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasSpecialFloorForWeightsTraining: gymFactorDetailsData.hasSpecialFloorForWeightsTraining === true ? null : true })} name='hasSpecialFloorForWeightsTraining' label='Yes' radioSelected={gymFactorDetailsData.hasSpecialFloorForWeightsTraining === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasSpecialFloorForWeightsTraining: gymFactorDetailsData.hasSpecialFloorForWeightsTraining === false ? null : false })} name='hasSpecialFloorForWeightsTraining' label='No' radioSelected={gymFactorDetailsData.hasSpecialFloorForWeightsTraining === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Are there mirrors for workouts?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasMirrorsForWorkouts: gymFactorDetailsData.hasMirrorsForWorkouts === true ? null : true })} name='hasMirrorsForWorkouts' label='Yes' radioSelected={gymFactorDetailsData.hasMirrorsForWorkouts === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasMirrorsForWorkouts: gymFactorDetailsData.hasMirrorsForWorkouts === false ? null : false })} name='hasMirrorsForWorkouts' label='No' radioSelected={gymFactorDetailsData.hasMirrorsForWorkouts === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Are there multiple stations to work out?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasMultipleStationsToWorkout: gymFactorDetailsData.hasMultipleStationsToWorkout === true ? null : true })} name='hasMultipleStationsToWorkout' label='Yes' radioSelected={gymFactorDetailsData.hasMultipleStationsToWorkout === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasMultipleStationsToWorkout: gymFactorDetailsData.hasMultipleStationsToWorkout === false ? null : false })} name='hasMultipleStationsToWorkout' label='No' radioSelected={gymFactorDetailsData.hasMultipleStationsToWorkout === false} />
                                </div>
                            </div>
                            <div className='radio-field w-full'>
                                <div className='radio-text mb-[15px]'>
                                    <span>Notes</span>
                                </div>
                                <div className='flex w-full'>
                                    <InputBlock inputColumnStyles='textarea-input-style' onInputChange={onInputChange} name='otherWeights' inputValue={gymFactorDetailsData} type='textarea' rows='2' />
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full mt-[30px]'>
                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Workout Machines</span>
                        </div>
                        <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[20px]'>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>What type of workout machines are provided?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <select id="workoutMachineType" name="workoutMachineType" className='dropdown-style dd-medium' onChange={(e) => onInputChange(e, 'workoutMachineType')}
                                    >
                                        <option selected disabled hidden>Choose</option>
                                        <option value={0} selected={gymFactorDetailsData.workoutMachineType === 0 ? true : false}>None</option>
                                        <option value={1} selected={gymFactorDetailsData.workoutMachineType === 1 ? true : false}>Multi-Functional</option>
                                        <option value={2} selected={gymFactorDetailsData.workoutMachineType === 2 ? true : false}>Dedicated</option>
                                    </select>
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>How many machines are available in total?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <InputBlock inputColumnStyles='number-input-style' onInputChange={onInputChange} name='totalAvailableMachines' inputValue={gymFactorDetailsData} type='number' />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Has Lower Body?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasLowerBody: gymFactorDetailsData.hasLowerBody === true ? null : true })} name='hasLowerBody' label='Yes' radioSelected={gymFactorDetailsData.hasLowerBody === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasLowerBody: gymFactorDetailsData.hasLowerBody === false ? null : false })} name='hasLowerBody' label='No' radioSelected={gymFactorDetailsData.hasLowerBody === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Has Upper Body - Push?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasUpperBodyPush: gymFactorDetailsData.hasUpperBodyPush === true ? null : true })} name='hasUpperBodyPush' label='Yes' radioSelected={gymFactorDetailsData.hasUpperBodyPush === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasUpperBodyPush: gymFactorDetailsData.hasUpperBodyPush === false ? null : false })} name='hasUpperBodyPush' label='No' radioSelected={gymFactorDetailsData.hasUpperBodyPush === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Has Upper Body - Pull?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasUpperBodyPull: gymFactorDetailsData.hasUpperBodyPull === true ? null : true })} name='hasUpperBodyPull' label='Yes' radioSelected={gymFactorDetailsData.hasUpperBodyPull === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasUpperBodyPull: gymFactorDetailsData.hasUpperBodyPull === false ? null : false })} name='hasUpperBodyPull' label='No' radioSelected={gymFactorDetailsData.hasUpperBodyPull === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Cross-over cable machine available</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasCrossOverCableMachine: gymFactorDetailsData.hasCrossOverCableMachine === true ? null : true })} name='hasCrossOverCableMachine' label='Yes' radioSelected={gymFactorDetailsData.hasCrossOverCableMachine === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasCrossOverCableMachine: gymFactorDetailsData.hasCrossOverCableMachine === false ? null : false })} name='hasCrossOverCableMachine' label='No' radioSelected={gymFactorDetailsData.hasCrossOverCableMachine === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Back extension bench available</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasBackExtensionBench: gymFactorDetailsData.hasBackExtensionBench === true ? null : true })} name='hasBackExtensionBench' label='Yes' radioSelected={gymFactorDetailsData.hasBackExtensionBench === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasBackExtensionBench: gymFactorDetailsData.hasBackExtensionBench === false ? null : false })} name='hasBackExtensionBench' label='No' radioSelected={gymFactorDetailsData.hasBackExtensionBench === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>TRX available?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasTRX: gymFactorDetailsData.hasTRX === true ? null : true })} name='hasTRX' label='Yes' radioSelected={gymFactorDetailsData.hasTRX === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasTRX: gymFactorDetailsData.hasTRX === false ? null : false })} name='hasTRX' label='No' radioSelected={gymFactorDetailsData.hasTRX === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Distance between machines (1.5m or more)?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasDistanceBetweenMachines: gymFactorDetailsData.hasDistanceBetweenMachines === true ? null : true })} name='hasDistanceBetweenMachines' label='Yes' radioSelected={gymFactorDetailsData.hasDistanceBetweenMachines === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasDistanceBetweenMachines: gymFactorDetailsData.hasDistanceBetweenMachines === false ? null : false })} name='hasDistanceBetweenMachines' label='No' radioSelected={gymFactorDetailsData.hasDistanceBetweenMachines === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Are there multiple machines for the same muscle group?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasMultipleMachinesForSameMuscleGroup: gymFactorDetailsData.hasMultipleMachinesForSameMuscleGroup === true ? null : true })} name='hasMultipleMachinesForSameMuscleGroup' label='Yes' radioSelected={gymFactorDetailsData.hasMultipleMachinesForSameMuscleGroup === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasMultipleMachinesForSameMuscleGroup: gymFactorDetailsData.hasMultipleMachinesForSameMuscleGroup === false ? null : false })} name='hasMultipleMachinesForSameMuscleGroup' label='No' radioSelected={gymFactorDetailsData.hasMultipleMachinesForSameMuscleGroup === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Is there a dedicated stretching/warm-up area available?</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasDedicatedWarmupArea: gymFactorDetailsData.hasDedicatedWarmupArea === true ? null : true })} name='hasDedicatedWarmupArea' label='Yes' radioSelected={gymFactorDetailsData.hasDedicatedWarmupArea === true} />
                                    <Radio onRadioChange={() => setGymFactorDetailsData({ ...gymFactorDetailsData, hasDedicatedWarmupArea: gymFactorDetailsData.hasDedicatedWarmupArea === false ? null : false })} name='hasDedicatedWarmupArea' label='No' radioSelected={gymFactorDetailsData.hasDedicatedWarmupArea === false} />
                                </div>
                            </div>
                            <div className='radio-field w-full'>
                                <div className='radio-text mb-[15px]'>
                                    <span>Notes</span>
                                </div>
                                <div className='flex w-full'>
                                    <InputBlock inputColumnStyles='textarea-input-style' onInputChange={onInputChange} name='otherWorkoutMachines' inputValue={gymFactorDetailsData} type='textarea' rows='2' />
                                </div>
                            </div>
                        </div>
                        <div className='flex border-t mt-[30px] pt-[25px] pb-[9px] mx-[-16px] calculator-footer-sticky bg-white'>
                            <button
                                onClick={() => insertGymFactorDetails()}
                                type="submit"
                                disabled={loadingButton}
                                className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black ml-[16px]`}>
                                SAVE ELEMENTS {loadingButton &&
                                    <LoadingDots customStyles={'bg-white'} dotsNumber={1} />
                                }
                            </button>
                        </div>
                    </>
            }
        </EditorPageGymFactorCalculatorWrapper>
    );
};

export default EditorPageGymFactorCalculator;