import styled from 'styled-components';

export const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  :last-child{
    margin-left: 20px;
    margin-right: 5px;
  }

  label{
    display: block;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
    font-size: 15px;
    user-select: none;
  }

  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 25px;
    height: 25px;
    right: ${props => props.originalRightValue ? props.originalRightValue : '24px'};
    z-index: 10;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    right: unset;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
  }
  
  label .checkmark {
    border: 1px solid #adb5bd;
  }

  label input:checked ~ .checkmark {
    border: 1px solid #0098C9;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  label input:checked ~ .checkmark:after {
    display: block;
  }

  label .checkmark:after {
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #0098C9;
  }

  .label-style{
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    color: #333;
  }
`;