const LoadingDots = ({ customStyles, dotsNumber = 3 }) => {
    const dots = []
    for (let i = 0; i < dotsNumber; i++) {
        dots.push(
            <span key={i} className={`dots-wrapper spinner-grow spinner-grow-sm ${customStyles}`} role="status" aria-hidden="true" />
        );
    }
    return dots;
}

export default LoadingDots;