import React, { useState } from 'react'
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import lodash from "lodash";
import { getAutoSuggest, getAutoSuggestPlaceIdMatchedData, searchPageGetHotels } from '../redux.js/api';
import LoadingDots from './LoadingDots';

function SearchHotels({ onSearchCompleted, forMatchedHotels = false }) {
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('')
    const [searchQuery, setSearchQuery] = useState({
        address: {
            name: "",
            cityName: "",
            countryName: "",
            bookingHotelId: "",
            type: "",
        },
        rooms: 1,
        adults: 2,
        children: 0,
        from: "",
        to: "",
    })
    const [searching, setSearching] = useState(false);

    const getSuggestionValue = async (suggestion) => {
        const data = {
            PLACE_ID: suggestion.placeId,
            Type: suggestion.type,
        }
        const { data: { result } } = await getAutoSuggestPlaceIdMatchedData(data)
        setSearchQuery({
            ...searchQuery,
            address: result,
            value: result.label
        })
        setValue(result.label);
        if (forMatchedHotels) {
            onSearchCompleted(result.bookingHotelId)
        }
    };

    const handleHotelSearchChange = (event) => {
        if (event.target.value) {
            setValue(event.target.value)
        }
        if (event.target.value === "") {
            setValue(event.target.value)
            setSearchQuery({ ...searchQuery, address: {}, value: event.target.value })
        }
    };

    const getBookingHotels = async () => {
        let query = searchQuery;
        setSearching(true);
        const data = {
            value: query.address.bookingHotelId,
            type: 1,
            countryCode: query.address.country,
            hotelFacilities: '',
            stars: 0,
            reviewScore: '',
            propertyTypes: '',
            orderBy: 1,
            orderDirection: 2,
            gymFactorScore: 0,
            cursor: null,
            size: 10
        }
        try {
            const { data: { result } } = await searchPageGetHotels(data)
            setSearching(false)
            onSearchCompleted({ completedHotels: result.items })
            setSearchQuery({ ...searchQuery, query })
        } catch (error) {
            console.log(error)
        }
    }

    const getSuggestions = async (filter) => {
        // setLoading(true)
        const {
            data: { result }
        } = await getAutoSuggest({ filter });
        // setLoading(false)
        //Return only hotel suggestions
        return result.filter(x => x.type === 'hotel')
        // if (forMatchedHotels) {
        //     result = result.filter(x => x.type === 'hotel')
        // }
        // return result
    };

    const onSuggestionsFetchRequested = lodash.debounce(async () => {
        if (value.length < 3) return;

        const suggestions = await getSuggestions(value);
        setSuggestions(suggestions)
    }, 100);

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const renderSuggestion = (suggestion, { query }) => {
        const suggestionText = suggestion.label;
        // suggestion.type === "hotel"
        //     ? `${suggestion.name}, ${suggestion.cityName}, ${suggestion.countryName}`
        //     : `${suggestion.label}`;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);
        let suggestionType = suggestion.type;
        return (
            <span className={"suggestion-content " + suggestionType}>
                <span className="name">
                    {parts.map((part, index) =>
                        <span className={part.highlight ? "highlight" : null} key={index}>
                            {part.text}
                        </span>
                    )}
                </span>
            </span>
        );
    }

    const inputProps = {
        placeholder: !forMatchedHotels ? "Hotel, destination, areas in city, or state" : "Search for Hotels",
        value,
        onChange: handleHotelSearchChange,
        className: "form-control text-dark"
    };

    return (
        <div className='flex w-full flex-col md:flex-row gap-[20px]'>
            <div className="flex w-full flex-col gap-[10px]">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
            {!forMatchedHotels &&
                <div className='flex w-full md:w-[170px]'>
                    <button
                        onClick={() => getBookingHotels()}
                        type="submit"
                        disabled={!value}
                        className={`w-full md:w-[170px] h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white ${!value ? 'bg-black opacity-50' : 'bg-black'}`}>
                        {!searching ? 'SEARCH HOTELS' : <LoadingDots customStyles={'bg-white mx-[10px]'} dotsNumber={3} />}
                    </button>
                </div>
            }
        </div>
    )
}
export default SearchHotels;