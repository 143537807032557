import React from 'react';
import { CheckboxWrapper, StyledCheckbox, HiddenCheckbox, CheckboxContainer, CheckMarkWhite } from './ComponentStyles';

const Checkbox = ({ 
  onInputChange, 
  name, 
  id, 
  checkboxTextBold, 
  checkboxText, 
  checkboxClickableText, 
  checkboxChecked,
  disabled,
  className
}) => {
  return (
    <CheckboxWrapper className={className}>
      <label 
        onChange={(e) => !disabled && onInputChange(e, name, 'checkbox')} 
        className={`flex items-center ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
      >
        <CheckboxContainer>
          <HiddenCheckbox 
            checked={checkboxChecked} 
            id={id} 
            onChange={() => {}} 
            disabled={disabled}
          />
          <StyledCheckbox checked={checkboxChecked} disabled={disabled}>
            <CheckMarkWhite viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </CheckMarkWhite>
          </StyledCheckbox>
        </CheckboxContainer>
        {checkboxText && (
          <span htmlFor={id} className="label-style">
            <span className='font-bold'>{checkboxTextBold}</span>
            {checkboxText}
          </span>
        )}
      </label>
    </CheckboxWrapper>
  )
}

export default Checkbox;