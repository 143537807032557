import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, StyledInput, StyledTextArea } from './styles';
import AppImage from '../AppImage';

const Input = ({ onInputChange, name, inputValue, type = 'text', placeholder, icon, frontIcon, rows = '7', id, value, disabled = false }) => {

  useEffect(() => {
    // Prevent scrolling to change input value when the input is a number
    const handleWheel = (e) => {
      if (e.target.type === 'number') {
        e.preventDefault(); // Stop the scroll behavior from changing the value
      }
    };

    // Add event listener to prevent scrolling for all number inputs
    document.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <InputWrapper>
      {type !== 'textarea' ?
        <StyledInput
          onChange={(e) => onInputChange(e, name, type)}
          name={name}
          value={inputValue ? inputValue[name] : value}
          type={type}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          min="0"
        /> :
        <StyledTextArea
          onChange={(e) => onInputChange(e, name, type)}
          name={name}
          value={inputValue[name]}
          type={type}
          placeholder={placeholder}
          rows={rows}
        />
      }
      {icon && <AppImage name={icon} folder={`app`} className='icon-style' alt='Icon' />}
      {frontIcon && <AppImage name={frontIcon} folder={`app`} className='front-icon-style' alt='Icon' />}
    </InputWrapper>
  )
}

Input.propTypes = {
  inputValue: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string
};

Input.defaultProps = {
  alt: 'Icon',
  type: 'text',
}

export default Input;