import React, { useEffect, useState } from 'react';
import ToastNotification from './ToastNotification';
import { addProviderHotels, deleteProviderHotel, getAllProviderHotels, updateProviderHotels } from '../redux.js/api';
import LoadingDots from './LoadingDots';

const EditorPageProviders = ({ selectedHotel }) => {
    const hotelPlannerInitialData = {
        providerKey: null,
        id: null
    }
    const kayakInitialData = {
        providerKey: null,
        id: null
    }
    const googlePlacesInitialData = {
        providerKey: null,
        id: null
    }

    const [hotelPlannerData, setHotelPlannerData] = useState(hotelPlannerInitialData);
    const [kayakData, setKayakData] = useState(kayakInitialData);
    const [googlePlacesData, setGooglePlacesData] = useState(googlePlacesInitialData);
    const [allProviderHotels, setAllProviderHotels] = useState(null)

    const [lockedFields, setLockedFields] = useState({
        hotelPlannerId: true,
        kayakId: true,
        googlePlacesId: true
    })

    const [loading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);

    const onHotelPlannerChange = (e, name, type) => {
        setHotelPlannerData({ ...hotelPlannerData, [name]: e.target.value })
    }

    const onKayakChange = (e, name, type) => {
        setKayakData({ ...kayakData, [name]: e.target.value })
    }

    const onGooglePlacesChange = (e, name, type) => {
        setGooglePlacesData({ ...googlePlacesData, [name]: e.target.value })
    }

    const handleChangeFieldStatus = (name, value) => {
        setLockedFields({ ...lockedFields, [name]: !value })
    }

    useEffect(() => {
        const fetchProviders = async () => {
            try {
                setSectionLoading(true)
                const { data: { result } } = await getAllProviderHotels(selectedHotel.hotelId)
                setAllProviderHotels(result)
                setSectionLoading(false)
            } catch (error) {
                ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
                setSectionLoading(false)
            }
        }

        if (selectedHotel) fetchProviders()
    }, [])

    const updateAllProviderHotels = (obj) => {
        setAllProviderHotels([...allProviderHotels, obj])
    }

    const fetchData = async () => {
        setSectionLoading(true);
        if (selectedHotel.hotelId) {
            if (allProviderHotels.find(p => p.providerId === 49)) {
                const data = allProviderHotels.find(p => p.providerId === 49)
                setHotelPlannerData({ providerKey: data.providerKey, id: data.id })
            }
            if (allProviderHotels.find(p => p.providerId === 43)) {
                const data = allProviderHotels.find(p => p.providerId === 43)
                setKayakData({ providerKey: data.providerKey, id: data.id })
            }
            if (allProviderHotels.find(x => x.providerId === 4)) {
                const data = allProviderHotels.find(x => x.providerId === 4)
                setGooglePlacesData({ providerKey: data.providerKey, id: data.id })
            }
        }
        else {
            setHotelPlannerData(hotelPlannerInitialData)
            setKayakData(kayakInitialData)
            setGooglePlacesData(googlePlacesInitialData)
        }
        setSectionLoading(false);
    }

    useEffect(() => {
        if (allProviderHotels && allProviderHotels.length > 0 && selectedHotel) fetchData();
    }, [allProviderHotels, allProviderHotels?.length > 0, selectedHotel.hotelId])

    const saveProvidersChanges = async ({ providerId, updatedData }) => {
        setLoading(true);
        try {
            if (updatedData.id !== null) {
                const data = {
                    providerKey: updatedData.providerKey
                }
                if (updatedData.providerKey && updatedData.providerKey !== null && updatedData.providerKey !== "" && updatedData.providerKey.length > 0) await updateProviderHotels(selectedHotel.hotelId, updatedData.id, data)
                else await deleteProviderHotel(selectedHotel.hotelId, updatedData.id)
            }
            else {
                const data = {
                    providerId: providerId,
                    providerKey: updatedData.providerKey
                }
                const { data: { result } } = await addProviderHotels(selectedHotel.hotelId, data)
                updateAllProviderHotels(result)
            }
            ToastNotification('success', 'Booking Providers updated successfully.')
            setLoading(false);
        } catch (error) {
            ToastNotification('error', 'Oops! Cannot update Booking Providers, please try again later.')
            setLoading(false);
        }
    }

    return (
        <div className='flex flex-col mt-[20px] gap-[20px]'>
            <div className='flex flex-col md:flex-row gap-[20px]'>
                <label className="block w-full md:w-[300px]">
                    <span className="text-[15px]">HotelGyms.com ID</span>
                    <div className='flex w-full relative'>
                        <input
                            type="text"
                            name="hotelId"
                            value={selectedHotel?.hotelId}
                            className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                            placeholder='HotelGyms.com ID'
                            disabled
                            readOnly
                        />
                    </div>
                </label>
                <label className="block w-full md:w-[300px]">
                    <span className="text-[15px]">Booking.com ID</span>
                    <div className='flex w-full relative'>
                        <input
                            type="text"
                            name="bookingHotelId"
                            value={selectedHotel?.bookingHotelId}
                            className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                            placeholder='Booking.com ID'
                            disabled
                            readOnly
                        />
                    </div>
                </label>
            </div>
            <div className='flex flex-col gap-[20px]'>
                <div className='flex w-full md:w-[620px] items-center gap-[15px]'>
                    <label className="block w-full">
                        <span className="text-[15px]">HotelPlanner ID</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="providerKey"
                                value={hotelPlannerData.providerKey}
                                onChange={(e) => onHotelPlannerChange(e, 'providerKey', 'text')}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='HotelPlanner ID'
                                disabled={lockedFields.hotelPlannerId}
                            />
                        </div>
                    </label>
                    <div className='flex items-end h-[70px] gap-[15px]'>
                        <button
                            className='text-[15px] text-white border-0 bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]'
                            onClick={() => handleChangeFieldStatus('hotelPlannerId', lockedFields.hotelPlannerId)}
                        >
                            {lockedFields.hotelPlannerId ? 'Edit' : 'Cancel'}
                        </button>
                        <button
                            className={`text-[15px] text-white border-0 ${lockedFields.hotelPlannerId ? 'opacity-70' : 'opacity-100'} bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]`}
                            onClick={() => { saveProvidersChanges({ providerId: 49, updatedData: hotelPlannerData }); handleChangeFieldStatus('hotelPlannerId', lockedFields.hotelPlannerId); }}
                            disabled={lockedFields.hotelPlannerId || loading}
                        >
                            {!loading ? 'Save' : <LoadingDots customStyles={'bg-white mx-[10px]'} dotsNumber={1} />}
                        </button>
                    </div>
                </div>
                <div className='flex w-full md:w-[620px] items-center gap-[15px]'>
                    <label className="block w-full">
                        <span className="text-[15px]">Kayak ID</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="providerKey"
                                value={kayakData.providerKey}
                                onChange={(e) => onKayakChange(e, 'providerKey', 'text')}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='Kayak ID'
                                disabled={lockedFields.kayakId}
                            />
                        </div>
                    </label>
                    <div className='flex items-end h-[70px] gap-[15px]'>
                        <button
                            className='text-[15px] text-white border-0 bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]'
                            onClick={() => handleChangeFieldStatus('kayakId', lockedFields.kayakId)}
                        >
                            {lockedFields.kayakId ? 'Edit' : 'Cancel'}
                        </button>
                        <button
                            className={`text-[15px] text-white border-0 ${lockedFields.kayakId ? 'opacity-70' : 'opacity-100'} bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]`}
                            onClick={() => { saveProvidersChanges({ providerId: 43, updatedData: kayakData }); handleChangeFieldStatus('kayakId', lockedFields.kayakId); }}
                            disabled={lockedFields.kayakId || loading}
                        >
                            {!loading ? 'Save' : <LoadingDots customStyles={'bg-white mx-[10px]'} dotsNumber={1} />}
                        </button>
                    </div>
                </div>
                <div className='flex w-full md:w-[620px] items-center gap-[15px]'>
                    <label className="block w-full">
                        <span className="flex items-center gap-[15px] text-[15px]">
                            Google Places ID
                            <a href='https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder' target='_blank' rel='noopener noreferrer' className='cursor-pointer'>
                                <img
                                    className='w-[15px] h-[15px]'
                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/external-link-icon.svg`}
                                    alt="Place ID Finder | HotelGyms.com"
                                    width='15'
                                    height='15'
                                />
                            </a>
                        </span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="providerKey"
                                value={googlePlacesData.providerKey || ''}
                                onChange={(e) => onGooglePlacesChange(e, 'providerKey', 'text')}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='Google Places ID'
                                disabled={lockedFields.googlePlacesId}
                            />
                        </div>
                    </label>
                    <div className='flex items-end h-[70px] gap-[15px]'>
                        <button
                            className='text-[15px] text-white border-0 bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]'
                            onClick={() => handleChangeFieldStatus('googlePlacesId', lockedFields.googlePlacesId)}
                        >
                            {lockedFields.googlePlacesId ? 'Edit' : 'Cancel'}
                        </button>
                        <button
                            className={`text-[15px] text-white border-0 ${lockedFields.googlePlacesId ? 'opacity-70' : 'opacity-100'} bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px] h-[44.5px] w-[79px]`}
                            onClick={() => { saveProvidersChanges({ providerId: 4, updatedData: googlePlacesData }); handleChangeFieldStatus('googlePlacesId', lockedFields.googlePlacesId); }}
                            disabled={lockedFields.googlePlacesId || loading}
                        >
                            {!loading ? 'Save' : <LoadingDots customStyles={'bg-white mx-[10px]'} dotsNumber={1} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditorPageProviders;