import React from 'react';
import toast from 'react-hot-toast';

const ToastNotification = (kind, message) => {
    return (
        <>
            {toast[kind](message,
                {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            )}
        </>
    )
}

export default ToastNotification;