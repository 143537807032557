import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import KindeLogout from './pages/KindeLogout';
import MainRouter from './MainRouter';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

// import { Navigate } from 'react-router-dom';
// Example of a route that redirects
// <Route path="/old-path" element={<Navigate to="/new-path" />} />

export function Router() {
    const { getPermissions, getToken } = useKindeAuth();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const checkAuthorization = useCallback(async () => {
        try {
            const data = getPermissions();
            const hasAdminAccess = data.permissions.includes("admin-access");
            setIsAuthorized(hasAdminAccess);
            if (hasAdminAccess) {
                const token = await getToken();
                if (token) sessionStorage.setItem('AccessToken', token);
            }
        } catch (error) {
            // Handle error if any
            console.error("Error while fetching permissions:", error);
        } finally {
            setIsLoading(false); // Set loading state to false after permissions are fetched
        }
    }, [getPermissions, getToken]);

    useEffect(() => {
        checkAuthorization();
    }, [checkAuthorization]);

    if (isLoading) return <div>Loading...</div>;

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/logout" element={<KindeLogout />} />
                {!isAuthorized ? (
                    <Route path="*" element={<Navigate to="/logout" replace />} />
                ) : (
                    <Route path="*" element={<MainRouter />} />
                )}
            </Routes>
        </BrowserRouter>
    );
}