import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './flags.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { Toaster } from 'react-hot-toast';
import { PrimeReactProvider } from 'primereact/api';
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "@fontsource/lato";
import "@fontsource/lato/700.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <KindeProvider
      audience={process.env.REACT_APP_KINDE_AUDIENCE}
      clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
      domain={process.env.REACT_APP_KINDE_DOMAIN}
      redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URL}
      logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URL}
    >
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </KindeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
