import styled from 'styled-components';

export const StyledInput = styled.input`
  display: flex;
  background-clip: padding-box !important;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  min-height: 40px;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  padding-left: 15px;
  :focus{
    outline: none !important;
    border: 2px solid #0098C9;
  }
  ::placeholder {
    color: #A9A9A9;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #A9A9A9;
  }
  ::-ms-input-placeholder { 
    color: #A9A9A9;
  }
`
export const StyledTextArea = styled.textarea`
  display: flex;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  min-height: 40px;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 15px;
  :focus{
    outline: none !important;
    border: 2px solid #0098C9;
  }
  ::placeholder {
    color: #A9A9A9;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #A9A9A9;
  }
  ::-ms-input-placeholder { 
    color: #A9A9A9;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon-style{
    display: flex;
    position: absolute;
    padding-right: 12px;
    align-self: center;
    justify-content: flex-end;
  }
  .front-icon-style{
    position: absolute;
    // left: 27px;
  }
`