import React, { useEffect, useState } from 'react';
import ToastNotification from './ToastNotification';
import LoadingDots from './LoadingDots';
import { addExternalGym, addHotelGymCooperation, getExternalGymCooperationsAdmin, getExternalGyms, updateHotelGymCooperation } from '../redux.js/api';
import Radio from './Radio';
import { Dropdown } from 'primereact/dropdown';
import AppImage from './AppImage';
import InputBlock from './InputBlock';
import { EditorPageExternalGymCooperationWrapper } from './ComponentStyles';

const EditorPageExternalGymCooperation = ({ hotel }) => {
    const [placeId, setPlaceId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const [nearbyExternalGyms, setNearbyExternalGyms] = useState([]);
    const [selectedNearbyExternalGym, setSelectedNearbyExternalGym] = useState(false);
    const [gymCooperationData, setGymCooperationData] = useState({
        hasCooperation: null,
        cooperationHasFee: null,
        dayPassPrice: null,
        dayPassCurrency: 'USD',
    })
    const [loadingAddCooperationButton, setLoadingAddCooperationButton] = useState(false);
    const [hotelGymCooperations, setHotelGymCooperations] = useState([]);

    useEffect(() => {
        async function fetchNearbyExternalGyms() {
            const { data: { result } } = await getExternalGyms(hotel.location.coordinates.latitude, hotel.location.coordinates.longitude);
            const all = result?.map(r => ({ value: r.id, label: `${r.name}, ${r.formatedAddress}`, name: r.name, image: r.image, address: r.formatedAddress }));
            setNearbyExternalGyms(all)
        }
        async function fetchHotelGymCooperations() {
            const { data: { result } } = await getExternalGymCooperationsAdmin(hotel.bookingHotelId)
            const editedResult = result && result !== null && result.length > 0 && result.map(res => ({
                id: res.id,
                hotelExternalGymId: res.externalGymId,
                hasCooperation: res.hasCooperation,
                dayPassPrice: res.dayPassPrice,
                dayPassCurrency: res.dayPassCurrency,
                isGymFactorOverruled: res.isGymFactorOverruled,
                showAsFirstNearbyGym: res.showAsFirstNearbyGym,
                useGymFactorImages: res.useGymFactorImages,
                images: res.externalGym.images,
                name: res.externalGym.name,
                formatedAddress: res.externalGym.formatedAddress,
                hasGroupFitnessClasses: res.externalGym.hasGroupFitnessClasses,
                hasChangingRooms: res.externalGym.hasChangingRooms,
                hasLockers: res.externalGym.hasLocker,
            }))
            setHotelGymCooperations(editedResult && editedResult.length > 0 ? editedResult : [])
        }
        fetchNearbyExternalGyms();
        fetchHotelGymCooperations();
    }, [hotel, refresh]);

    useEffect(() => {
        if (gymCooperationData.hasCooperation === false || gymCooperationData.hasCooperation === null) {
            setGymCooperationData({ ...gymCooperationData, cooperationHasFee: null, dayPassPrice: null })
            setSelectedNearbyExternalGym(false)
        }
    }, [gymCooperationData.hasCooperation])

    const onInputChange = (e, name, type) => {
        setGymCooperationData({ ...gymCooperationData, [name]: e.target.value })
    }

    const updateHotelGymCooperationsList = (record, type, editedIndex) => {
        if (type === 'add') {
            setHotelGymCooperations((prevCooperations) => [record, ...prevCooperations]);
            // setHotelGymCooperations([record, ...hotelGymCooperations]);
        }
        else {
            setHotelGymCooperations([
                ...hotelGymCooperations.slice(0, editedIndex),
                record,
                ...hotelGymCooperations.slice(editedIndex + 1),
            ]);
        }
    }

    const insertCooperation = async () => {
        setLoadingAddCooperationButton(true);
        try {
            const data = {
                bookingHotelId: hotel.bookingHotelId,
                externalGymId: selectedNearbyExternalGym.value,
                hasCooperation: gymCooperationData.hasCooperation,
                dayPassPrice: (gymCooperationData.cooperationHasFee === false || gymCooperationData.cooperationHasFee === null) ? null : parseFloat(gymCooperationData.dayPassPrice ? gymCooperationData.dayPassPrice : 0),
                dayPassCurrency: gymCooperationData.dayPassCurrency
            }
            const newlyAddedResponse = await addHotelGymCooperation(data)
            ToastNotification('success', 'Cooperation added successfully!')
            const record = {
                id: newlyAddedResponse.data.result.id,
                hotelExternalGymId: newlyAddedResponse.data.result.externalGymId,
                hasCooperation: newlyAddedResponse.data.result.hasCooperation,
                dayPassPrice: newlyAddedResponse.data.result.dayPassPrice,
                dayPassCurrency: newlyAddedResponse.data.result.dayPassCurrency,
                image: newlyAddedResponse.data.result.externalGym.image,
                name: newlyAddedResponse.data.result.externalGym.name,
                formatedAddress: newlyAddedResponse.data.result.externalGym.formatedAddress
            }
            updateHotelGymCooperationsList(record, 'add')
            setGymCooperationData({
                ...gymCooperationData,
                hasCooperation: null
            })
            setLoadingAddCooperationButton(false);
        } catch (error) {
            ToastNotification('error', 'Cannot add cooperation at this moment!')
            setGymCooperationData({
                ...gymCooperationData,
                hasCooperation: null
            })
            setLoadingAddCooperationButton(false);
        }
    }

    const handleSavePlaceId = async () => {
        setLoading(true)
        try {
            await addExternalGym(placeId)
            setPlaceId("")
            setRefresh(!refresh)
            ToastNotification('success', 'Gym added successfully')
        } catch (error) {
            ToastNotification('error', 'Cannot add gym!')
        }
        setLoading(false)
    }

    const handleSelectedNearbyExternalGym = (e) => {
        const value = e.target.value;
        const selectedGym = nearbyExternalGyms.find(x => x.value === value)
        setSelectedNearbyExternalGym(selectedGym)
        // setGymCooperationData({ ...gymCooperationData, cooperationId: value })
    }

    return (
        <EditorPageExternalGymCooperationWrapper>
            {
                loading &&
                <div className='flex items-center justify-center w-full h-[150px]'>
                    <LoadingDots customStyles={'mx-[10px]'} dotsNumber={3} />
                </div>
            }
            {nearbyExternalGyms.length > 0 ?
                <>
                    <div className='flex w-full'>
                        <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Add Gym Cooperation</span>
                    </div>
                    <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[20px]'>
                        <div className='flex'>
                            <span className='text-[15px]'>Has cooperation?</span>
                            <div className='flex ml-auto'>
                                <Radio
                                    onRadioChange={() => setGymCooperationData({ ...gymCooperationData, hasCooperation: gymCooperationData.hasCooperation === true ? null : true })}
                                    name='hasCooperation' label='Yes'
                                    radioSelected={gymCooperationData.hasCooperation === true}
                                />
                                <Radio
                                    onRadioChange={() => setGymCooperationData({ ...gymCooperationData, hasCooperation: gymCooperationData.hasCooperation === false ? null : false })}
                                    name='hasCooperation' label='No'
                                    radioSelected={gymCooperationData.hasCooperation === false}
                                />
                            </div>
                        </div>
                        {gymCooperationData.hasCooperation ?
                            <>
                                {nearbyExternalGyms.length > 0 &&
                                    <div className='flex w-full'>
                                        <div className="flex w-full mt-[5px] h-[44.5px]">
                                            <Dropdown
                                                options={nearbyExternalGyms}
                                                value={selectedNearbyExternalGym.value}
                                                onChange={(e) => handleSelectedNearbyExternalGym(e)}
                                                optionLabel="label"
                                                placeholder="Select a Gym"
                                                className="w-full rounded-[8px] primeDropdownStyle"
                                            />
                                        </div>
                                    </div>
                                }

                                {selectedNearbyExternalGym?.value ?
                                    <>
                                        <div className='selected-hotel no-margin' key={selectedNearbyExternalGym.value}>
                                            {selectedNearbyExternalGym?.image ?
                                                <img
                                                    src={selectedNearbyExternalGym.image}
                                                    alt='External Gym Photo | HotelGyms.com'
                                                    width='100'
                                                    height='91'
                                                    className='hotel-image'
                                                />
                                                :
                                                <AppImage name={'external-gym-default.svg'} folder={`app`} width='100' height='91' alt='External Gym Photo | HotelGyms.com' className='hotel-image' />
                                            }
                                            <div className='hotel-text'>
                                                <span className='hotel-name'>{selectedNearbyExternalGym.name}</span>
                                                <span className='hotel-address'>{selectedNearbyExternalGym.address}</span>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-[15px]'>Will there be a fee to enter the partner-gym?</span>
                                            <div className='flex ml-auto'>
                                                <Radio
                                                    onRadioChange={() => setGymCooperationData({ ...gymCooperationData, cooperationHasFee: gymCooperationData.cooperationHasFee === true ? null : true })}
                                                    name='cooperationHasFee'
                                                    label='Yes'
                                                    radioSelected={gymCooperationData.cooperationHasFee === true}
                                                />
                                                <Radio
                                                    onRadioChange={() => setGymCooperationData({ ...gymCooperationData, cooperationHasFee: gymCooperationData.cooperationHasFee === false ? null : false })}
                                                    name='cooperationHasFee'
                                                    label='No'
                                                    radioSelected={gymCooperationData.cooperationHasFee === false}
                                                />
                                            </div>
                                        </div>
                                        {gymCooperationData.cooperationHasFee === true &&
                                            <div className='radio-field flex items-center'>
                                                <div className='radio-text'>
                                                    <span>Price per day pass</span>
                                                </div>
                                                <div className='max-w-[200px] flex ml-auto'>
                                                    <InputBlock
                                                        inputColumnStyles='price-input-style'
                                                        onInputChange={onInputChange}
                                                        name='dayPassPrice'
                                                        inputValue={gymCooperationData}
                                                        frontIcon='onboarding-form-money-icon.svg'
                                                        type='number'
                                                    />
                                                    <select
                                                        id="dayPassCurrency"
                                                        name="dayPassCurrency"
                                                        className='dropdown-style dd-small left-no-radius'
                                                        onChange={(e) => onInputChange(e, 'dayPassCurrency')}
                                                    >
                                                        <option value="USD" selected={gymCooperationData.dayPassCurrency === "USD" ? true : false}>USD</option>
                                                        <option value="EUR" selected={gymCooperationData.dayPassCurrency === "EUR" ? true : false}>EUR</option>
                                                        <option value="CHF" selected={gymCooperationData.dayPassCurrency === "CHF" ? true : false}>CHF</option>
                                                        <option value="GBP" selected={gymCooperationData.dayPassCurrency === "GBP" ? true : false}>GBP</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className='no-margin' />
                                }

                            </>
                            :
                            <div className='h-[9px]' />
                        }
                    </div>
                    <div className='flex mt-[20px]'>
                        <button
                            onClick={() => { insertCooperation() }}
                            type="submit"
                            disabled={
                                loadingAddCooperationButton ||
                                (
                                    (gymCooperationData.hasCooperation !== true) ||
                                    (!selectedNearbyExternalGym && !selectedNearbyExternalGym.value)
                                )
                            }
                            className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black`}>
                            Add Gym Cooperation {loadingAddCooperationButton &&
                                <LoadingDots customStyles={'bg-white'} dotsNumber={1} />
                            }
                        </button>
                    </div>
                </>
                :
                <span>No nearby external gyms found!</span>
            }
            <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[20px]'>
                <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Hotel's Gym Cooperations</span>
                <div className='flex w-full mt-[-10px]'>
                    <span className='text-[13px] italic text-color_text_gray_light'>
                        NOTE: To edit a record first click the "Edit Cooperation" button under that record.
                    </span>
                </div>
                <div className='flex flex-col'>
                    {hotelGymCooperations.length > 0 && hotelGymCooperations?.filter(gym => gym.hasCooperation === true).map((x, index) => (
                        <div className='flex w-full' key={index}>
                            <HotelCooperation
                                x={x}
                                index={index}
                                updateHotelGymCooperationsList={updateHotelGymCooperationsList}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex flex-col mt-[20px] lg:mt-[30px] gap-[15px] mb-[20px]'>
                <div className='flex w-full md:w-[350px]'>
                    <label className="block w-full">
                        <span className="text-[15px]">Add Place Id</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="placeId"
                                value={placeId}
                                onChange={(e) => setPlaceId(e.target.value)}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='Place Id'
                            />
                        </div>
                    </label>
                </div>
                <div className='flex'>
                    <button
                        onClick={() => { handleSavePlaceId() }}
                        disabled={placeId?.length === 0 || loading}
                        type="submit"
                        className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black`}>
                        Add New Gym {loading &&
                            <LoadingDots customStyles={'bg-white'} dotsNumber={1} />
                        }
                    </button>
                </div>
            </div>
        </EditorPageExternalGymCooperationWrapper>
    );
};

export default EditorPageExternalGymCooperation;

const HotelCooperation = ({ x, index, updateHotelGymCooperationsList }) => {
    const [loadingEditSave, setLoadingEditSave] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);

    const [editGymCooperationData, setEditGymCooperationData] = useState({
        editCooperationId: x.id,
        editHasCooperation: x.hasCooperation,
        editExternalGymId: x.externalGymId,
        editDayPassPrice: x.dayPassPrice,
        editDayPassCurrency: x.dayPassCurrency,
        editCooperationHasFee: x.dayPassPrice === null ? false : true,
        editIsGymFactorOverruled: x.isGymFactorOverruled,
        editShowAsFirstNearbyGym: x.showAsFirstNearbyGym,
        editUseGymFactorImages: x.useGymFactorImages,
        editHasGroupFitnessClasses: x.hasGroupFitnessClasses,
        editHasChangingRooms: x.hasChangingRooms,
        editHasLockers: x.hasLockers
    })

    const handleEditSaveCooperation = async (disabledButton) => {
        if (disabledButton === true) {
            setDisabledButton(false);
        }
        else {
            setDisabledButton(true);
            setLoadingEditSave(true);
            try {
                const data = {
                    id: editGymCooperationData.editCooperationId,
                    hasCooperation: editGymCooperationData.editHasCooperation,
                    dayPassPrice: (editGymCooperationData.editCooperationHasFee === false || editGymCooperationData.editCooperationHasFee === null) ? null : parseFloat(editGymCooperationData.editDayPassPrice ? editGymCooperationData.editDayPassPrice : 0),
                    dayPassCurrency: editGymCooperationData.editDayPassCurrency,
                    isGymFactorOverruled: editGymCooperationData.editIsGymFactorOverruled,
                    showAsFirstNearbyGym: editGymCooperationData.editShowAsFirstNearbyGym,
                    useGymFactorImages: editGymCooperationData.editUseGymFactorImages,
                    hasGroupFitnessClasses: editGymCooperationData.editHasGroupFitnessClasses,
                    hasChangingRooms: editGymCooperationData.editHasChangingRooms,
                    hasLockers: editGymCooperationData.editHasLockers
                }
                const modifiedRecord = await updateHotelGymCooperation(data)
                const record = {
                    id: modifiedRecord.data.result.id,
                    hotelExternalGymId: modifiedRecord.data.result.externalGymId,
                    hasCooperation: modifiedRecord.data.result.hasCooperation,
                    dayPassPrice: modifiedRecord.data.result.dayPassPrice,
                    dayPassCurrency: modifiedRecord.data.result.dayPassCurrency,
                    isGymFactorOverruled: modifiedRecord.data.result.isGymFactorOverruled,
                    showAsFirstNearbyGym: modifiedRecord.data.result.showAsFirstNearbyGym,
                    useGymFactorImages: modifiedRecord.data.result.useGymFactorImages,
                    images: modifiedRecord.data.result.externalGym.images,
                    name: modifiedRecord.data.result.externalGym.name,
                    formatedAddress: modifiedRecord.data.result.externalGym.formatedAddress,
                    hasGroupFitnessClasses: modifiedRecord.data.result.externalGym.hasGroupFitnessClasses,
                    hasChangingRooms: modifiedRecord.data.result.externalGym.hasChangingRooms,
                    hasLockers: modifiedRecord.data.result.externalGym.hasLockers
                }
                updateHotelGymCooperationsList(record, 'edit', index)
                ToastNotification('success', `Cooperation edited successfully`)
                setLoadingEditSave(false);
            } catch (error) {
                ToastNotification('error', 'Oops! Cannot edit Cooperation!')
                setLoadingEditSave(false);
            }
        }
    }

    const onEditedInputChange = (e, name, type) => {
        setEditGymCooperationData({ ...editGymCooperationData, [name]: e.target.value })
    }

    return (
        <div className='flex w-full flex-col mb-[20px] pb-[20px] border-b border-solid'>
            <div className='w-full flex flex-col gap-[15px]'>
                {!disabledButton &&
                    <div className='radio-field flex items-center'>
                        <div className='radio-text'>
                            <span>Has cooperation? <i className='text-[11px] text-gray'>*To delete this record select "No".</i></span>
                        </div>
                        <div className='radio-input ml-auto' key={editGymCooperationData.editHasCooperation}>
                            <Radio
                                onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasCooperation: editGymCooperationData.editHasCooperation === true ? null : true })}
                                name='editHasCooperation' label='Yes'
                                radioSelected={editGymCooperationData.editHasCooperation === true}
                            />
                            <Radio
                                onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasCooperation: editGymCooperationData.editHasCooperation === false ? null : false })}
                                name='editHasCooperation' label='No'
                                radioSelected={editGymCooperationData.editHasCooperation === false}
                            />
                        </div>
                    </div>
                }
                <div className='selected-hotel no-margin mt-0 h-[75px]'>
                    {x && x.images && x.images !== null && x.images.length > 0 ?
                        <img
                            src={x.images[0]}
                            alt='External Gym Photo | HotelGyms.com'
                            width='100'
                            height='55'
                            className='hotel-image'
                        />
                        :
                        <AppImage name={'external-gym-default.svg'} folder={`app`} width='100' height='55' alt='External Gym Photo | HotelGyms.com' className='hotel-image' />
                    }
                    <div className='hotel-text'>
                        <span className='hotel-name'>{x?.name}</span>
                        <span className='hotel-address'>{x?.formatedAddress}</span>
                    </div>
                </div>
                <div className='flex flex-col gap-[20px]'>
                    {!disabledButton &&
                        <div className='radio-field flex items-center'>
                            <div className='radio-text'>
                                <span>Will there be a fee to enter the partner-gym?</span>
                            </div>
                            <div className='radio-input ml-auto'>
                                <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editCooperationHasFee: editGymCooperationData.editCooperationHasFee === true ? null : true })} name='editCooperationHasFee' label='Yes' radioSelected={editGymCooperationData.editCooperationHasFee === true} />
                                <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editCooperationHasFee: editGymCooperationData.editCooperationHasFee === false ? null : false })} name='editCooperationHasFee' label='No' radioSelected={editGymCooperationData.editCooperationHasFee === false} />
                            </div>
                        </div>
                    }
                    {editGymCooperationData.editCooperationHasFee === true &&
                        <div className='radio-field flex items-center'>
                            <div className='radio-text'>
                                <span>Price per day pass</span>
                            </div>
                            <div className='max-w-[200px] flex ml-auto'>
                                <InputBlock
                                    inputColumnStyles='price-input-style'
                                    onInputChange={onEditedInputChange}
                                    name='editDayPassPrice'
                                    inputValue={editGymCooperationData}
                                    frontIcon='onboarding-form-money-icon.svg'
                                    type='number'
                                    disabled={disabledButton}
                                />
                                <select
                                    id="editDayPassCurrency"
                                    name="editDayPassCurrency"
                                    className='dropdown-style dd-small left-no-radius'
                                    onChange={(e) => onEditedInputChange(e, 'editDayPassCurrency')}
                                >
                                    <option value="USD" selected={editGymCooperationData.editDayPassCurrency === "USD" ? true : false}>USD</option>
                                    <option value="EUR" selected={editGymCooperationData.editDayPassCurrency === "EUR" ? true : false}>EUR</option>
                                    <option value="CHF" selected={editGymCooperationData.editDayPassCurrency === "CHF" ? true : false}>CHF</option>
                                    <option value="GBP" selected={editGymCooperationData.editDayPassCurrency === "GBP" ? true : false}>GBP</option>
                                </select>
                            </div>
                        </div>
                    }
                    {!disabledButton &&
                        <>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Group Fitness</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasGroupFitnessClasses: editGymCooperationData.editHasGroupFitnessClasses === true ? null : true })} name='editHasGroupFitnessClasses' label='Yes' radioSelected={editGymCooperationData.editHasGroupFitnessClasses === true} />
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasGroupFitnessClasses: editGymCooperationData.editHasGroupFitnessClasses === false ? null : false })} name='editHasGroupFitnessClasses' label='No' radioSelected={editGymCooperationData.editHasGroupFitnessClasses === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Changing room</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasChangingRooms: editGymCooperationData.editHasChangingRooms === true ? null : true })} name='editHasChangingRooms' label='Yes' radioSelected={editGymCooperationData.editHasChangingRooms === true} />
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasChangingRooms: editGymCooperationData.editHasChangingRooms === false ? null : false })} name='editHasChangingRooms' label='No' radioSelected={editGymCooperationData.editHasChangingRooms === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Lockers</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasLockers: editGymCooperationData.editHasLockers === true ? null : true })} name='editHasLockers' label='Yes' radioSelected={editGymCooperationData.editHasLockers === true} />
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editHasLockers: editGymCooperationData.editHasLockers === false ? null : false })} name='editHasLockers' label='No' radioSelected={editGymCooperationData.editHasLockers === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Overrule GymFactor</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editIsGymFactorOverruled: editGymCooperationData.editIsGymFactorOverruled === true ? true : true })} name='editIsGymFactorOverruled' label='Yes' radioSelected={editGymCooperationData.editIsGymFactorOverruled === true} />
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editIsGymFactorOverruled: editGymCooperationData.editIsGymFactorOverruled === false ? false : false })} name='editIsGymFactorOverruled' label='No' radioSelected={editGymCooperationData.editIsGymFactorOverruled === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Show as first Nearby Gym</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editShowAsFirstNearbyGym: editGymCooperationData.editShowAsFirstNearbyGym === true ? true : true })} name='editShowAsFirstNearbyGym' label='Yes' radioSelected={editGymCooperationData.editShowAsFirstNearbyGym === true} />
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editShowAsFirstNearbyGym: editGymCooperationData.editShowAsFirstNearbyGym === false ? false : false })} name='editShowAsFirstNearbyGym' label='No' radioSelected={editGymCooperationData.editShowAsFirstNearbyGym === false} />
                                </div>
                            </div>
                            <div className='radio-field flex items-center'>
                                <div className='radio-text'>
                                    <span>Use GymFactor Images</span>
                                </div>
                                <div className='radio-input ml-auto'>
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editUseGymFactorImages: editGymCooperationData.editUseGymFactorImages === true ? true : true })} name='editUseGymFactorImages' label='Yes' radioSelected={editGymCooperationData.editUseGymFactorImages === true} />
                                    <Radio onRadioChange={() => setEditGymCooperationData({ ...editGymCooperationData, editUseGymFactorImages: editGymCooperationData.editUseGymFactorImages === false ? false : false })} name='editUseGymFactorImages' label='No' radioSelected={editGymCooperationData.editUseGymFactorImages === false} />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className='flex mt-[20px]'>
                <button
                    onClick={() => { handleEditSaveCooperation(disabledButton) }}
                    type="submit"
                    className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black`}>
                    {disabledButton ? 'Edit Cooperation' : 'Save Changes'} {loadingEditSave &&
                        <LoadingDots customStyles={'bg-white'} dotsNumber={1} />
                    }
                </button>
            </div>
        </div>
    )
}
