import React from 'react';
import Input from '../Input';

const InputBlock = ({ inputColumnStyles, onInputChange, name, inputValue, type = 'text', inputText, placeholder, icon, frontIcon, rows, id, value, disabled }) => {
  return (
    <div className={inputColumnStyles}>
      <span className='input-title'>{inputText}</span>
      <Input onInputChange={onInputChange} name={name} inputValue={inputValue} type={type} placeholder={placeholder} icon={icon} frontIcon={frontIcon} rows={rows} id={id} value={value} disabled={disabled} />
    </div>
  )
}
export default InputBlock;