export const webAppSettings = {
    "AddHotelReviewAndMaps": "AddHotelReviewAndMaps",
    "AllowHotelReviewUpdates": "AllowHotelReviewUpdates",
    "AllowGoogleGymCooperationData": "AllowGoogleGymCooperationData",
    "AllowNearbyGymsInformationUpdates": "AllowNearbyGymsInformationUpdates",
    "AllowTripAdvisorScore": "AllowTripAdvisorScore",
    "AllowTripAdvisorTextReviews": "AllowTripAdvisorTextReviews",
    "EnablePremiumMaps": "EnablePremiumMaps",
    "LockDetailPage": "LockDetailPage",
}

export const gymFactorSourceTypes = {
    1: "Booking Partners",
    2: "Hotel Data",
    3: "Partner Data",
    4: "Travel Data",
    5: "Blogs",
    6: "Social Data",
    7: "HotelGyms Assets",
    8: "Data Providers",
}

export const gymDetailsPropertyNames = {
    1: 'Peloton Bikes',
    2: 'Towels',
    3: 'Water',
    4: 'Cardio Machines',
    5: 'Strength Machines',
    6: 'Free Weights'
}

export const gymDetailsPropertyIcons = {
    1: 'gym-info-peloton-icon-new.svg',
    2: 'gym-info-towels-icon-new.svg',
    3: 'gym-info-water-icon-new.svg',
    4: 'gym-info-cardiomachines-icon-new.svg',
    5: 'gym-info-strengthmachines-icon-new.svg',
    6: 'gym-info-maxweight-icon-new.svg'
}

export const imageSizes = {
    searchList: {
        galleryThumbnails: {
            desktop: {
                width: 130,
                height: 120
            },
            mobile: {
                width: 65,
                height: 60
            }
        },
        galleryPreview: {
            desktop: {
                main: {
                    width: 270,
                    height: 235
                },
                thumb: {
                    width: 102,
                    height: 70
                }
            },
            mobile: {
                main: {
                    width: 132,
                    height: 110
                },
                thumb: {
                    width: 57,
                    height: 70
                }
            }
        }
    },
}

export const countries = [
    { name: 'Andorra', code: 'ad' },
    { name: 'United Arab Emirates', code: 'ae' },
    { name: 'Afghanistan', code: 'af' },
    { name: 'Antigua & Barbuda', code: 'ag' },
    { name: 'Anguilla', code: 'ai' },
    { name: 'Albania', code: 'al' },
    { name: 'Armenia', code: 'am' },
    { name: 'Angola', code: 'ao' },
    { name: 'Antarctica', code: 'aq' },
    { name: 'Argentina', code: 'ar' },
    { name: 'American Samoa', code: 'as' },
    { name: 'Austria', code: 'at' },
    { name: 'Australia', code: 'au' },
    { name: 'Aruba', code: 'aw' },
    { name: 'Åland Islands', code: 'ax' },
    { name: 'Azerbaijan', code: 'az' },
    { name: 'Bosnia and Herzegovina', code: 'ba' },
    { name: 'Barbados', code: 'bb' },
    { name: 'Bangladesh', code: 'bd' },
    { name: 'Belgium', code: 'be' },
    { name: 'Burkina Faso', code: 'bf' },
    { name: 'Bulgaria', code: 'bg' },
    { name: 'Bahrain', code: 'bh' },
    { name: 'Burundi', code: 'bi' },
    { name: 'Benin', code: 'bj' },
    { name: 'Saint Barthelemy', code: 'bl' },
    { name: 'Bermuda', code: 'bm' },
    { name: 'Brunei Darussalam', code: 'bn' },
    { name: 'Bolivia', code: 'bo' },
    { name: 'Bonaire St Eustatius and Saba', code: 'bq' },
    { name: 'Brazil', code: 'br' },
    { name: 'Bahamas', code: 'bs' },
    { name: 'Bhutan', code: 'bt' },
    { name: 'Bouvet Island', code: 'bv' },
    { name: 'Botswana', code: 'bw' },
    { name: 'Belarus', code: 'by' },
    { name: 'Belize', code: 'bz' },
    { name: 'Canada', code: 'ca' },
    { name: 'Cocos (K) I.', code: 'cc' },
    { name: 'Democratic Republic of Congo', code: 'cd' },
    { name: 'Central Africa Republic', code: 'cf' },
    { name: 'Congo', code: 'cg' },
    { name: 'Switzerland', code: 'ch' },
    { name: 'Côte d\'Ivoire', code: 'ci' },
    { name: 'Cook Islands', code: 'ck' },
    { name: 'Chile', code: 'cl' },
    { name: 'Cameroon', code: 'cm' },
    { name: 'China', code: 'cn' },
    { name: 'Colombia', code: 'co' },
    { name: 'Costa Rica', code: 'cr' },
    { name: 'Cuba', code: 'cu' },
    { name: 'Cape Verde', code: 'cv' },
    { name: 'Curaçao', code: 'cw' },
    { name: 'Christmas Island', code: 'cx' },
    { name: 'Cyprus', code: 'cy' },
    { name: 'Czech Republic', code: 'cz' },
    { name: 'Germany', code: 'de' },
    { name: 'Djibouti', code: 'dj' },
    { name: 'Denmark', code: 'dk' },
    { name: 'Dominica', code: 'dm' },
    { name: 'Dominican Republic', code: 'do' },
    { name: 'Algeria', code: 'dz' },
    { name: 'Ecuador', code: 'ec' },
    { name: 'Estonia', code: 'ee' },
    { name: 'Egypt', code: 'eg' },
    { name: 'Western Sahara', code: 'eh' },
    { name: 'Eritrea', code: 'er' },
    { name: 'Spain', code: 'es' },
    { name: 'Ethiopia', code: 'et' },
    { name: 'Finland', code: 'fi' },
    { name: 'Fiji', code: 'fj' },
    { name: 'Falkland Islands (Malvinas)', code: 'fk' },
    { name: 'Micronesia', code: 'fm' },
    { name: 'Faroe Islands', code: 'fo' },
    { name: 'France', code: 'fr' },
    { name: 'Gabon', code: 'ga' },
    { name: 'United Kingdom', code: 'gb' },
    { name: 'Grenada', code: 'gd' },
    { name: 'Georgia', code: 'ge' },
    { name: 'French Guiana', code: 'gf' },
    { name: 'Guernsey', code: 'gg' },
    { name: 'Ghana', code: 'gh' },
    { name: 'Gibraltar', code: 'gi' },
    { name: 'Greenland', code: 'gl' },
    { name: 'Gambia', code: 'gm' },
    { name: 'Guinea', code: 'gn' },
    { name: 'Guadeloupe', code: 'gp' },
    { name: 'Equatorial Guinea', code: 'gq' },
    { name: 'Greece', code: 'gr' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'gs' },
    { name: 'Guatemala', code: 'gt' },
    { name: 'Guam', code: 'gu' },
    { name: 'Guinea-Bissau', code: 'gw' },
    { name: 'Guyana', code: 'gy' },
    { name: 'Hong Kong', code: 'hk' },
    { name: 'Heard and McDonald Islands', code: 'hm' },
    { name: 'Honduras', code: 'hn' },
    { name: 'Croatia', code: 'hr' },
    { name: 'Haiti', code: 'ht' },
    { name: 'Hungary', code: 'hu' },
    { name: 'Indonesia', code: 'id' },
    { name: 'Ireland', code: 'ie' },
    { name: 'Israel', code: 'il' },
    { name: 'Isle of Man', code: 'im' },
    { name: 'India', code: 'in' },
    { name: 'British Indian Ocean Territory', code: 'io' },
    { name: 'Iraq', code: 'iq' },
    { name: 'Iran', code: 'ir' },
    { name: 'Iceland', code: 'is' },
    { name: 'Italy', code: 'it' },
    { name: 'Jersey', code: 'je' },
    { name: 'Jamaica', code: 'jm' },
    { name: 'Jordan', code: 'jo' },
    { name: 'Japan', code: 'jp' },
    { name: 'Kenya', code: 'ke' },
    { name: 'Kyrgyzstan', code: 'kg' },
    { name: 'Cambodia', code: 'kh' },
    { name: 'Kiribati', code: 'ki' },
    { name: 'Comoros', code: 'km' },
    { name: 'Saint Kitts and Nevis', code: 'kn' },
    { name: 'North Korea', code: 'kp' },
    { name: 'South Korea', code: 'kr' },
    { name: 'Kuwait', code: 'kw' },
    { name: 'Cayman Islands', code: 'ky' },
    { name: 'Kazakhstan', code: 'kz' },
    { name: 'Laos', code: 'la' },
    { name: 'Lebanon', code: 'lb' },
    { name: 'Saint Lucia', code: 'lc' },
    { name: 'Liechtenstein', code: 'li' },
    { name: 'Sri Lanka', code: 'lk' },
    { name: 'Liberia', code: 'lr' },
    { name: 'Lesotho', code: 'ls' },
    { name: 'Lithuania', code: 'lt' },
    { name: 'Luxembourg', code: 'lu' },
    { name: 'Latvia', code: 'lv' },
    { name: 'Libya', code: 'ly' },
    { name: 'Morocco', code: 'ma' },
    { name: 'Monaco', code: 'mc' },
    { name: 'Moldova', code: 'md' },
    { name: 'Montenegro', code: 'me' },
    { name: 'Saint Martin', code: 'mf' },
    { name: 'Madagascar', code: 'mg' },
    { name: 'Marshall Islands', code: 'mh' },
    { name: 'North Macedonia', code: 'mk' },
    { name: 'Mali', code: 'ml' },
    { name: 'Myanmar', code: 'mm' },
    { name: 'Mongolia', code: 'mn' },
    { name: 'Macao', code: 'mo' },
    { name: 'Northern Mariana Islands', code: 'mp' },
    { name: 'Martinique', code: 'mq' },
    { name: 'Mauritania', code: 'mr' },
    { name: 'Montserrat', code: 'ms' },
    { name: 'Malta', code: 'mt' },
    { name: 'Mauritius', code: 'mu' },
    { name: 'Maldives', code: 'mv' },
    { name: 'Malawi', code: 'mw' },
    { name: 'Mexico', code: 'mx' },
    { name: 'Malaysia', code: 'my' },
    { name: 'Mozambique', code: 'mz' },
    { name: 'Namibia', code: 'na' },
    { name: 'New Caledonia', code: 'nc' },
    { name: 'Niger', code: 'ne' },
    { name: 'Norfolk Island', code: 'nf' },
    { name: 'Nigeria', code: 'ng' },
    { name: 'Nicaragua', code: 'ni' },
    { name: 'Netherlands', code: 'nl' },
    { name: 'Norway', code: 'no' },
    { name: 'Nepal', code: 'np' },
    { name: 'Nauru', code: 'nr' },
    { name: 'Niue', code: 'nu' },
    { name: 'New Zealand', code: 'nz' },
    { name: 'Oman', code: 'om' },
    { name: 'Panama', code: 'pa' },
    { name: 'Peru', code: 'pe' },
    { name: 'French Polynesia', code: 'pf' },
    { name: 'Papua New Guinea', code: 'pg' },
    { name: 'Philippines', code: 'ph' },
    { name: 'Pakistan', code: 'pk' },
    { name: 'Poland', code: 'pl' },
    { name: 'St Pierre and Miquelon', code: 'pm' },
    { name: 'Pitcairn', code: 'pn' },
    { name: 'Puerto Rico', code: 'pr' },
    { name: 'Palestinian Territory', code: 'ps' },
    { name: 'Portugal', code: 'pt' },
    { name: 'Palau', code: 'pw' },
    { name: 'Paraguay', code: 'py' },
    { name: 'Qatar', code: 'qa' },
    { name: 'Reunion', code: 're' },
    { name: 'Romania', code: 'ro' },
    { name: 'Serbia', code: 'rs' },
    { name: 'Russia', code: 'ru' },
    { name: 'Rwanda', code: 'rw' },
    { name: 'Saudi Arabia', code: 'sa' },
    { name: 'Solomon Islands', code: 'sb' },
    { name: 'Seychelles', code: 'sc' },
    { name: 'Sudan', code: 'sd' },
    { name: 'Sweden', code: 'se' },
    { name: 'Singapore', code: 'sg' },
    { name: 'St Helena', code: 'sh' },
    { name: 'Slovenia', code: 'si' },
    { name: 'Svalbard & Jan Mayen', code: 'sj' },
    { name: 'Slovakia', code: 'sk' },
    { name: 'Sierra Leone', code: 'sl' },
    { name: 'San Marino', code: 'sm' },
    { name: 'Senegal', code: 'sn' },
    { name: 'Somalia', code: 'so' },
    { name: 'Suriname', code: 'sr' },
    { name: 'South Sudan', code: 'ss' },
    { name: 'São Tomé and Príncipe', code: 'st' },
    { name: 'El Salvador', code: 'sv' },
    { name: 'Sint Maarten', code: 'sx' },
    { name: 'Syria', code: 'sy' },
    { name: 'Eswatini', code: 'sz' },
    { name: 'Turks & Caicos Islands', code: 'tc' },
    { name: 'Chad', code: 'td' },
    { name: 'French Southern Territories', code: 'tf' },
    { name: 'Togo', code: 'tg' },
    { name: 'Thailand', code: 'th' },
    { name: 'Tajikistan', code: 'tj' },
    { name: 'Tokelau', code: 'tk' },
    { name: 'East Timor', code: 'tl' },
    { name: 'Turkmenistan', code: 'tm' },
    { name: 'Tunisia', code: 'tn' },
    { name: 'Tonga', code: 'to' },
    { name: 'Turkey', code: 'tr' },
    { name: 'Trinidad and Tobago', code: 'tt' },
    { name: 'Tuvalu', code: 'tv' },
    { name: 'Taiwan', code: 'tw' },
    { name: 'Tanzania', code: 'tz' },
    { name: 'Ukraine', code: 'ua' },
    { name: 'Uganda', code: 'ug' },
    { name: 'United States Minor Outlying Islands', code: 'um' },
    { name: 'United States', code: 'us' },
    { name: 'Uruguay', code: 'uy' },
    { name: 'Uzbekistan', code: 'uz' },
    { name: 'Vatican City', code: 'va' },
    { name: 'Saint Vincent & Grenadines', code: 'vc' },
    { name: 'Venezuela', code: 've' },
    { name: 'UK Virgin Islands', code: 'vg' },
    { name: 'US Virgin Islands', code: 'vi' },
    { name: 'Vietnam', code: 'vn' },
    { name: 'Vanuatu', code: 'vu' },
    { name: 'Wallis and Futuna', code: 'wf' },
    { name: 'Samoa', code: 'ws' },
    { name: 'Yemen', code: 'ye' },
    { name: 'Mayotte', code: 'yt' },
    { name: 'South Africa', code: 'za' },
    { name: 'Zambia', code: 'zm' },
    { name: 'Zimbabwe', code: 'zw' },
    { name: 'Kosovo', code: 'xk' },
    { name: 'Northern Cyprus', code: 'xy' },
    { name: 'Crimea', code: 'xc' }
];