import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SearchHotels from '../components/SearchHotels';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getAllHotelExperiences, getAllProviders, getGymFactorByHotelIdAdmin, getGymFactorDefinitions, searchPageGetHotels, urlShortener } from '../redux.js/api';
import { blobStorageUrl, HasHotelGym, useOutsideClick } from '../components/HelperComponent';
import ToastNotification from '../components/ToastNotification';
import { GymImageGalleryModal } from '../components/GymImageGalleryModal';
import {
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import EditorPageProviders from '../components/EditorPageProviders';
import EditorPageGymFactorScore from '../components/EditorPageGymFactorScore';
import EditorPageHotelInformation from '../components/EditorPageHotelInformation';
import EditorPageGymFactorCalculator from '../components/EditorPageGymFactorCalculator';
import EditorPageTravelerExperiences from '../components/EditorPageTravelerExperiences';
import EditorPageExternalGymCooperation from '../components/EditorPageExternalGymCooperation';
import html2canvas from 'html2canvas';
import { gymDetailsPropertyIcons, gymDetailsPropertyNames, imageSizes } from '../components/constants';
import ScoreHelper from '../components/ScoreHelper';
import GymFactorScoreBox from '../components/GymFactorScoreBox';

const EditorPage = () => {
    const { id } = useParams();
    const [hotels, setHotels] = useState([])
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [hotelUrl, setHotelUrl] = useState(null);
    const [copyUrl, setCopyUrl] = useState(false);
    // const [selectedHotelLoading, setSelectedHotelLoading] = useState(false);
    const [hotelGymFactorLabel, setHotelGymFactorLabel] = useState([]);
    const [hotelReviews, setHotelReviews] = useState([]);
    const [showImageGallery, setShowImageGallery] = useState(false);
    const [allProviders, setAllProviders] = useState(null);
    const [triggerGymFactorScoreSectionFunctions, setTriggerGymFactorScoreSectionFunctions] = useState(false);
    const [allGymFactorDefinitions, setAllGymFactorDefinitions] = useState(null);

    const onSearchCompleted = ({ completedHotels }) => {
        setSelectedHotel(null);

        if (completedHotels && completedHotels.length > 0) {
            setHotels([])
        }
        setTimeout(() => {
            setHotels(completedHotels)
        }, 1);
    }

    useEffect(() => {
        const fetchHotelData = async () => {
            const bookingHotelId = id;
            const data = {
                value: bookingHotelId,
                type: 1,
                countryCode: 'ir',
                hotelFacilities: '',
                stars: 0,
                reviewScore: '',
                propertyTypes: '',
                orderBy: 1,
                orderDirection: 2,
                gymFactorScore: 0,
                cursor: null,
                size: 10
            }
            try {
                const { data: { result } } = await searchPageGetHotels(data)
                onSearchCompleted({ completedHotels: result.items })
            } catch (error) {
                console.log(error)
            }
        }

        const fetchData = async () => {
            const { data: { result } } = await getAllProviders();
            setAllProviders(result)
        }

        const fetchGymFactorDefinitions = async () => {
            const { data: { result } } = await getGymFactorDefinitions();
            setAllGymFactorDefinitions(result.items)
        }

        if (id) fetchHotelData()
        fetchData()
        fetchGymFactorDefinitions()
    }, [])

    useEffect(() => {
        if (id && hotels.length > 0) selectHotel(hotels[0])
    }, [hotels])

    useEffect(() => {
        if (selectedHotel === null) {
            setHotelUrl(null)
            setCopyUrl(false)
        }
    }, [selectedHotel])

    const selectHotel = async (selectedHotelValue) => {
        // setSelectedHotelLoading(true)

        const hotel = hotels.find(x => x.bookingHotelId === selectedHotelValue.bookingHotelId);
        if (hotel?.hotelId === null || hotel?.hotelId === undefined) {
            ToastNotification('error', `Oops! This hotel is missing in our DB. Hotel Name: ${hotel.hotelName}. BookingId: ${hotel.bookingHotelId}`)
            return
        }

        const hotelGymFactor = await getGymFactorByHotelIdAdmin(hotel.hotelId)
        const reviews = hotel.hotelId !== null && await getAllHotelExperiences(hotel.hotelId)

        setSelectedHotel(selectedHotelValue)
        setHotelGymFactorLabel(hotelGymFactor?.data?.result)
        setHotelReviews(reviews?.data?.result?.items)

        // setSelectedHotelLoading(false)
    }

    const getHotelDetailUrl = async (selectedHotel) => {
        const url = `${process.env.REACT_APP_CLIENT_BASE_URL}/hotels/${selectedHotel.bookingHotelId}`
        const inputData = {
            url,
            bookingHotelId: selectedHotel.bookingHotelId,
            domain: process.env.REACT_APP_SWITCHY_DOMAIN
        }
        const detailPageUrl = await urlShortener(inputData);
        setHotelUrl(detailPageUrl.data.result)
    }

    const copyToClipboard = (url) => {
        let textarea = document.createElement("textarea");
        textarea.textContent = url;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy")
        setCopyUrl(true)
    }

    let allGymImages = []
    hotelGymFactorLabel && hotelGymFactorLabel.images?.length > 0 && hotelGymFactorLabel.images.map((x, index) => {
        allGymImages.push({ original: blobStorageUrl('original', x.filename), thumbnail: blobStorageUrl('original', x.filename) })
    })

    const handleShowImageGallery = () => {
        setShowImageGallery(!showImageGallery)
    }

    const changeTriggerGymFactorScoreSectionFunctions = () => {
        setTriggerGymFactorScoreSectionFunctions(!triggerGymFactorScoreSectionFunctions)
    }

    const onReviewActionCompleted = async () => {
        const hotel = hotels.find(x => x.bookingHotelId === selectedHotel.bookingHotelId);
        const reviews = hotel.hotelId !== null && await getAllHotelExperiences(hotel.hotelId);
        setHotelReviews(reviews.data.result.items)
    }

    const onHandleSaveExperience = (result) => {
        setHotelReviews(result)
    }

    const onHandleUpdateExperience = (result, reviewType, editedExperienceIndex) => {
        if (reviewType === "addReview") {
            setHotelReviews([result, ...hotelReviews])
        }
        else {
            setHotelReviews([
                ...hotelReviews.slice(0, editedExperienceIndex),
                result,
                ...hotelReviews.slice(editedExperienceIndex + 1),
            ])
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const [hotelCardVisible, setHotelCardVisible] = useState(false);
    const downloadAsImage = () => {
        setHotelCardVisible(true);
    };

    useEffect(() => {
        if (hotelCardVisible) {
            const hotelName = selectedHotel.name;
            const formattedHotelName = hotelName ? hotelName.toLowerCase().replace(/\s+/g, '-') : 'hotel-card';

            const element = document.getElementById('hotel-card-to-download');
            html2canvas(element, {
                scale: 2, // Adjust scale for higher resolution
                width: element.offsetWidth,
                height: element.offsetHeight,
                useCORS: true, // Ensures external images with CORS headers are captured
            }).then((canvas) => {
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/jpeg', 1.0);
                link.download = `${formattedHotelName}.jpg`;
                link.click();
            }).finally(() => {
                setHotelCardVisible(false);
            });
        }
    }, [hotelCardVisible]);

    return (
        <div className='flex w-full'>
            <div className='flex w-full flex-col gap-[30px]'>
                <div className='flex w-full xl:w-[700px]'>
                    <SearchHotels onSearchCompleted={onSearchCompleted} />
                </div>
                {selectedHotel === null && hotels.length > 0 &&
                    <div className='flex w-full xl:w-[700px]'>
                        <DataTable
                            className='w-full'
                            value={hotels}
                            // loading={selectedHotelLoading}
                            selectionMode="single"
                            selection={selectedHotel}
                            onSelectionChange={(e) => selectHotel(e.value)}
                            dataKey="id"
                            metaKeySelection={true}
                        >
                            <Column field="name" header="Hotel Name" style={{ width: '90%' }} className='text-[15px] font-bold border-b text-color_black_light'></Column>
                            <Column field="gymFactorScore" header="GymFactor" style={{ width: '10%' }} className='text-[15px] border-b text-color_black_light'></Column>
                        </DataTable>
                    </div>
                }
                {selectedHotel && selectedHotel !== null &&
                    <div className='flex flex-col gap-[20px]'>
                        <div className='flex w-full'>
                            <div className='flex w-full items-center gap-[20px]'>
                                <span className='text-[24px] font-bold'>{selectedHotel.name}</span>
                                <div className='flex cursor-pointer' onClick={() => getHotelDetailUrl(selectedHotel)}>
                                    <ShareMenuDropdown hotelUrl={hotelUrl} copyToClipboard={copyToClipboard} copyUrl={copyUrl} setCopyUrl={setCopyUrl} />
                                </div>
                                {allGymImages.length > 0 &&
                                    <div className='flex cursor-pointer' onClick={handleShowImageGallery}>
                                        <div className='flex items-center justify-center bg-color_background_main h-[40px] w-[40px] rounded-[8px]'>
                                            <img
                                                className='w-[26px] h-[26px]'
                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/gym-images-icon.svg`}
                                                alt="Images icon | HotelGyms.com"
                                                width='26'
                                                height='26'
                                            />
                                        </div>
                                    </div>
                                }
                                <div onClick={downloadAsImage} className='flex cursor-pointer'>
                                    <div className='flex items-center justify-center bg-color_background_main h-[40px] w-[40px] rounded-[8px]'>
                                        <img
                                            className='w-[13px] h-[16px]'
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/download-icon.svg`}
                                            alt="Download icon | HotelGyms.com"
                                            width='13'
                                            height='16'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {hotelCardVisible &&
                            <div className='flex w-full'>
                                <HotelCard hotel={selectedHotel} allGymFactorDefinitions={allGymFactorDefinitions} />
                            </div>
                        }
                        <div className='flex w-full'>
                            <UncontrolledAccordion defaultOpen="2">
                                <AccordionItem>
                                    <AccordionHeader targetId="1" onClick={scrollToTop}>
                                        HOTEL OVERVIEW
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <div className='flex w-full'>
                                            <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Providers</span>
                                        </div>
                                        <EditorPageProviders
                                            selectedHotel={selectedHotel}
                                        />
                                        <EditorPageHotelInformation
                                            selectedHotel={selectedHotel}
                                        />
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="2" onClick={scrollToTop}>
                                        GYMFACTOR SCORE
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                        <EditorPageGymFactorScore
                                            selectedHotel={selectedHotel}
                                            allProviders={allProviders}
                                            changeTriggerGymFactorScoreSectionFunctions={changeTriggerGymFactorScoreSectionFunctions}
                                            triggerGymFactorScoreSectionFunctions={triggerGymFactorScoreSectionFunctions}
                                            gymFactorDefinitions={allGymFactorDefinitions}
                                        />
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="3" onClick={scrollToTop}>
                                        GYMFACTOR CALCULATOR
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <EditorPageGymFactorCalculator
                                            selectedHotel={selectedHotel}
                                            allProviders={allProviders}
                                            changeTriggerGymFactorScoreSectionFunctions={changeTriggerGymFactorScoreSectionFunctions}
                                        />
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    {hotelReviews && hotelReviews.length > 0 ?
                                        <AccordionHeader targetId="4">
                                            {`TRAVELER EXPERIENCE (${hotelReviews.length})`}
                                        </AccordionHeader>
                                        :
                                        <AccordionHeader targetId="4" onClick={scrollToTop}>
                                            NO TRAVELER EXPERIENCE
                                        </AccordionHeader>
                                    }
                                    {hotelReviews && hotelReviews.length > 0 ?
                                        <AccordionBody accordionId="4">
                                            <EditorPageTravelerExperiences
                                                hotelReviews={hotelReviews}
                                                onReviewActionCompleted={onReviewActionCompleted}
                                                hotel={selectedHotel}
                                                onHandleSaveExperience={onHandleSaveExperience}
                                                onHandleUpdateExperience={onHandleUpdateExperience}
                                                allProviders={allProviders}
                                            />
                                        </AccordionBody>
                                        :
                                        <AccordionBody accordionId="4"></AccordionBody>
                                    }
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="5" onClick={scrollToTop}>
                                        EXTERNAL GYM COOPERATION
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5">
                                        <EditorPageExternalGymCooperation
                                            hotel={selectedHotel}
                                        />
                                    </AccordionBody>
                                </AccordionItem>
                            </UncontrolledAccordion>
                        </div>
                    </div>
                }
            </div>
            {showImageGallery &&
                <GymImageGalleryModal
                    gymPhotos={allGymImages}
                    handleShowImageGallery={handleShowImageGallery}
                />
            }
        </div>
    );
};

export default EditorPage;

const ShareMenuDropdown = ({ hotelUrl, copyToClipboard, copyUrl, setCopyUrl }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Outside Click
    const dropdownRef = useRef(null);

    const closePickers = () => {
        setIsOpen(false);
        setCopyUrl(false);
    };

    useOutsideClick([dropdownRef], closePickers);
    // End Outside Click

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <div onClick={toggleDropdown} className='w-full flex justify-end'>
                <div className='flex items-center justify-center bg-color_background_main h-[40px] w-[40px] rounded-[8px]'>
                    <img
                        className='w-[13px] h-[16px]'
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/share-icon.svg`}
                        alt="Share icon | HotelGyms.com"
                        width='13'
                        height='16'
                    />
                </div>
            </div>

            {isOpen && (
                <div className="z-[999] origin-top-right absolute right-0 mt-0 w-[150px] rounded-[15px] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1 m-[10px]">
                        <div className='flex flex-col'>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                            >
                                <a className='text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 no-underline' href={hotelUrl} target='_blank' rel="noreferrer">Go to Page</a>
                            </button>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                                onClick={() => !copyUrl && copyToClipboard(hotelUrl)}
                            >
                                {
                                    !copyUrl ?
                                        'Copy URL'
                                        :
                                        'Copied'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const HotelCard = ({ hotel, allGymFactorDefinitions }) => {
    const starArray = hotel ? Array.from({ length: hotel.stars }, (_, index) => index + 1) : 0;

    let gymDetailsArray = (hotel?.gymDetails && hotel?.gymDetails != null) ? hotel?.gymDetails.filter(item => item.value === true) : null;
    if (gymDetailsArray != null && gymDetailsArray.length > 5) gymDetailsArray = gymDetailsArray.slice(0, 5);

    let gymDetailsArrayMobile = gymDetailsArray;
    if (gymDetailsArrayMobile != null && gymDetailsArrayMobile.length > 3) gymDetailsArrayMobile = gymDetailsArrayMobile.slice(0, 3);

    const hotelImgSizes = imageSizes.searchList;
    const bookingMainImgSizeMobile = `${hotelImgSizes.galleryPreview.mobile.main.width}x${hotelImgSizes.galleryPreview.mobile.main.height}`
    const bookingMainImgSizeDesktop = `${hotelImgSizes.galleryPreview.desktop.main.width}x${hotelImgSizes.galleryPreview.desktop.main.height}`

    const cloudImageToken = 'https://cdn-imgs.hotelgyms.com/';
    const cloudImageMainMobile = `?w=${hotelImgSizes.galleryPreview.mobile.main.width}&h=${hotelImgSizes.galleryPreview.mobile.main.height}&p=main`;
    const cloudImageMainDesktop = `?w=${hotelImgSizes.galleryPreview.desktop.main.width}&h=${hotelImgSizes.galleryPreview.desktop.main.height}&p=main`;

    const handleMainImageError = (e, cloudImageMainSetup) => {
        e.target.src = e.target.src.replace(cloudImageToken, '')
        e.target.src = e.target.src.replace(cloudImageMainSetup, '')
    }


    const hasHotelGym = HasHotelGym(hotel.hasFitnessFacility, hotel.gymFactorScore);
    let gymFactorObject = {}
    const roundedRating = hasHotelGym ? ((hotel.gymFactorScore === 0 || hotel.gymFactorScore === null) ? -1 : Math.round(hotel.gymFactorScore * 2) / 2) : 0;
    if (allGymFactorDefinitions && allGymFactorDefinitions != null && allGymFactorDefinitions.length > 0 && allGymFactorDefinitions.find(data => data.gymFactorScore === roundedRating)) gymFactorObject = allGymFactorDefinitions.find(data => data.gymFactorScore === roundedRating)
    else gymFactorObject = allGymFactorDefinitions && allGymFactorDefinitions !== null && allGymFactorDefinitions.length > 0 && allGymFactorDefinitions.find(data => data.gymFactorScore === -1)

    return (
        <div className='flex flex-col md:w-[859px] bg-color_white p-[12px] md:p-[28px] rounded-[10px] shadow-[0_2px_8px_-1px_rgba(0,0,0,0.12)] relative md:static pb-[15px] md:pb-[28px] mb-[10px] md:mb-[14px]' id='hotel-card-to-download'>
            <div className='flex flex-row gap-[16px] md:gap-[30px]'>
                <div id='search-results_gallery' className='flex w-[132px] md:w-[270px] h-[110px] md:h-[235px] min-w-[132px] md:min-w-[270px] min-h-[110px] md:min-h-[235px] rounded-[8px] cursor-pointer'>
                    <div className='w-[132px] md:w-[270px] h-[110px] md:h-[235px] min-w-[132px] md:min-w-[270px] min-h-[110px] md:min-h-[235px]'>
                        <img
                            src={hotel.image && hotel.image.includes("max500") ? hotel.image.replace("max500", bookingMainImgSizeMobile) : hotel.image && hotel.image.includes("max300") && hotel.image.replace("max300", bookingMainImgSizeMobile)}
                            alt="Hotel Image | HotelGyms.com"
                            object-fit='cover'
                            height='110'
                            width='132'
                            className='block md:hidden rounded-[8px]'
                            onError={(e) => handleMainImageError(e, cloudImageMainMobile)}
                        />
                        <img
                            src={hotel.image && hotel.image.includes("max500") ? hotel.image.replace("max500", bookingMainImgSizeDesktop) : hotel.image && hotel.image.includes("max300") && hotel.image.replace("max300", bookingMainImgSizeDesktop)}
                            alt="Hotel Image | HotelGyms.com"
                            object-fit='cover'
                            height='235'
                            width='270'
                            className='hidden md:block rounded-[8px]'
                            onError={(e) => handleMainImageError(e, cloudImageMainDesktop)}
                        />
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col w-full'>
                        <h2 className='text-[14px] md:text-[20px] font-bold text-color_black_dark pb-[4px] md:pb-[8px] mt-[-6px] md:mt-[-10px]'>{hotel.name}</h2>
                        <div className='flex gap-[3px]'>
                            {
                                starArray.map((star) => (
                                    <img
                                        key={star}
                                        className='w-[10px] h-[10px]'
                                        src={`${process.env.REACT_APP_STORAGE_BASE_URL}/images/app/filters-staryellow-icon.svg`}
                                        alt='Star | HotelGyms.com'
                                        width='10'
                                        height='10'
                                    />
                                ))
                            }
                        </div>
                        <div className='flex'>
                            <span className='text-[13px] font-regular text-color_black_light opacity-70'>{hotel.location.address}, {hotel.location.cityName}</span>
                        </div>
                    </div>
                    <div className='flex w-full mt-[14px] md:mt-auto'>
                        <div className='flex flex-col w-full'>
                            <div className='hidden md:flex mt-auto'>
                                {hotel.isGymFactorOverruled ?
                                    <ScoreHelper
                                        score={100}
                                        gymFactorScore={hotel?.gymFactorScore}
                                        size='medium'
                                        allGymFactorDefinitions={allGymFactorDefinitions}
                                    />
                                    :
                                    <GymFactorScoreBox
                                        gymFactorScore={hotel?.gymFactorScore}
                                        hasHotelGym={hasHotelGym}
                                        gymFactorObject={gymFactorObject}
                                    />
                                }
                            </div>
                            <div className='flex md:hidden'>
                                {hotel.isGymFactorOverruled ?
                                    <ScoreHelper
                                        score={100}
                                        gymFactorScore={hotel?.gymFactorScore}
                                        size='small'
                                        allGymFactorDefinitions={allGymFactorDefinitions}
                                    />
                                    :
                                    <GymFactorScoreBox
                                        gymFactorScore={hotel?.gymFactorScore}
                                        size='small'
                                        hasHotelGym={hasHotelGym}
                                        gymFactorObject={gymFactorObject}
                                    />
                                }
                            </div>
                            {gymDetailsArray && gymDetailsArray != null &&
                                <div className='hidden md:flex mt-[30px] gap-[15px]'>
                                    <div className='hidden xl:flex gap-[15px]'>
                                        {gymDetailsArray.map((x, index) => {
                                            return (
                                                <div key={index} className='flex items-center justify-center bg-color_gym_details_bg p-[6px] rounded-[6px]'>
                                                    <img
                                                        className='w-[20px] h-[20px]'
                                                        src={`${process.env.REACT_APP_STORAGE_BASE_URL}/images/app/${gymDetailsPropertyIcons[x.id]}`}
                                                        alt={`${gymDetailsPropertyNames[x.id]} icon | HotelGyms.com`}
                                                        width='20'
                                                        height='20'
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='flex xl:hidden gap-[15px]'>
                                        {gymDetailsArrayMobile.map((x, index) => {
                                            return (
                                                <div key={index} className='flex items-center justify-center bg-color_gym_details_bg p-[6px] rounded-[6px]'>
                                                    <img
                                                        className='w-[20px] h-[20px]'
                                                        src={`${process.env.REACT_APP_STORAGE_BASE_URL}/images/app/${gymDetailsPropertyIcons[x.id]}`}
                                                        alt={`${gymDetailsPropertyNames[x.id]} icon | HotelGyms.com`}
                                                        width='20'
                                                        height='20'
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='flex items-center justify-center bg-color_gym_details_bg p-[6px] rounded-[6px] cursor-pointer hover:shadow-[0_2px_8px_-1px_rgba(0,0,0,0.12)] min-w-[32px] min-h-[32px]'>
                                        <img
                                            className='rotate-anchor-90reverse'
                                            src={`${process.env.REACT_APP_STORAGE_BASE_URL}/images/app/searchbar-chevron-down.svg`}
                                            alt='Show More icon | HotelGyms.com'
                                            width='15'
                                            height='10'
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}