import './App.css';
import { Router } from './Router';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function App() {
  const { login, isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    !isAuthenticated ?
      <div
        className='flex flex-col items-center justify-center h-screen text-white'>
        <img
          src="https://cdn-data.hotelgyms.com/images/app/hotelgyms-logo-black.svg"
          className='mb-[35px] w-[240px] h-[52px]'
          alt="Hotelgyms logo | Hotelgyms.com"
        />
        <button className="text-[13px] border-0 bg-color_black font-bold cursor-pointer px-[14px] py-[10px] rounded-[10px]" onClick={login}>
          Sign in
        </button>
      </div>
      :
      <div className="App">
        <header className="App-header">
          <Router />
        </header>
      </div>
  );
}

export default App;
