import React, { useEffect, useState } from 'react';
import ToastNotification from './ToastNotification';
import LoadingDots from './LoadingDots';
import { getHotelInformation, updateHotelInformation, triggerAISummary } from '../redux.js/api';
import { InputTextarea } from 'primereact/inputtextarea';

const EditorPageHotelInformation = ({ selectedHotel }) => {
    const initialHotelInfoData = {
        id: selectedHotel.hotelId,
        website: null,
        instagram: null,
        twitter: null,
        facebook: null,
        yearBuild: null,
        lastRenovation: null,
        totalRooms: null,
        summary: null
    };
    const [hotelInfoData, setHotelInfoData] = useState(initialHotelInfoData);
    const [summaryStatusToCheck, setSummaryStatusToCheck] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [loadingAISummary, setLoadingAISummary] = useState(false);

    const onInputChange = (e, name, type) => {
        if ((name === 'instagram' || name === 'twitter') && e.target.value && e.target.value.includes('@')) {
            e.target.value = e.target.value.replace('@', '');
        }
        setHotelInfoData({ ...hotelInfoData, [name]: e.target.value })
    }

    useEffect(() => {
        const fetchData = async () => {
            if (selectedHotel.hotelId) {
                try {
                    const { data: { result } } = await getHotelInformation(selectedHotel.hotelId);
                    setSummaryStatusToCheck(result.summary);
                    setHotelInfoData({
                        id: selectedHotel.hotelId,
                        website: result.website,
                        instagram: result.instagram,
                        twitter: result.twitter,
                        facebook: result.facebook,
                        yearBuild: result.yearBuild,
                        lastRenovation: result.lastRenovation,
                        totalRooms: result.totalRooms,
                        summary: result.summary ?? ''
                    });
                } catch (error) {
                    ToastNotification('error', 'Oops! Cannot fetch Hotel Information, please try again later.')
                }
            }
            else {
                setHotelInfoData(initialHotelInfoData)
            }
        }

        fetchData();
    }, [selectedHotel.hotelId])

    const saveHotelChanges = async () => {
        setLoading(true);
        if (hotelInfoData.website && hotelInfoData.website !== null && hotelInfoData.website !== "" && hotelInfoData.website.includes('http://')) {
            hotelInfoData.website = hotelInfoData.website.replace('http', 'https');
        }
        if (hotelInfoData.website && hotelInfoData.website !== null && hotelInfoData.website !== "" && !hotelInfoData.website.includes('https://')) {
            hotelInfoData.website = `https://${hotelInfoData.website}`;
        }
        try {
            await updateHotelInformation(hotelInfoData);
            setSummaryStatusToCheck(hotelInfoData.summary);
            ToastNotification('success', 'Hotel Information updated successfully.');
            setLoading(false);
        } catch (error) {
            ToastNotification('error', 'Oops! Cannot update Hotel Information, please try again later.')
            setLoading(false);
        }
    }

    const handleAISummary = async () => {
        setLoadingAISummary(true);
        try {
            const { data: { result } } = await triggerAISummary({ hotelId: selectedHotel.hotelId });
            setHotelInfoData({ ...hotelInfoData, summary: result });
            setSummaryStatusToCheck(result);
            ToastNotification('success', 'AI Summary triggered successfully.');
            setLoadingAISummary(false);
        } catch (error) {
            ToastNotification('error', 'Oops! Cannot trigger AI Summary, please try again later.')
            setLoadingAISummary(false);
        }
    }

    return (
        <div className='flex flex-col mt-[30px]'>
            <div className='flex w-full'>
                <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Hotel</span>
            </div>
            <div className='flex flex-col mt-[20px] gap-[20px]'>
                <div className='flex flex-wrap gap-[20px]'>
                    <label className="block w-full md:w-[400px]">
                        <span className="text-[15px]">Address</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="address"
                                value={selectedHotel?.location.address}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='Address'
                                disabled
                            />
                        </div>
                    </label>
                    <label className="block w-full sm:w-[200px]">
                        <span className="text-[15px]">City</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="city"
                                value={selectedHotel?.location.cityName}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='City'
                                disabled
                            />
                        </div>
                    </label>
                    <label className="block w-[100px]">
                        <span className="text-[15px]">Postal Code</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="zip"
                                value={selectedHotel?.zip}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='Postal Code'
                                disabled
                            />
                        </div>
                    </label>
                    <label className="block w-[100px]">
                        <span className="text-[15px]">Country</span>
                        <div className='flex w-full relative'>
                            <input
                                type="text"
                                name="country"
                                value={selectedHotel?.location.country}
                                className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                placeholder='Country'
                                disabled
                            />
                        </div>
                    </label>
                </div>
                <div className='flex'>
                    <div className='flex w-full md:w-[620px] items-center gap-[15px]'>
                        <label className="block w-full">
                            <span className="text-[15px]">Website</span>
                            <div className='flex w-full relative'>
                                <input
                                    type="url"
                                    name="website"
                                    value={hotelInfoData['website']}
                                    onChange={(e) => onInputChange(e, 'website', 'url')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. www.hotelgyms.com'
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-[20px] flex-wrap'>
                    <div className='flex w-full md:w-[350px]'>
                        <label className="block w-full">
                            <div className='flex flex-col'>
                                <span className="text-[15px]">Instagram Username</span>
                                <span className='text-[13px] italic text-color_text_gray_light'>
                                    NOTE: You don't need to add @ for Instagram account
                                </span>
                            </div>
                            <div className='flex w-full relative'>
                                <input
                                    type="text"
                                    name="instagram"
                                    value={hotelInfoData['instagram']}
                                    onChange={(e) => onInputChange(e, 'instagram', 'text')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. hotelgyms_com'
                                />
                            </div>
                        </label>
                    </div>
                    <div className='flex w-full md:w-[350px]'>
                        <label className="block w-full">
                            <div className='flex flex-col'>
                                <span className="text-[15px]">X Username</span>
                                <span className='text-[13px] italic text-color_text_gray_light'>
                                    NOTE: You don't need to add @ for X account
                                </span>
                            </div>
                            <div className='flex w-full relative'>
                                <input
                                    type="text"
                                    name="twitter"
                                    value={hotelInfoData['twitter']}
                                    onChange={(e) => onInputChange(e, 'twitter', 'text')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. hotelgyms_com'
                                />
                            </div>
                        </label>
                    </div>
                    <div className='flex w-full md:w-[350px]'>
                        <label className="block w-full">
                            <div className='flex flex-col'>
                                <span className="text-[15px] xl:mb-[19.5px]">Facebook Page</span>
                            </div>
                            <div className='flex w-full relative'>
                                <input
                                    type="text"
                                    name="facebook"
                                    value={hotelInfoData['facebook']}
                                    onChange={(e) => onInputChange(e, 'facebook', 'text')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. https://www.facebook.com/hotelgyms'
                                />
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div className='flex w-full mt-[30px]'>
                <span className='w-full text-[18px] font-bold border-b border-b-[2px]'>Information</span>
            </div>
            <div className='flex flex-col mt-[20px] gap-[20px]'>
                <div className='flex flex-col md:flex-row gap-[20px] flex-wrap'>
                    <div className='flex w-full md:w-[350px]'>
                        <label className="block w-full">
                            <span className="text-[15px]">Year Build</span>
                            <div className='flex w-full relative'>
                                <input
                                    type="text"
                                    name="yearBuild"
                                    value={hotelInfoData['yearBuild']}
                                    onChange={(e) => onInputChange(e, 'yearBuild', 'text')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. 2002'
                                />
                            </div>
                        </label>
                    </div>
                    <div className='flex w-full md:w-[350px]'>
                        <label className="block w-full">
                            <span className="text-[15px]">Last Renovation</span>
                            <div className='flex w-full relative'>
                                <input
                                    type="text"
                                    name="lastRenovation"
                                    value={hotelInfoData['lastRenovation']}
                                    onChange={(e) => onInputChange(e, 'lastRenovation', 'text')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. 2016'
                                />
                            </div>
                        </label>
                    </div>
                    <div className='flex w-full md:w-[350px]'>
                        <label className="block w-full">
                            <span className="text-[15px]">Total Rooms</span>
                            <div className='flex w-full relative'>
                                <input
                                    type="text"
                                    name="totalRooms"
                                    value={hotelInfoData['totalRooms']}
                                    onChange={(e) => onInputChange(e, 'totalRooms', 'text')}
                                    className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                                    placeholder='e.g. 877'
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className='flex w-full'>
                    <label className="block w-full">
                        <div className='flex'>
                            <span className="text-[15px]">Hotel and GymFactor Summary</span>
                            <span className="text-[15px] ml-auto">Character count: {hotelInfoData?.summary?.length}</span>
                        </div>
                        <div className='flex w-full relative'>
                            <InputTextarea
                                type="textarea"
                                name="summary"
                                value={hotelInfoData['summary']}
                                onChange={(e) => onInputChange(e, 'summary', 'textarea')}
                                placeholder='AI generated Hotel and Hotel Gym summary'
                                rows={3}
                                cols={30}
                                className='primeDropdownStyle text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full'
                            />
                        </div>
                    </label>
                </div>
            </div>
            <div className='flex border-t mt-[30px] pt-[25px] pb-[9px] mx-[-16px]'>
                <button
                    onClick={() => saveHotelChanges()}
                    type="submit"
                    disabled={loading}
                    className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black ml-[16px]`}>
                    SAVE {loading && <LoadingDots customStyles={'bg-white'} dotsNumber={1} />}
                </button>
                <button
                    onClick={() => handleAISummary()}
                    type="submit"
                    disabled={
                        loadingAISummary || (summaryStatusToCheck !== null && summaryStatusToCheck !== '')
                    }
                    className={`h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white bg-black ml-[16px] ${(summaryStatusToCheck !== null && summaryStatusToCheck !== '')
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                >
                    AI SUMMARY {loadingAISummary && <LoadingDots customStyles={'bg-white'} dotsNumber={1} />}
                </button>
            </div>
        </div>
    );
};

export default EditorPageHotelInformation;