import React, { useState } from "react";
import PropTypes from "prop-types";
import { TooltipWrapper } from "./styles";

const Tooltip = ({ content, direction, delay, alwaysVisible, children }) => {
  const [active, setActive] = useState(alwaysVisible);

  let timeout;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper
      className={"tooltipWrapper"}
      onMouseEnter={!alwaysVisible ? showTip : undefined}
      onMouseLeave={!alwaysVisible ? hideTip : undefined}
    >
      {children}
      {active && (
        <div className={`tooltip-tip ${direction}`}>{content}</div>
      )}
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  direction: PropTypes.string,
  delay: PropTypes.number,
  alwaysVisible: PropTypes.bool,
};

Tooltip.defaultProps = {
  direction: "bottom",
  delay: 200,
  alwaysVisible: false,
};

export default Tooltip;
