import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { editImageProvider } from '../redux.js/api';
import { Dropdown } from 'primereact/dropdown';
import ToastNotification from './ToastNotification';

export default function ImageProviderModal({
    handleClose,
    image,
    onActionCompleted,
    allProviders
}) {
    const [provider, setProvider] = useState(false)
    const [providers, setProviders] = useState([])

    useEffect(() => {
        const providers = allProviders && allProviders.filter(x => x.isImageSource === true).map(p => ({ value: p.id, label: p.name }));
        setProviders(providers)
    }, []);

    const handleProviderChange = (e) => {
        setProvider(e.value)
    }

    const handleSave = async () => {
        const data = { imageId: image.id, providerId: provider }
        try {
            const { data: { result } } = await editImageProvider(data)
            result.providerName = providers.find(x => x.value === provider).label
            onActionCompleted({ imageId: image.id, result, forImageProvider: true })
            handleClose()
            ToastNotification('success', 'Image Provider updated successfully!')
        } catch (error) {
            ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    return (
        <Modal
            size='md'
            className='z-[999]'
            isOpen={true}
            toggle={handleClose}
        >
            <ModalHeader toggle={handleClose}>Image Provider</ModalHeader>
            <ModalBody>
                <div>
                    <div className="flex flex-col w-full">
                        <label className='text-[15px] mb-[10px] mt-[20px]'>Provider: </label>
                        {providers?.length > 0 &&
                            <div className="flex items-center justify-content-center h-[44.5px] w-full">
                                <Dropdown
                                    filter
                                    options={providers}
                                    value={!provider ? providers.find(x => x.value === image.providerId).value : provider}
                                    onChange={(e) => handleProviderChange(e)}
                                    optionLabel="label"
                                    placeholder="Select a Provider"
                                    className="rounded-[8px] primeDropdownStyle w-full"
                                />
                            </div>
                        }

                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-3'>
                <button
                    className='text-[15px] font-bold mr-[7px] cursor-pointer bg-white text-color_black_light border-[1px] border-color_black_light rounded-[10px] px-[20px] py-[10px]'
                    onClick={() => handleSave()}
                >
                    SAVE CHANGES
                </button>
                <button
                    className='text-[15px] font-bold cursor-pointer bg-color_black_light text-white border-[1px] border-color_black_light rounded-[10px] px-[20px] py-[10px]'
                    onClick={handleClose}
                >
                    CANCEL
                </button>
            </ModalFooter>
        </Modal>
        // <Modal show={display} onHide={handleClose} centered>
        //     <Modal.Header closeButton>
        //         <Modal.Title>Image Provider</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //         <Form>

        //             <Form.Group>
        //                 <Form.Label>Provider: </Form.Label>
        //                 {providers?.length > 0 && <Select
        //                     options={providers}
        //                     value={!provider ? providers.find(x => x.value === image.providerId) : provider}
        //                     onChange={(value) => setProvider(value)}
        //                 />}
        //             </Form.Group>
        //         </Form>
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <Button variant="secondary" onClick={handleClose}>
        //             Close
        //     </Button>
        //         <Button variant="primary" onClick={() => handleSave()}>
        //             Save Changes
        //     </Button>
        //     </Modal.Footer>
        // </Modal>
    )
}
