import React, { useState } from 'react';
import Checkbox from '../components/Checkbox';
import { Dropdown } from 'primereact/dropdown';
import { countries } from '../components/constants';
import { triggerAutoReviews } from '../redux.js/api';
import ToastNotification from '../components/ToastNotification';
import LoadingDots from '../components/LoadingDots';

const AutoReviewPage = () => {
    const [loading, setLoading] = useState(false);
    const initialAutoReviewData = {
        country: null,
        city: '',
        noLimit: true,
        includeAll: false,
        includeImages: true,
        hotelsOnly: true
    }
    const [autoReviewData, setAutoReviewData] = useState(initialAutoReviewData)

    const handleChangeAutoReviewData = async (e, name, type) => {
        e.preventDefault();
        const value = type !== 'checkbox' ? e.target.value : e.target.checked;
        setAutoReviewData({ ...autoReviewData, [name]: value })
    }

    const runAutoReview = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            country: autoReviewData.country.code,
            city: autoReviewData.city,
            noLimit: autoReviewData.noLimit,
            includeAll: autoReviewData.includeAll,
            includeImages: autoReviewData.includeImages,
            hotelsOnly: autoReviewData.hotelsOnly
        }
        try {
            await triggerAutoReviews(data)
            setLoading(false)
            ToastNotification('success', 'Auto Review Run successfully!')
            setAutoReviewData(initialAutoReviewData)
        } catch (error) {
            setLoading(false)
            ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.code.toUpperCase()} - {option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.code.toUpperCase()} - {option.name}</div>
            </div>
        );
    };

    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-col mb-[30px]'>
                <h3 className='text-black mb-[10px]'>Region Selection</h3>
                <label className="block w-full md:w-[50%] lg:w-[400px] mb-[20px]">
                    <span className="text-[15px]">Country Code</span>
                    <div className='flex w-full relative'>
                        <Dropdown
                            value={autoReviewData.country}
                            onChange={(e) => handleChangeAutoReviewData(e, 'country', 'dropdown')}
                            options={countries}
                            optionLabel="name"
                            placeholder="Select your country"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full text-[15px] mt-1 rounded-[8px] w-full primeDropdownStyle"
                        />
                    </div>
                </label>
                <label className="block w-full md:w-[50%] lg:w-[400px]">
                    <span className="text-[15px]">City Name (Optional)</span>
                    <div className='flex w-full relative'>
                        <input
                            type="text"
                            value={autoReviewData.city}
                            onChange={(e) => handleChangeAutoReviewData(e, 'city', 'text')}
                            className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                            placeholder='Enter your city name'
                        />
                    </div>
                </label>
            </div>
            <div className='flex flex-col mb-[30px]'>
                <h3 className='text-black mb-[10px]'>Parameters</h3>
                <div className='flex flex-col px-[12px] py-[10px] border rounded-[10px] w-full bg-gray-50'>
                    <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                        <Checkbox
                            onInputChange={handleChangeAutoReviewData}
                            name='noLimit'
                            id='noLimit-checkbox'
                            checkboxText='Ignore the limit of 250 hotels.'
                            checkboxChecked={autoReviewData.noLimit}
                        />
                    </div>
                    <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                        <Checkbox
                            onInputChange={handleChangeAutoReviewData}
                            name='includeAll'
                            id='includeAll-checkbox'
                            checkboxText='Include all hotels.'
                            checkboxChecked={autoReviewData.includeAll}
                            disabled={true}
                            className="opacity-50"
                        />
                    </div>
                    <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                        <Checkbox
                            onInputChange={handleChangeAutoReviewData}
                            name='hotelsOnly'
                            id='hotelsOnly-checkbox'
                            checkboxText='Hotels only'
                            checkboxChecked={autoReviewData.hotelsOnly}
                        />
                    </div>
                    <div className='flex w-full'>
                        <Checkbox
                            onInputChange={handleChangeAutoReviewData}
                            name='includeImages'
                            id='includeImages-checkbox'
                            checkboxText='Include images in the process.'
                            checkboxChecked={autoReviewData.includeImages}
                        />
                    </div>
                </div>
            </div>
            <button
                onClick={(e) => runAutoReview(e)}
                type="submit"
                disabled={autoReviewData.country === null || loading}
                className={`w-[70px] h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white ${autoReviewData.country === null ? 'bg-black opacity-50' : 'bg-black'}`}>
                {!loading ? 'RUN' : <LoadingDots customStyles={'bg-white'} dotsNumber={1} />}
            </button>
        </div>
    );
};

export default AutoReviewPage;