import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ImageGallery from "react-image-gallery";
import { blobStorageUrl } from './HelperComponent';

function GymImageGalleryModal({ gymPhotos, handleShowImageGallery, forFlaggedReviewsModal = false, mainImageSrc = false }) {
    // const [errorImage, setErrorImage] = useState("")
    const [gymImages, setGymImages] = useState(gymPhotos)
    const [mainImage, setMainImage] = useState(false)

    useEffect(() => {
        setGymImages(gymPhotos)
        if (mainImageSrc) {
            setMainImage(gymPhotos.findIndex(x => x.original.includes(mainImageSrc)))
        }
    }, [gymPhotos, mainImageSrc])
    const onImageError = (event) => {
        const currentSrc = event.target.currentSrc
        const gymImages1 = gymImages.filter(x => x.original !== currentSrc)
        const fileName = currentSrc.substring(currentSrc.lastIndexOf("/") + 1, currentSrc.length)
        gymImages1.push({
            original: blobStorageUrl('original', fileName),
            thumbnail: blobStorageUrl('original', fileName)
        })
        if (mainImageSrc) {
            setMainImage(gymImages1.findIndex(x => x.original.includes(mainImageSrc)))
        }
        setGymImages(gymImages1)
    }

    return (
        <>
            {!forFlaggedReviewsModal ?
                <>
                    <Modal
                        size='lg'
                        className='z-[999]'
                        isOpen={true}
                        toggle={handleShowImageGallery}
                    >
                        <ModalHeader toggle={handleShowImageGallery}></ModalHeader>
                        <ModalBody>
                            <div>
                                <ImageGallery
                                    items={gymImages}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    onImageError={(event) => onImageError(event)}
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                </>
                :
                <div>
                    <ImageGallery
                        items={gymImages}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        onImageError={(event) => onImageError(event)}
                        showThumbnails={false}
                        startIndex={mainImage ? mainImage : 0}
                    />
                </div>
            }
        </>

    );
};

export { GymImageGalleryModal }