import React, { useState, useEffect } from 'react';
import LoadingDots from '../components/LoadingDots';
import { getStatistics } from '../redux.js/api';
import { formatNumberWithComma } from '../components/formatting';
import AppImage from '../components/AppImage';

const DashboardPage = () => {
    const [loading, setLoading] = useState(false)
    const [statistics, setStatistics] = useState(undefined)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: { result } } = await getStatistics();
                setStatistics(result);
            } catch (error) {
                console.error('Error while fetching data:', error);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <div className='w-full'>
            {loading ?
                <div className='flex items-center justify-center w-full h-full'>
                    <LoadingDots customStyles={'mx-[10px]'} dotsNumber={3} />
                </div>
                :
                <>
                    {statistics && statistics.totalHotels ?
                        <div className='flex flex-col flex-wrap gap-[40px]'>
                            <div className='flex gap-[40px] flex-col md:flex-row'>
                                {statistics.totalHotels ?
                                    <div className='flex flex-col rounded-[30px] p-[15px] shadow h-[250px] min-h-[250px] flex-1'>
                                        <span className='text-[22px]'>Total Hotels</span>
                                        <div className='flex items-center justify-center w-full h-[187px]'>
                                            <AppImage
                                                name='new-about-the-hotel-section-icon.svg'
                                                alt='Total Hotels icon | HotelGyms.com'
                                                width='50'
                                                height='50'
                                                className='mr-[10px]'
                                            />
                                            <span className='text-[32px] font-bold'>{formatNumberWithComma(statistics.totalHotels)}</span>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                                {statistics.totalHotelsWithGym ?
                                    <div className='flex flex-col rounded-[30px] p-[15px] shadow h-[250px] min-h-[250px] flex-1 relative'>
                                        <span className='text-[22px]'>Total Hotels with Gym</span>
                                        <div className='flex items-center justify-center w-full h-[187px]'>
                                            <AppImage
                                                name='new-about-the-location-section-icon.svg'
                                                alt='Total Hotels icon | HotelGyms.com'
                                                width='50'
                                                height='50'
                                                className='mr-[10px]'
                                            />
                                            <span className='text-[32px] font-bold'>{formatNumberWithComma(statistics.totalHotelsWithGym)}</span>
                                        </div>
                                        <ProgressBar
                                            totalHotelsWithGym={statistics.totalHotelsWithGym}
                                            totalGymFactorReviews={statistics.totalGymFactorReviews}
                                            totalIgnoredGymFactorReviews={statistics.totalIgnoredGymFactorReviews}
                                        />
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='flex gap-[40px] flex-col xl:flex-row'>
                                <div className='flex gap-[40px] flex-col md:flex-row flex-1'>
                                    {statistics.totalGymFactorReviews ?
                                        <div className='flex flex-col rounded-[30px] p-[15px] shadow h-[250px] min-h-[250px] flex-1'>
                                            <span className='text-[22px]'>Total Reviews</span>
                                            <div className='flex items-center justify-center w-full h-[187px]'>
                                                <AppImage
                                                    name='new-gymfactor-section-icon.svg'
                                                    alt='Total Hotels icon | HotelGyms.com'
                                                    width='50'
                                                    height='50'
                                                    className='mr-[10px]'
                                                />
                                                <span className='text-[32px] font-bold'>{formatNumberWithComma(statistics.totalGymFactorReviews)}</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {statistics.totalIgnoredGymFactorReviews ?
                                        <div className='flex flex-col rounded-[30px] p-[15px] shadow h-[250px] min-h-[250px] flex-1'>
                                            <span className='text-[22px]'>Total Ignored Reviews</span>
                                            <div className='flex items-center justify-center w-full h-[187px]'>
                                                <AppImage
                                                    name='new-gymfactor-section-gray-icon.svg'
                                                    alt='Total Hotels icon | HotelGyms.com'
                                                    width='50'
                                                    height='50'
                                                    className='mr-[10px]'
                                                />
                                                <span className='text-[32px] font-bold'>{formatNumberWithComma(statistics.totalIgnoredGymFactorReviews)}</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className='flex gap-[40px] flex-col md:flex-row flex-1'>
                                    {statistics.totalGymFactorExperiences ?
                                        <div className='flex flex-col rounded-[30px] p-[15px] shadow h-[250px] min-h-[250px] flex-1'>
                                            <span className='text-[22px]'>Total Experiences</span>
                                            <div className='flex items-center justify-center w-full h-[187px]'>
                                                <AppImage
                                                    name='new-user-reviews-section-icon.svg'
                                                    alt='Total Hotels icon | HotelGyms.com'
                                                    width='50'
                                                    height='50'
                                                    className='mr-[10px]'
                                                />
                                                <span className='text-[32px] font-bold'>{formatNumberWithComma(statistics.totalGymFactorExperiences)}</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {statistics.totalGymFactorImages ?
                                        <div className='flex flex-col rounded-[30px] p-[15px] shadow h-[250px] min-h-[250px] flex-1'>
                                            <span className='text-[22px]'>Total Images</span>
                                            <div className='flex items-center justify-center w-full h-[187px]'>
                                                <AppImage
                                                    name='new-images-icon.svg'
                                                    alt='Total Hotels icon | HotelGyms.com'
                                                    width='38'
                                                    height='38'
                                                    className='mr-[10px]'
                                                />
                                                <span className='text-[32px] font-bold'>{formatNumberWithComma(statistics.totalGymFactorImages)}</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </>
            }
        </div>
    );
};

export default DashboardPage;

export const ProgressBar = ({
    totalHotelsWithGym,
    totalGymFactorReviews,
    totalIgnoredGymFactorReviews
}) => {
    const reviewsPercentage = (totalGymFactorReviews / totalHotelsWithGym) * 100;
    const ignoredPercentage = (totalIgnoredGymFactorReviews / totalHotelsWithGym) * 100;

    return (
        <div className='flex flex-col absolute bottom-[15px] w-full pr-[30px]'>
            <div className="flex w-full bg-gray-200 rounded-full h-[10px] overflow-hidden">
                <div
                    className="bg-color_primary_blue h-[10px] rounded-l-full"
                    style={{ width: `${reviewsPercentage}%` }}
                ></div>
                <div
                    className="bg-color_mustard h-[10px] rounded-r-full"
                    style={{ width: `${ignoredPercentage}%` }}
                ></div>
            </div>
            <div className='flex mt-[10px] gap-[20px]'>
                <div className='flex items-center gap-[10px]'><div className='w-[20px] h-[10px] rounded-full bg-color_primary_blue' /><span className='text-[15px]'>Total Reviews</span></div>
                <div className='flex items-center gap-[10px]'><div className='w-[20px] h-[10px] rounded-full bg-color_mustard' /><span className='text-[15px]'>Total Ignored Reviews</span></div>
            </div>
        </div>
    );
};