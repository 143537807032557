import { useState, useRef } from 'react';
import { useOutsideClick } from './HelperComponent';

export default function UserMenuDropdown({ user, logout }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Outside Click
    const dropdownRef = useRef(null);

    const closePickers = () => {
        setIsOpen(false);
    };

    useOutsideClick([dropdownRef], closePickers);
    // End Outside Click

    return (
        <div ref={dropdownRef} className="relative inline-block text-left mt-[5px]">
            <button onClick={toggleDropdown} className="inline-flex items-center p-0 border-0 bg-transparent">
                {user?.picture ?
                    <img
                        src={user.picture}
                        className='rounded-full hover:opacity-70 transition-opacity duration-300 ease-in-out'
                        width="36"
                        height="36"
                        alt="User Profile | HotelGyms.com"
                    />
                    :
                    <div className='flex items-center justify-center bg-color_primary_blue rounded-full w-[36px] h-[36px]'>
                        <span className='text-white text-[20px]'>{user?.given_name[0]}</span>
                    </div>
                }
            </button>

            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-max rounded-[15px] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1 m-[10px]">
                        <div className="flex px-[12px] py-[12px]">
                            {user?.picture ?
                                <img
                                    src={user.picture}
                                    className='rounded-full'
                                    width="36"
                                    height="36"
                                    alt="User Profile | HotelGyms.com"
                                />
                                :
                                <div className='flex items-center justify-center bg-color_primary_blue rounded-full w-[36px] h-[36px]'>
                                    <span className='text-white text-[20px]'>{user?.given_name[0]}</span>
                                </div>
                            }
                            <div className='flex flex-col ml-[10px]'>
                                <span className="text-[16px] leading-[16px] font-medium text-gray-900 mb-[6px]">{user?.given_name} {user?.family_name}</span>
                                <span className="text-[12px] leading-[13px] text-gray-500">{user?.email}</span>
                            </div>
                        </div>
                        <div className="boder border-solid border-[1px] border-x-0 border-b-0 border-gray-100 mb-[8px] mt-[6px]"></div>
                        <div className='flex flex-col'>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                                onClick={logout}
                            >
                                Sign out
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}