import React, { useState, useEffect } from 'react';
import { getAllProviders, updateProviderKeys } from '../redux.js/api';
import { Dropdown } from 'primereact/dropdown';
import LoadingDots from '../components/LoadingDots';
import ToastNotification from '../components/ToastNotification';

const ProviderDataImport = () => {
    const initialData = {
        url: '',
        originalProvider: null,
        newProvider: null,
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialData)
    const [allProviders, setAllProviders] = useState([])

    useEffect(() => {
        const fetchAllProviders = async () => {
            try {
                const { data: { result } } = await getAllProviders();
                setAllProviders(result)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchAllProviders()
    }, [])

    const handleChangeData = async (e, name, type) => {
        e.preventDefault();
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }

    const runMapping = async (e) => {
        e.preventDefault();
        setLoading(true)
        const finalData = {
            fullPathToCsv: data.url,
            originalProviderId: data.originalProvider.id,
            newProviderId: data.newProvider.id,
        }
        try {
            if (finalData.fullPathToCsv !== '' && finalData.originalProviderId !== null && finalData.newProviderId !== null) {
                await updateProviderKeys(finalData)
                setLoading(false)
                ToastNotification('success', 'Mapping Run successfully!')
                setData(initialData)
            }
            else {
                setLoading(false)
                ToastNotification('error', 'Please fill all the fields.')
            }
        } catch (error) {
            setLoading(false)
            ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const selectOriginalProviderTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.id} - {option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    }
    const originalProviderOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.id} - {option.name}</div>
            </div>
        );
    }

    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-col mb-[30px]'>
                <h3 className='text-black mb-[10px]'>Provider Data Import</h3>
                <label className="block w-full md:w-[50%] lg:w-[500px]">
                    <span className="text-[15px]">Full Path to CSV</span>
                    <div className='flex w-full relative'>
                        <input
                            type="text"
                            value={data.url}
                            onChange={(e) => handleChangeData(e, 'url', 'text')}
                            className={`text-[15px] block mt-1 p-[10px] rounded-[8px] border border-gray-300 w-full`}
                            placeholder='Enter Full Path to CSV (URL)'
                        />
                    </div>
                </label>
                <label className="block w-full md:w-[50%] lg:w-[500px] mt-[20px]">
                    <span className="text-[15px]">Original Provider (Source)</span>
                    <div className='flex w-full relative'>
                        <Dropdown
                            value={data.originalProvider}
                            onChange={(e) => handleChangeData(e, 'originalProvider', 'dropdown')}
                            options={allProviders}
                            optionLabel="name"
                            placeholder="Select an ID"
                            filter
                            valueTemplate={selectOriginalProviderTemplate}
                            itemTemplate={originalProviderOptionTemplate}
                            className="w-full text-[15px] mt-1 rounded-[8px] w-full primeDropdownStyle"
                        />
                    </div>
                </label>
                <label className="block w-full md:w-[50%] lg:w-[500px] mt-[20px]">
                    <span className="text-[15px]">New Provider (Target)</span>
                    <div className='flex w-full relative'>
                        <Dropdown
                            value={data.newProvider}
                            onChange={(e) => handleChangeData(e, 'newProvider', 'dropdown')}
                            options={allProviders}
                            optionLabel="name"
                            placeholder="Select an ID"
                            filter
                            valueTemplate={selectOriginalProviderTemplate}
                            itemTemplate={originalProviderOptionTemplate}
                            className="w-full text-[15px] mt-1 rounded-[8px] w-full primeDropdownStyle"
                        />
                    </div>
                </label>
            </div>
            <button
                onClick={(e) => runMapping(e)}
                type="submit"
                disabled={data.url === '' || data.originalProvider === null || data.newProvider === null || loading}
                className={`w-[70px] h-[44.5px] text-[15px] font-bold py-[10px] px-[20px] rounded-[8px] border-none cursor-pointer text-white ${data.url === '' || data.originalProvider === null || data.newProvider === null ? 'bg-black opacity-50' : 'bg-black'}`}>
                {!loading ? 'RUN' : <LoadingDots customStyles={'bg-white'} dotsNumber={1} />}
            </button>
        </div>
    );
};

export default ProviderDataImport;