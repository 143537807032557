import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { getAllReviewRequestedHotels, removeReviewRequest } from '../redux.js/api';
import { CheckMarkBlack } from '../components/ComponentStyles';
import ToastNotification from '../components/ToastNotification';
import { useOutsideClick } from '../components/HelperComponent';
const RequestsPage = () => {
    const [hotels, setHotels] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getReviewRequestedHotels() {
            try {
                const { data: { result } } = await getAllReviewRequestedHotels();
                setLoading(false);
                setHotels(result)
            } catch (error) {
                setLoading(false);
                console.error("Error while fetching review requested hotels:", error);
            }
        }
        getReviewRequestedHotels();
    }, [])

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        city: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        country: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        hotelId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        autoReview: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex items-center relative">
                <img
                    className='w-[20px] h-[20px] ml-[10px] absolute'
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/searchicon.svg`}
                    alt="Search icon | HotelGyms.com"
                    width='20'
                    height='20'
                />
                <InputText className='flex h-[40px] w-[100%] lg:w-[50%] pl-[40px] border rounded-[10px]' value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </div>
        );
    };
    const header = renderHeader();

    const autoReviewBodyTemplate = (rowData) => {
        const autoReview = rowData.autoReview;
        return (
            <div className='flex items-center justify-center'>
                {autoReview === true ?
                    <CheckMarkBlack viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </CheckMarkBlack>
                    :
                    <></>
                }
            </div>
        )
    };

    const [selectedHotelToIgnore, setSelectedHotelToIgnore] = useState(undefined)
    const [showIgnoreReviewRequestModal, setShowIgnoreReviewRequestModal] = useState(false)

    const ignoreReviewRequest = (hotel) => {
        setSelectedHotelToIgnore(hotel)
        setShowIgnoreReviewRequestModal(true)
    }
    const cancelReviewRequest = () => {
        setSelectedHotelToIgnore(undefined)
        setShowIgnoreReviewRequestModal(false)
    }

    const deleteRequest = async (hotelId) => {
        setLoading(true);
        try {
            await removeReviewRequest(hotelId);
            cancelReviewRequest();
            setHotels(hotels.filter(hotel => hotel.hotelId !== hotelId));
            setLoading(false);
            ToastNotification('success', 'Review Request Ignored!')
        }
        catch (err) {
            cancelReviewRequest();
            setLoading(false);
            ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <ActionMenuDropdown ignoreReviewRequest={ignoreReviewRequest} data={rowData} />
        );
    };

    const selectHotelName = (data) => {
        window.open(`${window.location.origin}/editor/hotel/${data.bookingHotelId}`, '_blank')
    }

    const hotelNameBodyTemplate = (rowData) => {
        return (
            <div className='flex items-center cursor-pointer hover:bg-color_border_light p-[15px]' onClick={() => selectHotelName(rowData)}>
                <span className='text-[15px] font-bold text-color_black_light'>{rowData.name}</span>
            </div>
        )
    }

    return (
        <div className='flex w-full'>
            <DataTable
                className='w-full max-h-full'
                value={hotels}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 15, 20, 50]}
                dataKey="id"
                loading={loading}
                filters={filters}
                header={header}
                emptyMessage="No hotel found."
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                size='large'
                removableSort
            >
                <Column body={hotelNameBodyTemplate} field="name" header="Hotel Name" sortable style={{ width: '54%' }} className='text-[15px] p-[0px] font-bold border-b text-color_black_light' />
                <Column field="city" header="City" sortable style={{ width: '15%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="country" header="Country" sortable style={{ width: '8%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="hotelId" header="Hotel ID" sortable style={{ width: '8%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="autoReview" header="Auto Review" sortable dataType="boolean" body={autoReviewBodyTemplate} style={{ width: '10%' }} className='text-[15px] border-b text-color_black_light' />
                <Column body={actionBodyTemplate} exportable={false} style={{ width: '5%', minWidth: '40px', padding: 0 }} className='text-[15px] border-b text-color_black_light'></Column>
            </DataTable>

            {showIgnoreReviewRequestModal &&
                <IgnoreReviewRequestModal cancelReviewRequest={cancelReviewRequest} selectedHotelToIgnore={selectedHotelToIgnore} deleteRequest={deleteRequest} />
            }
        </div>
    );
};

export default RequestsPage;

const ActionMenuDropdown = ({ ignoreReviewRequest, data }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Outside Click
    const dropdownRef = useRef(null);

    const closePickers = () => {
        setIsOpen(false);
    };

    useOutsideClick([dropdownRef], closePickers);
    // End Outside Click

    const selectHotel = (data) => {
        window.open(`${window.location.origin}/editor/hotel/${data.bookingHotelId}`, '_blank')
    }

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <div onClick={toggleDropdown} className='w-full flex justify-end'>
                <div className='flex items-center justify-center w-[43px] h-[43px] rounded-full cursor-pointer hover:bg-gray-100'>
                    <span className='text-[13px]'>•••</span>
                </div>
            </div>

            {isOpen && (
                <div className="z-[999] origin-top-right absolute right-0 mt-0 w-[150px] rounded-[15px] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1 m-[10px]">
                        <div className='flex flex-col'>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                                onClick={() => ignoreReviewRequest(data)}
                            >
                                Ignore
                            </button>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                                onClick={() => selectHotel(data)}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const IgnoreReviewRequestModal = ({ cancelReviewRequest, deleteRequest, selectedHotelToIgnore }) => {
    return (
        <Modal
            size='md'
            className='z-[999]'
            isOpen={true}
            toggle={cancelReviewRequest}
        >
            <ModalHeader toggle={cancelReviewRequest}>Ignore Review Request</ModalHeader>
            <ModalBody>
                <div className='flex w-full'>
                    <div className='flex flex-col w-full m-[5px]'>
                        <div className='flex flex-col mb-[30px]'>
                            <span className="text-black text-[15px] leading-[15px] mt-[20px]">Are you sure you want to ignore this Review Request?</span>
                            <span className='text-black text-[15px] leading-[20px] mt-[15px]'>Hotel Name: <b>{selectedHotelToIgnore.name}</b></span>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-3'>
                <button
                    className='text-[15px] font-bold mr-[7px] cursor-pointer bg-white text-color_black_light border-[1px] border-color_black_light rounded-[10px] px-[20px] py-[10px]'
                    onClick={() => deleteRequest(selectedHotelToIgnore.hotelId)}
                >
                    YES, IGNORE
                </button>
                <button
                    className='text-[15px] font-bold cursor-pointer bg-color_black_light text-white border-[1px] border-color_black_light rounded-[10px] px-[20px] py-[10px]'
                    onClick={cancelReviewRequest}
                >
                    CANCEL
                </button>
            </ModalFooter>
        </Modal>
    )
}