import React, { useEffect, useState } from 'react'

const ScoreHelper = ({
    score,
    gymFactorScore,
    size = 'large',
    allGymFactorDefinitions,
}) => {
    const [gymFactorObject, setGymFactorObject] = useState({})
    useEffect(() => {
        const fetchDefinition = async () => {
            const GF = allGymFactorDefinitions.find(data => data.gymFactorScore == score)
            setGymFactorObject(GF)
        }
        if (allGymFactorDefinitions.length > 0) fetchDefinition();
    }, [allGymFactorDefinitions.length > 0, score])

    const gfProgressColor = score == 100 ?
        'bg-gf_progress_green' :
        score == 1 ?
            'bg-gf_progress_red' :
            score == 2 ?
                'bg-gf_progress_gold' :
                score > 2 ?
                    'bg-gf_progress_blue' : 'bg-gf_progress_blue'

    return (
        <div className={`${size == 'large' ? 'w-[304px] h-[106px] px-[20px] py-[16px] border-2 rounded-[16px] gap-[20px]' : size == 'medium' ? 'w-[182px] h-[64px] px-[12px] py-[10px] border rounded-[10px] gap-[12px]' : 'w-[152px] h-[53px] px-[10px] py-[8px] border rounded-[8px] gap-[10px]'} bg-white border-color_stroke justify-start items-center inline-flex`}>
            <div className="relative">
                <img
                    className={`${size == 'large' ? 'min-w-[60px] min-h-[60px]' : size == 'medium' ? 'min-w-[34px] min-h-[34px]' : 'min-w-[28px] min-h-[28px]'}`}
                    src={`${process.env.REACT_APP_STORAGE_BASE_URL}/images/app/${score == 100 ? 'hotelgyms-g-black-logo.svg' : gymFactorObject?.icon?.substring(gymFactorObject?.icon?.lastIndexOf('/') + 1)}`}
                    alt='HotelGyms Logo | HotelGyms.com'
                    width={size == 'large' ? '60' : size == 'medium' ? '34' : '28'}
                    height={size == 'large' ? '60' : size == 'medium' ? '34' : '28'}
                />
            </div>
            <div className={`flex-col justify-center items-start inline-flex ${size == 'large' ? 'gap-[12px]' : size == 'medium' ? 'gap-[7px]' : 'gap-[6px]'}`}>
                <div className="flex-col justify-start items-start flex">
                    <span className={`${size == 'large' ? 'text-[23px] leading-[28px]' : size == 'medium' ? 'text-[14px] leading-[17px]' : 'text-[12px] leading-[14px]'} text-black font-bold`}>{score == 100 ? 'Cooperating with a Gym' : gymFactorObject && gymFactorObject.ratingClassName && gymFactorObject.ratingClassName}</span>
                    {score > 0 && score < 6 && <span className={`${size == 'large' ? 'text-[20px] leading-[24px]' : size == 'medium' ? 'text-[12px] leading-[15px]' : 'text-[10px] leading-[12px]'} text-black font-normal tracking-[0.5px]`}>{score} out of 5</span>}
                </div>
                <div className="justify-start items-start inline-flex gap-1.5">
                    {Array.from({ length: 5 }, (_, index) => {
                        return (
                            <div key={index}>
                                {
                                    gymFactorScore ?
                                        <div key={index + 'gfscore'} className={gymFactorScore > index ? 'opacity-100' : 'opacity-20'}>
                                            <div key={index} className={`${size == 'large' ? 'w-[30px] h-[10px]' : size == 'medium' ? 'w-[18px] h-[6px]' : 'w-[15px] h-[5px]'} rounded-[6px] relative ${gfProgressColor}`} />
                                        </div>
                                        :
                                        <div key={index + 'score'} className={score > index ? 'opacity-100' : 'opacity-20'}>
                                            <div key={index} className={`${size == 'large' ? 'w-[30px] h-[10px]' : size == 'medium' ? 'w-[18px] h-[6px]' : 'w-[15px] h-[5px]'} rounded-[6px] relative ${gfProgressColor}`} />
                                        </div>
                                }
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}
export default ScoreHelper;