import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { deleteExperience, getAllExperiences } from '../redux.js/api';
import { CheckMarkBlack, CheckMarkBlue, CheckMarkBlueLight, CrossMarkRed } from '../components/ComponentStyles';
import { useOutsideClick } from '../components/HelperComponent';
import AppImage from '../components/AppImage';
import ToastNotification from '../components/ToastNotification';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const UserReviewsPage = () => {
    const [experiences, setExperiences] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getExperiences() {
            try {
                const { data: { result } } = await getAllExperiences(1, 10000);
                setLoading(false);
                setExperiences(result.items)
            } catch (error) {
                setLoading(false);
                console.error("Error while fetching experiences:", error);
            }
        }
        getExperiences();
    }, [])

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        hotelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        hotelId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        hotelCity: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        hotelCountryCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        stars: { value: null, matchMode: FilterMatchMode.EQUALS },
        hasImages: { value: null, matchMode: FilterMatchMode.EQUALS },
        userName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        gymFacotrStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex items-center relative">
                <img
                    className='w-[20px] h-[20px] ml-[10px] absolute'
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/searchicon.svg`}
                    alt="Search icon | HotelGyms.com"
                    width='20'
                    height='20'
                />
                <InputText className='flex h-[40px] w-[100%] lg:w-[50%] pl-[40px] border rounded-[10px]' value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </div>
        );
    };
    const header = renderHeader();

    const hasImagesBodyTemplate = (rowData) => {
        const hasImages = rowData.hasImages;
        return (
            <div className='flex items-center justify-center'>
                {hasImages === true ?
                    <CheckMarkBlack viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </CheckMarkBlack>
                    :
                    <></>
                }
            </div>
        )
    };

    const gymFactorStatusBodyTemplate = (rowData) => {
        const score = rowData.score;
        const completeness = rowData.completeness;
        let gymFactorStatus = undefined;

        if (
            score && score !== null && score > 0 &&
            completeness && completeness !== null && completeness > 50
        ) {
            gymFactorStatus = 'blue-dark';
        }
        else if (
            score && score !== null && score > 0
        ) {
            gymFactorStatus = 'blue-light';
        }
        else {
            gymFactorStatus = 'red';
        }


        return (
            <div className='flex items-center justify-center'>
                {gymFactorStatus === 'blue-dark' ? (
                    <CheckMarkBlue viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </CheckMarkBlue>
                ) : gymFactorStatus === 'blue-light' ? (
                    <CheckMarkBlueLight viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </CheckMarkBlueLight>
                ) : (
                    <CrossMarkRed viewBox="0 0 24 24">
                        <line x1="6" y1="6" x2="18" y2="18" />
                        <line x1="6" y1="18" x2="18" y2="6" />
                    </CrossMarkRed>
                )}
            </div>
        );
    };

    const userNameBodyTemplate = (rowData) => {
        const user = rowData.userName;
        const nameOnly = user.split(',')[0].trim();
        return (
            <div className='flex items-center'>
                <span className='text-[15px] text-color_black_light'>{nameOnly}</span>
            </div>
        )
    };

    const starsBodyTemplate = (rowData) => {
        const stars = rowData.stars;
        const starArray = Array.from({ length: stars }, (_, index) => index + 1);
        return (
            <div className='flex items-center'>
                {stars > 0 ?
                    <>
                        {
                            starArray.map((star) => (
                                <AppImage key={star} name='filters-staryellow-icon.svg' alt='Star | HotelGyms.com' className='lazyload mr-[2px]' width='10' height='10' />
                            ))
                        }
                    </>
                    :
                    <span className='text-[15px] text-color_black_light'>{stars}</span>
                }
            </div>
        )
    };

    const [selectedReviewToDelete, setSelectedReviewToDelete] = useState(undefined)
    const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false)

    const deleteReview = (review) => {
        setSelectedReviewToDelete(review)
        setShowDeleteReviewModal(true)
    }
    const cancelReview = () => {
        setSelectedReviewToDelete(undefined)
        setShowDeleteReviewModal(false)
    }

    const deleteRequest = async (reviewId) => {
        setLoading(true);
        try {
            await deleteExperience(reviewId);
            cancelReview();
            setExperiences(experiences.filter(experience => experience.id !== reviewId));
            setLoading(false);
            ToastNotification('success', 'Review Deleted!')
        }
        catch (err) {
            cancelReview();
            setLoading(false);
            ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <ActionMenuDropdown
                deleteReview={deleteReview}
                data={rowData}
            />
        );
    };

    const selectHotelName = (data) => {
        window.open(`${window.location.origin}/editor/hotel/${data.bookingHotelId}`, '_blank')
    }

    const hotelNameBodyTemplate = (rowData) => {
        return (
            <div className='flex items-center cursor-pointer hover:bg-color_border_light p-[15px] truncate-text' onClick={() => selectHotelName(rowData)}>
                <span className='text-[15px] font-bold text-color_black_light'>{rowData.hotelName}</span>
            </div>
        )
    }

    return (
        <div className='flex w-full'>
            <DataTable
                className='w-full max-h-full'
                value={experiences}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 15, 20, 50]}
                dataKey="id"
                loading={loading}
                filters={filters}
                header={header}
                emptyMessage="No user experiences were found."
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                size='large'
                removableSort
            >
                <Column body={hotelNameBodyTemplate} field="name" header="Hotel Name" sortable style={{ width: '30%' }} className='text-[15px] p-[0px] font-bold border-b text-color_black_light' />
                <Column field="hotelId" header="Hotel ID" sortable style={{ width: '6%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="hotelCity" header="City" sortable style={{ width: '8%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="hotelCountryCode" header="Country" sortable style={{ width: '1%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="stars" header="Stars" sortable body={starsBodyTemplate} style={{ width: '3%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="hasImages" header="Images" sortable dataType="boolean" body={hasImagesBodyTemplate} style={{ width: '3%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="userName" header="User Name" sortable body={userNameBodyTemplate} style={{ width: '11%' }} className='text-[15px] border-b text-color_black_light' />
                <Column field="gymFactorStatus" header="GymFactor" sortable dataType="boolean" body={gymFactorStatusBodyTemplate} style={{ width: '3%' }} className='text-[15px] border-b text-color_black_light' />
                <Column body={actionBodyTemplate} exportable={false} style={{ width: '3%', minWidth: '40px', padding: 0 }} className='text-[15px] border-b text-color_black_light'></Column>
            </DataTable>

            {showDeleteReviewModal &&
                <DeleteReviewModal cancelReview={cancelReview} selectedReviewToDelete={selectedReviewToDelete} deleteRequest={deleteRequest} />
            }
        </div>
    );
};

export default UserReviewsPage;

const ActionMenuDropdown = ({
    data,
    deleteReview
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Outside Click
    const dropdownRef = useRef(null);

    const closePickers = () => {
        setIsOpen(false);
    };

    useOutsideClick([dropdownRef], closePickers);
    // End Outside Click

    const selectHotel = (data) => {
        window.open(`${window.location.origin}/editor/hotel/${data.bookingHotelId}`, '_blank')
    }

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <div onClick={toggleDropdown} className='w-full flex justify-end'>
                <div className='flex items-center justify-center w-[43px] h-[43px] rounded-full cursor-pointer hover:bg-gray-100'>
                    <span className='text-[13px]'>•••</span>
                </div>
            </div>

            {isOpen && (
                <div className="z-[999] origin-top-right absolute right-0 mt-0 w-[150px] rounded-[15px] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1 m-[10px]">
                        <div className='flex flex-col'>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                                onClick={() => selectHotel(data)}
                            >
                                Edit
                            </button>
                            <button
                                className="border-0 bg-inherit flex cursor-pointer block px-[12px] py-[10px] text-[16px] text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-[8px]"
                                onClick={() => deleteReview(data)}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const DeleteReviewModal = ({ cancelReview, selectedReviewToDelete, deleteRequest }) => {
    const user = selectedReviewToDelete.userName;
    const nameOnly = user.split(',')[0].trim();
    return (
        <Modal
            size='md'
            className='z-[999]'
            isOpen={true}
            toggle={cancelReview}
        >
            <ModalHeader toggle={cancelReview}>Delete User Review</ModalHeader>
            <ModalBody>
                <div className='flex w-full'>
                    <div className='flex flex-col w-full m-[5px]'>
                        <div className='flex flex-col mb-[30px]'>
                            <span className="text-black text-[15px] leading-[15px] mt-[20px]">Are you sure you want to DELETE this Review?</span>
                            <span className='text-black text-[15px] leading-[20px] mt-[15px]'>Hotel Name: <b>{selectedReviewToDelete.hotelName}</b></span>
                            <span className='text-black text-[15px] leading-[20px] mt-[15px]'>User Name: <b>{nameOnly}</b></span>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-3'>
                <button
                    className='text-[15px] font-bold mr-[7px] cursor-pointer bg-white text-color_black_light border-[1px] border-color_black_light rounded-[10px] px-[20px] py-[10px]'
                    onClick={() => deleteRequest(selectedReviewToDelete.id)}
                >
                    YES, DELETE
                </button>
                <button
                    className='text-[15px] font-bold cursor-pointer bg-color_black_light text-white border-[1px] border-color_black_light rounded-[10px] px-[20px] py-[10px]'
                    onClick={cancelReview}
                >
                    CANCEL
                </button>
            </ModalFooter>
        </Modal>
    )
}