import React from 'react';
import UserMenuDropdown from './UserMenuDropdown';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const Navbar = ({ toggleMobileSidebar, mobileSidebarOpen }) => {
    const { user, logout } = useKindeAuth();
    return (
        <nav className='border-b fixed w-full bg-white top-0 z-[1110]'>
            <div className="flex h-[60px] px-[15px] lg:px-[20px]">
                <div className='flex items-center'>
                    <img
                        src="https://cdn-data.hotelgyms.com/images/app/hotelgyms-logo-black.svg"
                        className='w-[140px] h-[30px] lg:w-[186px] lg:h-[40px]'
                        alt="Hotelgyms logo | Hotelgyms.com"
                    />
                </div>
                <div className='flex items-center ml-auto'>
                    <UserMenuDropdown user={user} logout={logout} />
                </div>
                <div key={mobileSidebarOpen} className="flex lg:hidden ml-[15px] w-[24px] items-center">
                    {mobileSidebarOpen ?
                        <div onClick={() => toggleMobileSidebar()}>
                            <img
                                className='w-[22px] h-[22px]'
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/close-icon.svg`}
                                alt="Close icon | HotelGyms.com"
                                width='22'
                                height='22'
                            />
                        </div>
                        :
                        <div onClick={() => toggleMobileSidebar()}>
                            <img
                                className='w-[24px] h-[24px]'
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/app/burger-menu-icon.svg`}
                                alt="Menu icon | HotelGyms.com"
                                width='24'
                                height='24'
                            />
                        </div>
                    }
                </div>
            </div>
        </nav>
    );
}

export default Navbar;