import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const KindeLogout = () => {
    const { logout } = useKindeAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            try {
                await logout();
                // Redirect to the root URL after successful logout
                navigate("/");
            } catch (error) {
                console.error("Error during logout:", error);
            }
        };

        handleLogout();
    }, [logout, navigate]);

    // This component doesn't render anything visible
    return null;
}

export default KindeLogout;

