import React, { Suspense, useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import DashboardPage from "./pages/DashboardPage";
import EditorPage from "./pages/EditorPage";
import RequestsPage from "./pages/RequestsPage";
import AutoReviewPage from "./pages/AutoReviewPage";
import UserReviewsPage from "./pages/UserReviewsPage";
import SettingsWebAppPage from "./pages/SettingsWebAppPage";
import ProviderDataImport from "./pages/ProviderDataImport";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

export default function MainRouter() {
    const location = useLocation();
    const currentPath = location.pathname;

    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false)
    const toggleMobileSidebar = () => {
        setMobileSidebarOpen(!mobileSidebarOpen)
    }

    useEffect(() => {
        setMobileSidebarOpen(false)
    }, [currentPath])

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div>
                <Navbar toggleMobileSidebar={toggleMobileSidebar} mobileSidebarOpen={mobileSidebarOpen} />
                <div className="flex mt-[61px]">
                    <div className="hidden lg:flex w-[230px] min-w-[230px] shadow-xl desktop-sidebar-helper">
                        <Sidebar />
                    </div>
                    {mobileSidebarOpen &&
                        <div className="flex lg:hidden bg-white w-full h-full mobile-sidebar-modal-helper">
                            <Sidebar />
                        </div>
                    }
                    <div className="flex p-[15px] lg:p-[20px] w-full">
                        <Routes>
                            <Route path="/" element={<Navigate to="/dashboard" replace />} />
                            <Route exact path="/dashboard" element={<DashboardPage />} />
                            <Route path="/editor" element={<EditorPage />} />
                            <Route path="/editor/hotel/:id" element={<EditorPage />} />
                            <Route path="/requests" element={<RequestsPage />} />
                            <Route path="/autoreview" element={<AutoReviewPage />} />
                            <Route path="/userreviews" element={<UserReviewsPage />} />
                            <Route path="/webapp" element={<SettingsWebAppPage />} />
                            <Route path="/providerdataimport" element={<ProviderDataImport />} />
                            <Route path="*" element={<Navigate to="/error/error-v1" replace />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}