import React from 'react';
import { RadioWrapper } from './styles';

const Radio = ({ onRadioChange, name, label, radioSelected, originalRightValue }) => {
  return (
    <RadioWrapper originalRightValue={originalRightValue}>
      <label htmlFor={name}>
        <input type="radio" checked={radioSelected} onClick={(e) => onRadioChange(e, name, 'radio')} id={name} name={name} />
        <span className='checkmark'></span>
        <span htmlFor={name} className="label-style">{label}</span>
      </label>
    </RadioWrapper>
  )
}

export default Radio;